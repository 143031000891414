/*import { combineReducers } from 'redux'
import newStore from '../../utils/redux/newStore'
import authReducer from './reducers/auth'
import setAuthToken from '../../utils/auth/setAuthToken'
import selectedOptions from './reducers/selectedOptions'

const reducers = combineReducers({
  auth: authReducer,
  selectedOptions: selectedOptions.redux
})

const store = newStore(reducers, {})

let currentToken = store.getState().auth.token

store.subscribe(() => {
  let previousToken = currentToken
  currentToken = store.getState().auth.token

  if (previousToken !== currentToken) {
    setAuthToken(currentToken)
  }
})

export default store*/

import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import selectedOptions from './reducers/selectedOptions';
import authReducer from './reducers/auth';

const reducers = combineReducers({
  selectedOptions: selectedOptions.redux,
  auth: authReducer.redux
})

let middleware = [thunk];
const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(...middleware)));

selectedOptions.setDispatch(store.dispatch)
authReducer.setDispatch(store.dispatch)

export default store

