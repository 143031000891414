import styled from 'styled-components'
import { FormLabel } from './style'
import { FieldWrapper, StyledField } from '../../components/base/gui/controls/Form'
import Joi from 'joi'
import { StyledSelectWrapper } from './style'

const CustomSelect = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 0.8rem;
  /* border: 2px solid #ccc; */
  background-color: white;
  font-size: 16px;
  /*overflow: hidden;*/
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: rgba(50, 50, 93, 0.05) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.25) 0px -2px 4px 0px inset;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary} !important;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35); */
  }

  select {
    width: 100%;
    height: 100%;
    padding: 12px;
    font-size: 16px;
    border: none;
    background: transparent;
    appearance: none;
  }

  .select-arrow {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    transition: all 0.2s ease-in-out;
  }

  &:hover .select-arrow {
    /* border-top-color: #fff; */
    border-top-color: ${({ theme }) => theme.colors.primary} !important;
  }
`

export const Select = styled.select`
  /* styles for the select element */
  appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px;
  width: 100%;

  /* styles for the dropdown items */
  /* styles for the dropdown items */
  /* target the options using ::before and ::after */
  ::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none;
  }
  option {
    color: #000;
    font-size: 16px;
    padding: 12px;
  }
  option:hover {
    background-color: #f5f5f5;
  }
  option:active {
    background-color: #ccc;
  }
`

export const FormSelect = ({ label = 'Select', name = 'select', onChange = null, children }) => {
  let idx = 0
  let labelHtml = label.split('').map((letter) => <span style={{ transitionDelay: `${idx++ * 50}ms` }}>{letter}</span>)

  return (
    <FormLabel
    // initial={{ opacity: 0, y: 50 }}
    // whileInView={{
    //   opacity: 1,
    //   y: 0,
    // }}
    // transition={{
    //   duration: 0.7,
    //   delay: 0,
    // }}
    // once
    >
      {labelHtml}
      <CustomSelect>
        <FieldWrapper
          onChange={onChange}
          name={name}
          validation={Joi.string().required().messages({ 'string.empty': 'this field is required', 'string.base': 'this field is required' })}
          StyleWrapper={StyledSelectWrapper}>
          <Select>{children}</Select>
        </FieldWrapper>
        <div className='select-arrow'></div>
      </CustomSelect>
    </FormLabel>
  )
}
