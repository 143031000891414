import styled from 'styled-components'

export const StyledTermsAndCondition = styled.div`
  color: #444;
  padding-top: 7vh;
  width: 65%;
  margin: 0 auto;
  line-height: 2.5;

  .root-0-2-277 {
    padding: 20px;
    background: #fff;
    min-height: 100vh;
    white-space: pre-wrap;
  }

  .TextField-h1-0-2-245 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 3.4rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    /* line-height: 1.13; */
    /* letter-spacing: normal; */
  }

  .text-field-wrapper {
    letter-spacing: normal;
    display: inline-block;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    padding-top: 4vh;

    .TextField-h1-0-2-245 {
      line-height: 1.13;
      font-size: 2.8rem;
    }
  }
`
