import { GridRow } from '../../../components/layouts/GridRow'
import { Col } from '../../../components/layouts/Col'
import {
  Button2,
  H2,
  Label,
  Text,
  TextEmphasis,
  TextL,
  AnimatedCard,
  Accordion,
  PriceBreakdown,
  OverallPrice,
  CancellationPolicy,
  Select,
} from './Style'
import Button from '../../../components/elements/Button'
import { DateRange, StartDate, EndDate } from '../../../components/base/gui/controls/DateRange'
import { DateHolder } from './DateHolder'
import { useEffect, useRef, useState } from 'react'
import { Form } from '../../../components/inputs/Form'
import { TextField } from '../../Home/TextField'
import { useSelector } from 'react-redux'
import { calculateDateDifference } from './utils'
import { useUnitDetails } from '../../../app/providers/unit'
import { useParams } from 'react-router-dom'
import { Loader } from '../../../components/elements/Loader'
import { useValue } from '../../../components/hooks/useValue'
import { BookingSteps, formatDate } from './BookingSteps'
import { InquireSteps } from './InquireSteps'

export const BookingCard = ({ showBookingCard }) => {
  const { getQuote } = useUnitDetails(false)
  //const { selectedDateRange, guests } = useSelector((state) => state.selectedOptions)

  let urlParams = new URLSearchParams(window.location.search);
  let guests = urlParams.get('guests');
  if(!guests)
  {
    guests = 1
  }

  let selectedStartDate = urlParams.get('startDate');
  let selectedEndDate = urlParams.get('endDate');

  let selectedDateRange = null
  if(selectedStartDate && selectedEndDate)
  {
    selectedDateRange = {startDate: new Date(parseInt(selectedStartDate)), endDate: new Date(parseInt(selectedEndDate))}
  }

  const { listingId } = useParams()
  const { unitData } = useUnitDetails('unitData')

  const viewSettings = useValue({ view: '', cardHeight: 'auto' })
  const selectedDates = useValue(
    selectedDateRange === null
      ? { startDate: null, endDate: null, nights: null }
      : {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          nights: calculateDateDifference(selectedDateRange.startDate, selectedDateRange.endDate),
        }
  )

  const quote = useValue({ loading: false, phases: [], available: false, total: 0 })

  let previousDates = null
  let quoteId = null
  let numberOfGuests = guests

  const handleBookNowClick = () => {
    //let bookingCardTitle = document.getElementById("bookingCardTitle")
    viewSettings.set({ view: 'book-now', cardHeight: `100%` })

    /*if(bookingCardTitle) {
      window.scrollTo({
        top: bookingCardTitle.getBoundingClientRect().top-95,
        behavior: 'smooth',
      })
    }*/
  }

  const handleInquireClick = () => {
    viewSettings.set({ view: 'inquire', cardHeight: `100%` })
  }

  const onDateChange = ({ startDate, endDate }) => {
    let nights = null

    if (endDate !== null && startDate !== null) {
      nights = calculateDateDifference(startDate, endDate)
    }
    selectedDates.set({ startDate, endDate, nights })
  }

  const loadQuote = async () => {
    try {
      quote.set({ loading: true, phases: [], available: false, total: 0 })
      let quoteData = await getQuote({
        guestsCount: numberOfGuests,
        checkInDate: formatDate(selectedDates.get().startDate),
        checkOutDate: formatDate(selectedDates.get().endDate),
        listingId,
      })

      //calculate days
      let d = new Date()
      let twoDays = new Date(d.setDate(d.getDate() + 2)).toString().split(' ').splice(1, 4).join(' ')
      twoDays = twoDays.substr(0, twoDays.length - 3)
      let remaining_30_days = new Date(selectedDates.get().endDate)
      remaining_30_days.setDate(remaining_30_days.getDate() - 30)
      remaining_30_days = remaining_30_days.toString().split(' ').splice(1, 3).join(' ')

      quoteId = quoteData.quoteId
      quote.set({
        loading: false,
        phases: quoteData,
        available: quoteData.available,
        total: quoteData.phasesTotal,
        message: null,
        twoDays,
        remaining_30_days,
      })
    } catch (err) {
      quote.set({ loading: false, invoiceItems: [], available: false, message: null })
      console.log(err)
      alert(err.message)
    }
  }

  const onSelectedDatesChange = ({ startDate, endDate }) => {
    if (startDate !== null && endDate !== null) {
      if (previousDates === null) {
        previousDates = { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
      } else if (startDate.toISOString() == previousDates.startDate && endDate.toISOString() == previousDates.endDate) {
        return
      } else {
        previousDates = { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
      }

      if (unitData.terms.minNights && unitData.terms.minNights > selectedDates.get().nights) {
        quote.set({
          loading: false,
          invoiceItems: [],
          available: false,
          message: `This unit has to be booked for at least ${unitData.terms.minNights} nights`,
        })
        return
      }
      if (unitData.terms.maxNights && unitData.terms.maxNights < selectedDates.get().nights) {
        quote.set({
          loading: false,
          invoiceItems: [],
          available: false,
          message: `This unit cannot be booked for more than ${unitData.terms.maxNights} nights`,
        })
        return
      }

      //get price breakdown here
      loadQuote()
    }
  }

  const onGuestsChanged = ({ value }) => {
    if (value !== numberOfGuests) {
      numberOfGuests = value
      if (quote.get().message || previousDates === null || !selectedDates.get().nights) {
        console.log('returning')
        return
      }

      loadQuote()
    }
  }

  useEffect(() => selectedDates.onChange(onSelectedDatesChange), [])

  onSelectedDatesChange(selectedDates.get())

  return (
    <viewSettings.Value
      render={({ view, cardHeight }) => (
        <AnimatedCard height={cardHeight} view={view} showBookingCard={showBookingCard}>
          {/*<H2 id="bookingCardTitle">Booking</H2>*/}

          <quote.Value
            render={(quote) => (
              <>
                {quote.loading && <Loader height='100%' />}
                {!quote.loading && (
                  <selectedDates.Value
                    render={(selectedDates) => (
                      <>
                        <GridRow col='2' margin='.8rem 0'>
                          <TextEmphasis>
                            {selectedDates.nights ? (
                              <>
                                {selectedDates.nights} night{selectedDates.nights !== 1 && 's'}
                              </>
                            ) : (
                              '__ nights'
                            )}
                          </TextEmphasis>
                          <Select
                            defaultValue={numberOfGuests}
                            onChange={onGuestsChanged}
                            options={new Array(unitData.guests).fill(0).map((_, index) => ({
                              value: index + 1,
                              label: (
                                <TextL>
                                  {index + 1} guest{index > 0 && 's'}
                                </TextL>
                              ),
                            }))}
                            placeholder={
                              <TextL>
                                {numberOfGuests} guest{numberOfGuests !== 1 && 's'}
                              </TextL>
                            }
                            isSearchable={false}
                          />
                        </GridRow>

                        <DateRange
                          onChange={onDateChange}
                          startDate={selectedDates.startDate}
                          endDate={selectedDates.endDate}
                          disabledDates={unitData.bookedDates}>
                          <GridRow col='2' margin='.8rem 0'>
                            <Col>
                              <Label>FROM</Label>
                              <StartDate>
                                <DateHolder defaultText='Check in' />
                              </StartDate>
                            </Col>
                            <Col>
                              <Label>TO</Label>
                              <EndDate>
                                <DateHolder defaultText='Check out' />
                              </EndDate>
                            </Col>
                          </GridRow>
                        </DateRange>

                        {quote.available && (
                          <>
                            <OverallPrice>
                              <div className='price-title'>Total Price</div>
                              <div className='price-total'>
                                {quote.phases.currency} ${quote.phases.deposit ? <>{quote.phases.phasesTotal - quote.phases.deposit}&nbsp;<span style={{fontSize:"1.4rem"}}><span style={{color:"#aaa"}}>+ deposit of</span> <strong>${quote.phases.deposit}</strong></span></> : <>{quote.phases.phasesTotal}</>}
                              </div>
                              <div className='price-item'>
                                <span className='item-name'>Refundable Deposit: </span>
                                <span className='item-amount'>${quote.phases.deposit.toFixed(2)}</span>
                              </div>
                              <div className='price-item'>
                                <span className='item-name'>Accommodation: </span>
                                <span className='item-amount'>${quote.phases.totalAccommodation.toFixed(2)}</span>
                              </div>
                              <div className='price-item'>
                                <span className='item-name'>Service Fees: </span>
                                <span className='item-amount'>${quote.phases.totalFees.toFixed(2)}</span>
                              </div>
                              <div className='price-item'>
                                <span className='item-name'>Service Tax: </span>
                                <span className='item-amount'>${quote.phases.totalTaxes.toFixed(2)}</span>
                              </div>
                            </OverallPrice>
                            <Accordion>
                              <div className='tab'>
                                <input type='checkbox' id='chck1' className='hidden-checkbox' />
                                <label className='tab-label' for='chck1'>
                                  Payment Breakdown
                                </label>
                                <div className='tab-content'>
                                  <PriceBreakdown>
                                    {quote.phases.phases.map((phase, index) => (
                                      <div key={'phase_' + index}>
                                        <span className='dateString'>{phase.dateString}</span>
                                        <div className='phaseTotal'>${(phase.grandTotal / 100).toFixed(2)}</div>
                                        <div className='phasesContainer'>
                                          {phase.amounts.map((amount, index2) => (
                                            <div key={'phase_' + index + '_' + index2}>
                                              <div className='phase'>
                                                <span className='phase-name'>{amount.name}:&nbsp;</span>
                                                <span className='phase-amount'>
                                                  ${(amount.amount / 100).toFixed(2)}
                                                </span>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                        <hr className='separator' />
                                      </div>
                                    ))}
                                  </PriceBreakdown>

                                  <h3>Total: ${quote.total}</h3>
                                </div>
                              </div>
                              <div className='tab'>
                                <input type='checkbox' id='chck2' className='hidden-checkbox' />
                                <label className='tab-label' for='chck2'>
                                  Cancellation Policy
                                </label>
                                <div className='tab-content'>
                                  <CancellationPolicy>
                                    <ul>
                                      <li>
                                        To receive a full refund, guests must cancel <strong>within 48 hours</strong> (
                                        <strong>{quote.twoDays}</strong>) of booking.
                                      </li>
                                      <li>
                                        If a guest cancels <strong>after 48 hours</strong> of booking (
                                        <strong>after {quote.twoDays}</strong>) and prior to moving in, the first
                                        month's rent and deposit are <strong>not refunded.</strong>
                                      </li>
                                      <li>
                                        If a guest cancels <strong>after 48 hours</strong> of booking (
                                        <strong>after {quote.twoDays}</strong>) and has <strong>moved in</strong>, 100%
                                        of all nights spent plus 30 days is <strong>not refunded</strong>, the deposit
                                        is also <strong>not refunded.</strong>
                                      </li>
                                      <li>
                                        If <strong>fewer than 30 days</strong> remain (
                                        <strong>after {quote.remaining_30_days}</strong>) on the reservation when the
                                        guest cancels, 100% of all of those remaining nights is{' '}
                                        <strong>not refunded.</strong>
                                      </li>
                                    </ul>
                                  </CancellationPolicy>
                                </div>
                              </div>
                            </Accordion>

                            <div style={{ marginTop: '1rem' }}>
                              <GridRow col='2' gridGap='0.8rem' margin='0rem 0'>
                                <Button onClick={handleBookNowClick}>Book instantly</Button>
                                <Button2 onClick={handleInquireClick}>Inquire</Button2>
                              </GridRow>
                            </div>
                          </>
                        )}
                        {!quote.available && (
                          <>
                            {selectedDates.nights === null && (
                              <div>Please select a check in date and a check out date</div>
                            )}
                            {selectedDates.nights !== null && (
                              <div>{quote.message ? quote.message : 'Unit not available between these dates'}</div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  />
                )}
              </>
            )}
          />

          {view === 'book-now' && <BookingSteps selectedDates={selectedDates.get()} quoteId={quoteId} />}
          {view === 'inquire' && <InquireSteps quoteId={quoteId} />}
        </AnimatedCard>
      )}
    />
  )
}
