import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom'

import GlobalStyle from './theme/global-styles'

import { Home } from '../pages/Home'
import MapTest from '../pages/tests/Map'
// import { Login } from '../pages/Login'

import { PrivateRoute } from '../utils/routing/PrivateRoute'
import { ScrollToTop } from '../utils/routing/ScrollToTop'
import { Header } from './theme/header'
import { ListingPage } from '../pages/ListingPage'
import { ProviderTest } from '../pages/tests/Provider'
import { CardImageStackTest } from '../pages/tests/CardImageStackTest'
import { StripeTest } from '../pages/tests/stripe'
import { AboutPage } from '../pages/About'
import { ContactPage } from '../pages/Contact'
import { StyleFormTest } from '../pages/tests/StyleForm'
import { UseValueDemo } from '../pages/tests/UseValueDemo'
import { SliderDemo } from '../pages/tests/SliderDemo'
import { Footer } from './theme/footer'
import { PrivacyPolicy } from '../pages/PrivacyPolicy'
import { TermsAndConditions } from '../pages/TermsAndConditions'

const App = () => {
  const location = useLocation()

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />

      <Header />
      <Routes location={location} key={location.pathname}>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<AboutPage />} />
        <Route exact path='/contact' element={<ContactPage />} />
        <Route exact path='/services' element={<Home />} />
        <Route exact path='/listing/:listingId' element={<ListingPage />} />
        <Route exact path='/listing/:listingId/:unitName' element={<ListingPage />} />
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route exact path='/terms-of-service' element={<TermsAndConditions />} />
        <Route exact path='/book/success' element={<Home />} />
        <Route exact path='/inquire/success' element={<Home />} />
        <Route exact path='/contact/sent' element={<ContactPage />} />

        <Route path='*' element={<p>Page not found</p>} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
