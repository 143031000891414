import { CheckboxField as Style } from "./Style"
import { FieldWrapper } from "../../base/gui/controls/Form"
import Joi from "joi"

const WrappedCheckbox = ({ onChange, value, onBlur, label}) => {

    const onChange_debug = (e) => {
        e.target.value = e.target.checked
        onChange(e)
    }

    return (
        <div>
            <input type="checkbox" onChange={onChange_debug} value={value} onBlur={onBlur}/>{label}
        </div>
    )
}

export const CheckboxField = ({label="checkbox", placeholder="Enter text", name="checkbox", validation = null}) => {

    return (
        <Style>
            <FieldWrapper name={name} validation={validation || Joi.boolean()} defaultValue={false}><WrappedCheckbox label={label}/></FieldWrapper>
        </Style>
    )
}