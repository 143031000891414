import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
  /* max-width: 43rem; */
`
export const Img = styled.img`
  width: 100%;
  height: 25rem;
  border-radius: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    height: 40vh; /* reduce height on smaller screens */
  }
`
export const Title = styled.h4`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
  /* margin-top: 0.5rem; */
  font-family: 'Inter';
  letter-spacing: 0.05px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 315px; */
`

export const ReviewStars = ({ stars = 0, reviewCount = 0 }) => {
  if (reviewCount == 0) {
    return <></>
  }

  return (
    <ReviewStarsStyle>
      <i className='fa-solid fa-star'></i> {(stars / 2.0).toFixed(1)} ({reviewCount})
    </ReviewStarsStyle>
  )
}

export const ReviewStarsStyle = styled.div`
  font-weight: medium;
  font-size: 1.2rem;
  margin: auto 0;
  width: max-content;
  word-break: keep-all;
  margin-left: auto;
`
export const ShortDescription = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  letter-spacing: -0.5px;
  color: grey;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Price = styled.span`
  /* color: #63a1a0; */
  font-size: 1.45rem;
  font-weight: 600;
  letter-spacing: 0.25px;
`
export const TotalPriceLabel = styled.span`
  /* background: #63a1a0; */
  color: white;
  color: #63a1a0;
  font-size: 1.3rem;
  font-weight: 800;
  padding: 0.3rem 0.8rem;
  border-radius: 0.4rem;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  color: grey;
  letter-spacing: 0.05rem;
  margin-right: 1.5rem;
  height: 4rem;

  /*&:first-of-type {
    margin-right: 1.5rem;
  }*/
`

export const TextEmphasis = styled.span`
  font-size: ${({ fontSize }) => fontSize || '1.8rem'};
  color: #63a1a0;
  font-weight: bold;
`

export const Subtext = styled.span`
  color: #63a1a0;
  color: #717171;
  color: grey;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: -0px;
`
