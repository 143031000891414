import { useEffect, useState } from "react"

const MakeHeaderSignal = () => {
    let subscribers = new Set()

    const subscribe = (func) => {
        subscribers.add(func)

        return () => subscribers.delete(func)
    }

    const unsubscribe = (func) => {
        subscribers.delete(func)
    }

    const signal= (value) => {
        subscribers.forEach(setState => setState({value}))
    }

    return {subscribe, unsubscribe, signal}
}


export const headerSignal = MakeHeaderSignal()

export const useHeaderSignal = () => {
    const [receivedHeaderSignal, getHeaderSignal] = useState(null)

    useEffect(() => {
        return headerSignal.subscribe(getHeaderSignal)
    }, [])

    return receivedHeaderSignal
}