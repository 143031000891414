import {useState, useEffect} from 'react'
import { PageButton, PaginationWrapper } from './Style'

/*
export const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  const showPrevButton = currentPage > 1
  const showNextButton = currentPage < pageCount
  const pageButtons = []

  const handlePageButtonClick = (pageIndex) => {
    onPageChange(pageIndex)
  }

  if (currentPage > 1) {
    pageButtons.push(
      <PageButton key={currentPage - 1} onClick={() => handlePageButtonClick(currentPage - 1)}>
        <i class='fa-duotone fa-arrow-left'></i>
      </PageButton>
    )
  } else {
    pageButtons.push(
      <PageButton key={currentPage - 1} disabled>
        <i class='fa-duotone fa-arrow-left'></i>
      </PageButton>
    )
  }

  pageButtons.push(
    <PageButton key={currentPage} active={true} onClick={() => handlePageButtonClick(currentPage)}>
      {currentPage}
    </PageButton>
  )

  if (showNextButton) {
    pageButtons.push(
      <PageButton key={currentPage + 1} onClick={() => handlePageButtonClick(currentPage + 1)}>
        <i class='fa-duotone fa-arrow-right'></i>
      </PageButton>
    )
  } else {
    pageButtons.push(
      <PageButton key={currentPage + 1} disabled>
        <i class='fa-duotone fa-arrow-right'></i>
      </PageButton>
    )
  }

  return <PaginationWrapper>{pageButtons}</PaginationWrapper>
}

*/

export const Pagination = ({paginationController}) => {

  const [currentPage, setCurrentPage] = useState(paginationController.getCurrentPage())
  
  const numberOfPages = paginationController.numberOfPages

  useEffect(() => {
    paginationController.subscribePaginationComponent(setCurrentPage)
  }, [paginationController])

  if(numberOfPages == 1)
  {
    return <></>
  }

  const pageButtons = []


  /*if (currentPage > 1) {
    pageButtons.push(
      <PageButton key={"prevPage"} onClick={paginationController.previousPage}>
        <i class='fa-duotone fa-arrow-left'></i>
      </PageButton>
    )
  } else {
    pageButtons.push(
      <PageButton key={"prevPageDisabled"} disabled>
        <i class='fa-duotone fa-arrow-left'></i>
      </PageButton>
    )
  }*/

  pageButtons.push(<PageButton key={"page1"} active={currentPage == 1} onClick={() => paginationController.setPage(1)}>1</PageButton>)

  if(currentPage-1 > 2 && numberOfPages > 6)
  {
    if(numberOfPages === 7 || currentPage === 4)
    {
      pageButtons.push(<PageButton key={"page2"} active={currentPage == 2} onClick={() => paginationController.setPage(2)}>2</PageButton>)
    }
    else
    {
      pageButtons.push(<span key="dots1">...</span>)
    }
  }


  for(let i = Math.max(Math.min(Math.max(currentPage-1, 2),numberOfPages-4),2); i <= Math.min(Math.max(Math.min(currentPage+1, numberOfPages-1),5),numberOfPages-1); i++)
  {
    pageButtons.push(<PageButton key={"page"+i} active={currentPage === i} onClick={() => paginationController.setPage(i)}>{i}</PageButton>)
  }

  if(currentPage+1 < numberOfPages - 1 && numberOfPages > 6)
  {
    if(numberOfPages === 7)
    {
      pageButtons.push(<PageButton key={"page"+6} active={currentPage == 6} onClick={() => paginationController.setPage(6)}>{6}</PageButton>)
    }
    else
    {
      pageButtons.push(<span key="dots2">...</span>)
    }
  }

  pageButtons.push(<PageButton key={"page"+numberOfPages} active={currentPage == numberOfPages} onClick={() => paginationController.setPage(numberOfPages)}>{numberOfPages}</PageButton>)

  /*if (currentPage < numberOfPages) {
    pageButtons.push(
      <PageButton key={currentPage + 1} onClick={() => paginationController.nextPage()}>
        <i class='fa-duotone fa-arrow-right'></i>
      </PageButton>
    )
  } else {
    pageButtons.push(
      <PageButton key={currentPage + 1} disabled>
        <i class='fa-duotone fa-arrow-right'></i>
      </PageButton>
    )
  }*/

  return <PaginationWrapper>{pageButtons}</PaginationWrapper>
}