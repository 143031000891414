import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

const images = [
  '//image.myvr.com/e0445af696c0570c/d4c48f15b1e81a4d/64cbd1c1-f6ef-4d9f-92e1-25c8dcb41af6.jpg',
  '//image.myvr.com/e0445af696c0570c/d4c48f15b1e81a4d/b68afc2c-2b32-4708-9ec7-c5eccf973cd0.jpg',
  '//image.myvr.com/e0445af696c0570c/d4c48f15b1e81a4d/2ca5ae31-0741-4d99-b58f-e1ad14ed9d7d.jpg',
]

const ScrollerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  height: 200px;
  margin: 20px 0;

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ScrollerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 1s ease-in-out;
`

const grow = css`
  @keyframes grow {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(2.5);
    }
  }
`

const shrink = css`
  @keyframes shrink {
    from {
      transform: scale(2.5);
    }
    to {
      transform: scale(1);
    }
  }
`

const ActiveScrollerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: ${(props) => (props.active ? `${grow} 1s forwards` : `${shrink} 1s forwards`)};
  transition: all 1s ease-in-out;
  transform: ${(props) => (props.active ? 'scale(2.5)' : 'scale(1)')};
`

export const CustomScroller = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 3000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <ScrollerContainer>
      {images.map((imageUrl, index) => (
        <div key={index}>
          {index === activeIndex ? (
            <ActiveScrollerImage src={imageUrl} active={true} />
          ) : (
            <ScrollerImage src={imageUrl} />
          )}
        </div>
      ))}
    </ScrollerContainer>
  )
}
