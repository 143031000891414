import React, { useEffect, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

export const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
  background-image: url('../../../bed-food-home.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.5rem;
  padding: 0;
  /* transform: scaleX(-1); */
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8));
  background: linear-gradient(-55deg, rgba(99, 161, 160, 0.75), rgba(99, 161, 160, 0.96));
  opacity: 0.6;
  transition: opacity 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out;
  backdrop-filter: blur(0);
  z-index: 0;
`

export const SectionTitle = styled.h3`
  color: white;
  font-size: 4rem;
  text-align: left;
  position: relative;
  z-index: 1;
`

export const SectionContent = styled.p`
  font-size: 1.9rem;

  color: #666;
  color: #fafafa;
  /* width: 60rem; */
  margin: 0 auto;
  margin-bottom: 2rem;
  line-height: 1.6;
`

const animateUnderline = keyframes`
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
`

export const Underline = styled.span`
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: #ffcc00;
    transform-origin: left center;
    transform: scaleX(0);
    animation: ${animateUnderline} 1s forwards;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  width: 50%;
  margin: auto 0;
  margin-right: auto;
  margin-left: 8rem;
  z-index: 1;
  position: relative;
  padding: 0 2.5rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 2.5rem;
    margin-left: 0rem;
  }
`

export const YourHome = ({ style }) => {
  const ref = useRef(null)
  const [isInView, setIsInView] = useState(false)

  const handleScroll = () => {
    const section = ref.current
    const sectionTop = section.offsetTop
    const sectionBottom = sectionTop + section.offsetHeight
    const viewportHeight = window.innerHeight
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (scrollTop + viewportHeight / 2 >= sectionTop && scrollTop + viewportHeight / 2 <= sectionBottom) {
      setIsInView(true)
    } else {
      setIsInView(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Layout style={style}>
      <Overlay />
      {/* <HighTechCarousel></HighTechCarousel> */}
      <TextContainer ref={ref}>
        <SectionTitle>
          {/* Your Home <Underline>{isInView ? 'Away from Home' : ''}</Underline> */}
          Your home <br />
          away from home
        </SectionTitle>
        <SectionContent>
          We understand that a home is more than just four walls. It's a place where memories are made and experiences
          are shared. That's why we strive to provide a welcoming and comfortable environment for all our residents.
        </SectionContent>
      </TextContainer>
    </Layout>
  )
}
