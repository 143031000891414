import styled from 'styled-components'

export const MapHolder = styled.div`
  width: 100%;
  height: ${(props) => props.height || '100%'};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* max-height: calc(100vh - 9rem); */
  /* height: 50vh; */
  //min-height: 100vh;
  margin-bottom: auto;

  &:focus {
    outline: none;
  }

  .gm-style-iw {
    max-width: 500px !important;
  }

  .gm-style-iw button:focus {
    outline: 0;
  }

  .gm-style-iw a:focus {
    outline: 0;
  }

  .gmnoprint {
    display: none;
  }

  .gm-style-cc {
    display: none;
  }
`
