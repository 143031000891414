import { Page } from "../../../components/layouts"
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { CheckoutForm } from "./CheckoutForm";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

export const StripeTest = () => {

    const options = {
        mode: 'payment',
        amount: 12345,
        currency: 'cad'
      };

    return (
        <Page>
            <h1>My test form</h1>
            <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
            </Elements>
        </Page>
    )
}