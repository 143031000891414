import {useState} from 'react'
import { Wrapper, CardWrap, Modal, CloseButton, FullImage, ArrowContainer, ViewAllImages, GalleryModal, CloseGalleryButton} from "./Style"
import { Card } from './Card';
import { useValue } from '../../components/hooks/useValue';
import { ImageGallery } from './ImageGallery';

export const ImageCardStack = ({pictures}) => {

    const cards = useValue(pictures);
    const displayedImage = useValue(null)
    const galleryView = useValue(false)

    const onImageClick = (index) => {

        let currentCards = cards.get()

        if(index > 0)
        {
            let newArray = new Array(currentCards.length);

            let stackIndex = 0;
            for (let i = index; i < currentCards.length; i++) {
                newArray[stackIndex] = currentCards[i];
                stackIndex++;
            }
            for (let i = 0; i < index; i++) {
                newArray[stackIndex] = currentCards[i];
                stackIndex++;
            }

            cards.set(newArray);

            if(displayedImage.get())
            {
                displayedImage.set(newArray[0].large)
            }
        }
        else if(index == 0)
        {
            displayedImage.set(currentCards[0].large)
        }
        else
        {
            let newArray = new Array(currentCards.length);

            newArray[0] = currentCards[currentCards.length-1];
            for (let i = 0; i < currentCards.length-1; i++) {
                newArray[i+1] = currentCards[i];
            }

            cards.set(newArray);

            if(displayedImage.get())
            {
                displayedImage.set(newArray[0].large)
            }
        }
    }

    const closeModal = () => {
        displayedImage.set(null)
    }

    const onFullScreenArrowClick = (e, direction) => { //direction is either 1 or -1
        e.stopPropagation()
        if(direction > 0)
        {
            onImageClick(1)
        }
        else
        {
            onImageClick(-1)
        }
    }

    
    return (
        <>
            <galleryView.Value conditionalRender={() => 
                <GalleryModal>
                    <CloseGalleryButton onClick={() => {window.scrollTo(0,0); galleryView.set(false)}}><i className="fa-solid fa-angle-left"></i></CloseGalleryButton>
                    <div>
                        <ImageGallery images={cards.get().map(img => img.original)} onImageClick={onImageClick}/>
                        <div style={{display:"inline-block", width:"100%", height:"10px"}}></div>
                    </div>
                </GalleryModal>
            } />
            <displayedImage.Value conditionalRender={displayedImage =>
                <Modal onClick={closeModal}>
                    <CloseButton><i className="fa-regular fa-xmark"></i></CloseButton>
                    <FullImage src={displayedImage} onClick={e => e.stopPropagation()}/>
                    <ArrowContainer left={true} onClick={(e) => onFullScreenArrowClick(e,-1)}><i class="fa-solid fa-chevron-left"></i></ArrowContainer>
                    <ArrowContainer right={true} onClick={(e) => onFullScreenArrowClick(e,1)}><i class="fa-solid fa-chevron-right"></i></ArrowContainer>
                </Modal>
            }/>
            <Wrapper>
                <div style={{display:"none"}}>
                    {cards.get().map(img => <img key={"img_"+(img.large || img.original)} src={img.large || img.original} />)}
                </div>
                <CardWrap>
                    <cards.Value render={cards => <>
                        {cards.slice(0, 3).map(({large, original}, index) => (
                            <Card
                                key={large || original}
                                image={large || original}
                                index={index}
                                onImageClick={() => onImageClick(index)}
                            />
                        ))}
                    </>} />
                </CardWrap>
                <ViewAllImages onClick={() => galleryView.set(true)}><i class="fa-sharp fa-solid fa-grid"></i>&nbsp;View all images</ViewAllImages>
            </Wrapper>
        </>
    )
}