import { useValue } from "../../../components/hooks/useValue";
import { BgDiv, DoubleImage, FullScreenImg, GalleryContainer, LargeImage, SmallImage } from "./Style"

export const ImageGallery = ({images}) => {

    const displayImg = useValue(null)

    const viewImage = (e) => {
        displayImg.set(e.target.src)
    }

    const cancelViewImage = (e) => {
        displayImg.set(null)
    }
    
    let components = []
    for(let i=0; i<images.length; i+=3)
    {
        let image = images[i]
        let image2 = null;

        if(image)
        {
            components.push(<LargeImage src={image} onClick={viewImage}/>)
        }
        image = images[i+1]
        if(image)
        {
            image2 = images[i+2]
            if(image2)
            {
                components.push(<DoubleImage><div><SmallImage src={image} onClick={viewImage}/></div><div><SmallImage src={image2} onClick={viewImage}/></div></DoubleImage>)
            }
            else
            {
                components.push(<LargeImage src={image} onClick={viewImage}/>)
            }
        }
    }

    return (
        <GalleryContainer>
            <displayImg.Value conditionalRender={ displayImg => 
                <BgDiv onClick={cancelViewImage}>
                    <FullScreenImg src={displayImg}/>
                </BgDiv>
            }/>
            {components}
        </GalleryContainer>
    )
}