import { ErrorWrapper } from '../../../components/base/gui/controls/Form'
import { useValue } from '../../../components/hooks/useValue'
import { EmailField, Form, TextField } from '../../../components/inputs/Form'
import { SubmitButton } from '../../../components/inputs/Form/SubmitButton'
import { Col } from '../../../components/layouts/Col'
import api from '../../../utils/api'
import { H3 } from '../Style'
import { StripeForm } from './StripeForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE)

export const formatDate = (date) => {
  return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2,'0') + '-' + date.getDate().toString().padStart(2,'0')
}

const MakeLogic = ({ selectedDates }) => {
  console.log("selectedDates", selectedDates)
  let state = {
    emailSent: useValue(null), //will be the email address
    verifiedCode: useValue(null), //will be the code when it was validated
    bookingId: useValue(null),
  }

  const submitEmail = async (email) => {
    await api.post('/user/email-verification/request', { email })
    state.emailSent.set(email)
  }

  const submitValidationCode = async (code) => {
    const { data } = await api.post('/user/email-verification/validate', { email: state.emailSent.get(), code })
    let { valid } = data
    if (valid) {
      state.verifiedCode.set(code)
      return true
    } else {
      return false
    }
  }

  const bookUnit = async ({ paymentMethod, listingId }) => {
    let { data } = await api.post('/units/book/' + listingId, {
      paymentMethod,
      email: state.emailSent.get(),
      validationCode: state.verifiedCode.get(),
      selectedDates: {startDate: formatDate(selectedDates.startDate), endDate: formatDate(selectedDates.endDate)},
      guests: 1,
    })

    if (data.bookingId) {
      state.bookingId.set(data.bookingId)
    }
    return data
  }

  return { state, submitEmail, submitValidationCode, bookUnit }
}

export const BookingSteps = ({ selectedDates }) => {
  const logic = MakeLogic({ selectedDates })
  const { emailSent, verifiedCode } = logic.state

  const onEmailSubmit = async ({ values, callback }) => {
    try {
      await logic.submitEmail(values.email)
    } catch (e) {
      console.log(e)
      callback(e.message)
    }
  }

  const onCodeValidationSubmit = async ({ values, callback }) => {
    try {
      let valid = await logic.submitValidationCode(values.code)
      if (!valid) {
        callback('Validation code is invalid.')
      }
    } catch (e) {
      console.log(e)
      callback(e.message)
    }
  }

  return (
    <Col>
      <verifiedCode.Value
        render={(verifiedCode) => (
          <>
            {!verifiedCode && (
              <emailSent.Value
                render={(emailSent) => (
                  <>
                    {!emailSent && (
                      <div>
                        <Form onSubmit={onEmailSubmit}>
                          <EmailField />
                          <br />
                          <SubmitButton>Ok</SubmitButton>
                        </Form>
                      </div>
                    )}
                    {emailSent && (
                      <>
                        <div style={{ marginTop: '2rem' }}>
                          <H3>Email sent to {emailSent}</H3>
                        </div>

                        <div>
                          {/*<p>Please enter the validation code</p>*/}
                          <Form onSubmit={onCodeValidationSubmit}>
                            <ErrorWrapper Element={({ error }) => <p>{error}</p>} />
                            <TextField label='Validation Code' name='code' />
                            <br />
                            <SubmitButton>Validate</SubmitButton>
                          </Form>
                        </div>
                      </>
                    )}
                  </>
                )}
              />
            )}
            {verifiedCode && (
              <Elements stripe={stripePromise}>
                <StripeForm logic={logic} />
              </Elements>
            )}
          </>
        )}
      />
    </Col>
  )
}
