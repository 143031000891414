import { CardOverlay, Card as CardStyle } from "./Style"

export const Card = ({image, index, onImageClick}) => {

    return (
        <CardStyle
            image={image}
            animate={{
                top: (index * -3)+'%',
                left: (index * 30)+'%',
                zIndex: 3 - index
            }}
            index={index}
            onClick={() => onImageClick(index)}
        >
            <CardOverlay index={index}/>
        </CardStyle>
    )
}