import { useState, useEffect, useRef } from 'react'
//import { usePagination } from "../../../app/providers/pagination"
import { GridRow } from '../../../components/layouts/GridRow'
import { Listing } from '../Listing'
import { Container } from '../Style'
import { StyledPageSelector } from './Style'
import { useNavigate } from 'react-router-dom'
import selectedOptions from '../../../app/context/reducers/selectedOptions'

export const PaginatedListings = ({ onMouseEnter, paginationController, searchBarContracted }) => {
  const navigate = useNavigate()

  //const {page, pageData, numberOfPages, previousPage, nextPage, setPage} = usePagination(["page", "pageData", "numberOfPages"])
  //paginationController
  const [pageData, setPageData] = useState(paginationController.getPageData())
  const containerRef = useRef()

  const scrollToTop = () => {
    containerRef.current.scrollTop = 0;
  }

  //selectedOptions

  const urlParams = `?guests=${selectedOptions.state.guests}${selectedOptions.state.selectedDateRange ? `&startDate=${selectedOptions.state.selectedDateRange.startDate.getTime()}&endDate=${selectedOptions.state.selectedDateRange.endDate.getTime()}` : ''}`

  useEffect(() => {
    paginationController.subscribeListComponent(setPageData,scrollToTop)
  }, [paginationController])

  return (
    <div>
      <searchBarContracted.Value render={searchBarContracted =>
        <Container ref={containerRef} searchBarContracted={searchBarContracted}>
          <GridRow col='2' mediumScreenTemplate="1fr" mediumScreenWidth="1400px" mobile>
            {pageData.map((result) => (
              <Listing
                key={result.unit.listingId}
                data={result}
                onMouseEnter={() => onMouseEnter(result)}
                navigate={navigate}
                arrowPosition='inside'
                urlParams={urlParams}
              />
            ))}
          </GridRow>
        </Container>
      } />
    </div>
  )
}
