import axios from 'axios'
import store from '../../app/context/store'

// create instance of axios
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      store.dispatch({ type: 'LOGOUT' })
      throw new Error(err.response.data.error)
    } else if (err.response.status === 404) {
      throw new Error('server path not found')
    } else {
      //Do nothing
    }

    throw new Error(err.response.data.message || err.response.data.error)
  }
)

// what is setAuthToken doing here?
export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = token
    localStorage.setItem('token', token)
  } else {
    delete api.defaults.headers.common['Authorization']
    localStorage.removeItem('token')
  }
}

export default api
