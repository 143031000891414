import { Label, SmallText } from './Style'

export const compileAmenities = (unitData) => {
  const { amenities } = unitData

  let parkingText = null
  if (amenities.includes('Free parking on premises')) {
    parkingText = (
      <Label>
        Free parking
        <br />
        <SmallText>on premises</SmallText>
      </Label>
    )
  } else if (amenities.includes('Free parking on street')) {
    parkingText = (
      <Label>
        Free parking
        <br />
        <SmallText>on street</SmallText>
      </Label>
    )
  } else if (amenities.includes('Paid parking off premises')) {
    parkingText = (
      <Label>
        Paid parking
        <br />
        <SmallText>off premises</SmallText>
      </Label>
    )
  }

  let hasGym = amenities.includes('Gym')

  let poolText = null
  if (amenities.includes('Private pool')) {
    poolText = <Label>Private pool</Label>
  } else if (amenities.includes('Swimming pool')) {
    poolText = <Label>Swimming pool</Label>
  } else if (amenities.includes('Outdoor pool')) {
    poolText = <Label>Outdoor pool</Label>
  } else if (amenities.includes('Indoor pool')) {
    poolText = <Label>Indoor pool</Label>
  }

  let hasDesk = amenities.includes('Desk')

  return { parkingText, hasGym, poolText, hasDesk }
}
