import React, { useState, useEffect } from 'react'
import { StyledFooter } from './Style'
import { Row } from '../../../components/layouts/Row'

export const Footer = ({ ...props }) => {
  const [currentYear] = useState(new Date().getFullYear())
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [isVisible, setIsVisible] = useState(false)

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset
    setIsVisible(prevScrollPos > currentScrollPos)
    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [prevScrollPos, isVisible])

  return (
    <StyledFooter style={{ transform: isVisible ? 'translateY(0)' : 'translateY(100%)' }}>
      © {currentYear} Toronto Boutique Apartments&nbsp;•&nbsp;
      <Row width='fit-content' margin='0'>
        <a href='/terms-of-service'>Terms</a>&nbsp;•&nbsp;
        <a href='/privacy-policy'>Privacy</a>
      </Row>
    </StyledFooter>
  )
}
