import { LabelStyle, TextField as Style } from './Style'
import { FieldWrapper, StyledField } from '../../base/gui/controls/Form'
import Joi from 'joi'
import styled from 'styled-components'

const FieldWrapperStyle = styled(StyledField)`
  width: 100%;

  textarea {
    width: 100%;
    font-family: inherit;
  }
`

export const TextAreaField = ({ label = 'text', placeholder = 'Enter text', name = 'textarea', ...style }) => {
  return (
    <Style {...style}>
      <label>
        <LabelStyle>{label}</LabelStyle>
        <FieldWrapper
          name={name}
          validation={Joi.string()
            .required()
            .messages({ 'string.empty': label + ' is required' })}
          StyleWrapper={FieldWrapperStyle}>
          <textarea placeholder={placeholder} rows={12} className='input'/>
        </FieldWrapper>
      </label>
    </Style>
  )
}
