import { LabelStyle, TextField as Style } from "./Style"
import { FieldWrapper } from "../../base/gui/controls/Form"
import Joi from "joi"


export const TextField = ({label="text", placeholder="Enter text", name="text"}) => {

    return (
        <Style>
            <label>
                <LabelStyle>{label}</LabelStyle>
                <FieldWrapper name={name} validation={Joi.string().required().messages({ 'string.empty': label+' is required' })}><input className="input" placeholder={placeholder}/></FieldWrapper>
            </label>
        </Style>
    )
}