import React, { useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import logoMark from '../../../../app/media/img/logoMark.png'
import { Col as ColRef } from '../../../layouts/Col'
import { useNavigate } from 'react-router-dom'
import { headerSignal } from '../../../../app/theme/header/HeaderSignal'
import selectedOptions from '../../../../app/context/reducers/selectedOptions'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
`

const Layout = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Logo = styled.img`
  height: 4.9rem;
  margin-right: 1rem;
  cursor: pointer;
  opacity: 0;
  animation: ${fadeIn} 0.5s cubic-bezier(0.6, 0.1, 0.2, 1.5) forwards;

  @media screen and (max-width: 768px) {
    width: 4rem;
    height: 4rem;
  }
`

const Text = styled.span`
  font-size: 1.6rem;
  font-family: 'PlayfairDisplay';
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  letter-spacing: 1.12px;
  letter-spacing: 1.92px;
  opacity: 0;
  animation: ${fadeIn} 0.5s cubic-bezier(0.6, 0.1, 0.2, 1.5) forwards;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`

const SubText = styled.span`
  font-size: 1.6rem;
  font-size: 1.25rem;
  font-family: 'PlayfairDisplay';
  /* font-weight: 100; */
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  letter-spacing: 12px;
  opacity: 0;
  animation: ${slideIn} 1s cubic-bezier(0.6, 0.1, 0.2, 1.5) forwards;

  @keyframes textSpacing {
    from {
      letter-spacing: 0;
    }
    to {
      letter-spacing: 13.1px;
    }
  }

  animation: ${slideIn} 0.8s ease forwards, textSpacing 0.8s ease forwards;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    letter-spacing: 4px;

    @keyframes textSpacing {
      from {
        letter-spacing: 0;
      }
      to {
        letter-spacing: 8.5px;
      }
    }
    animation: ${slideIn} 0.8s ease forwards, textSpacing 0.8s ease forwards;
  }
`

const Col = styled(ColRef)`
  margin: 0;
`

export const LogoWithText = ({ ...props }) => {
  const navigate = useNavigate()
  const [animate, setAnimate] = useState(false)

  const handleHover = () => {
    setAnimate(true)
  }

  const handleHoverEnd = () => {
    setAnimate(false)
  }

  return (
    <Layout
      onClick={() => {
        navigate('/')
        props.onClick()
        headerSignal.signal("LOGO_CLICK")
        selectedOptions.resetSearchFilters()
      }}>
      <Logo src={logoMark} alt='' onMouseEnter={handleHover} onMouseLeave={handleHoverEnd} animate={animate} />
      <Col>
        <Text animate={animate}>Toronto Boutique</Text>
        <SubText animate={animate}>Apartments</SubText>
      </Col>
    </Layout>
  )
}
