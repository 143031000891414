import styled from 'styled-components'
import { ContactForm } from '../../features/ContactForm/index'
//import { sendPageView } from '../../utils/analytics'
import { useEffect } from 'react'
import { sendPageViewGTM } from '../../utils/analytics/GTM'

export const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const H1 = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`

export const ContactPage = () => {

  useEffect(() => {
    sendPageViewGTM()
  }, [])

  return (
    <Layout>
      <H1>Reach us for assistance</H1>
      <ContactForm />
    </Layout>
  )
}
