import { Loader } from '@googlemaps/js-api-loader'
import { useEffect, useState } from 'react'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
})

var MapLibary = null
var AdvancedMarkerElement = null
var PinElement = null

/***
 * "markers" is array of {
      location: { lat: Number, lng: Number }
      controller: null
      popupContent: HTML string
    }
 ***/

export const GoogleMaps = ({ markers = [], onMarkerClick = () => null, ...props }) => {
  const [map, setMap] = useState(null)

  const loadMap = async () => {
    await loader.load()

    if (!MapLibary) {
      const { Map } = await window.google.maps.importLibrary('maps')
      const { AdvancedMarkerElement: AdvancedMarker, PinElement: Pin } = await window.google.maps.importLibrary(
        'marker'
      )
      MapLibary = Map
      AdvancedMarkerElement = AdvancedMarker
      PinElement = Pin
    }

    const newMap = new MapLibary(document.getElementById('map'), {
      center: markers[0].location,
      zoom: 14,
      mapId: 'myMap',
    })

    let newMarkers = []

    for (let i = 0; i < markers.length; i++) {
      const pinBackground = new PinElement({
        background: '#333333',
        glyphColor: '#dddddd',
        borderColor: '#dddddd',
      })

      const markerContent = document.createElement('div')
      markerContent.innerHTML = `
        <div class="icon" style="position:relative;">
          <i class="fa-sharp fa-solid fa-location-pin" style="font-size:30px;color:#333333;"></i>
          ${
            markers[i].label
              ? '<span style="color:#dddddd;position:absolute;top:2px;left:50%;font-size:15px;transform:translateX(-50%);">' +
                markers[i].label +
                '</span>'
              : '<i class="fa-solid fa-circle" style="color:#dddddd;position:absolute;top:5px;left:5px;font-size:12px;"></i>'
          }
        <div>
      `

      let newMarker = new AdvancedMarkerElement({
        map: newMap,
        position: markers[i].location,
        content: markerContent, //pinBackground.element,
        collisionBehavior: 'REQUIRED',
      })


      let infoWindow = new window.google.maps.InfoWindow({
        content: markers[i].popupContent, //'<img width="10rem", height="10rem" src="https://baytaq.b-cdn.net/units/62b26aa0cae19edfb3bb13bc.webp"><br/><i>this is a test</i>'
      })

      let clickInfoWindow = new window.google.maps.InfoWindow({
        content: markers[i].onClickPopupContent,
      })

      let openedWindow = null

      markers[i].controller = {
        openPopup: () => {
          openedWindow = infoWindow
          infoWindow.open(newMap, newMarker)
          newMap.panTo(markers[i].location)
        },
        openClickPopup: () => {
          openedWindow = clickInfoWindow
          clickInfoWindow.open(newMap, newMarker)
          newMap.panTo(markers[i].location)
        },
        closePopup: () => {
          if (openedWindow) {
            openedWindow.close(newMap, newMarker)
          }
        },
      }

      newMarker.addListener('click', () => {
        onMarkerClick(markers[i])
      })

      newMarkers.push(newMarker)
    }

    //new MarkerClusterer({ map: newMap, markers: newMarkers })

    setMap(newMap)
  }

  useEffect(() => {
    if (markers.length > 0) {
      loadMap()
    }
  }, [markers])

  return (
    <>
      {!map && <h3>Loading...</h3>}
      <div
        id='map'
        style={{ display: 'inline-block', width: '100%', height: '100%', borderRadius: '2rem 0 0 2rem' }}></div>
    </>
  )
}
