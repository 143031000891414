import { useValue } from "../../../components/hooks/useValue"
import selectedOptions from "../../context/reducers/selectedOptions"

const MakeController = () => {
  let state = {
    homepageListingCount: useValue(9),
    maxListingCount: 9,
    searchBarContracted: useValue(false)
  }

  const loadMore = () => {
    if(state.homepageListingCount.get() < state.maxListingCount)
    {
      state.homepageListingCount.set(Math.min(state.homepageListingCount.get() + 9, state.maxListingCount))
    }
  }

  const reset = () => {
    state.maxListingCount = 9
    state.homepageListingCount.set(9)
  }

  return {state, loadMore, reset}
}

export const SearchResultLogic = ({ pushState, api }, processor) => {
    const state = {
      initialListings: [],
      listings: [],
      cities: [],
      searchView: false,
      startDate: null,
      endDate: null,
      numberOfDaysSelected: null,
    }

    const controller = MakeController()
  
    const makeNewListing = (data) => {
      let markerMap = {}
      let positionString = ''
  
      for (let i = 0; i < data.length; i++) {
        positionString = data[i].lat.toFixed(5) + ',' + data[i].lng.toFixed(5)
        if (markerMap[positionString]) {
          markerMap[positionString].push(data[i])
        } else {
          markerMap[positionString] = [data[i]]
        }
      }
  
      return data.map((unit) => {

        let urlParams = ''
        if(selectedOptions.state.selectedDateRange)
        {
          urlParams = `?guests=${selectedOptions.state.guests}${selectedOptions.state.selectedDateRange ? `&startDate=${selectedOptions.state.selectedDateRange.startDate.getTime()}&endDate=${selectedOptions.state.selectedDateRange.endDate.getTime()}` : ''}`
        }

        let listing = {
          unit: unit,
          marker: {
            id: unit.listingId || Math.random().toString(),
            location: { lat: unit.lat, lng: unit.lng },
            controller: null,
            popupContent: `<div style="padding: 0.6rem;"><img style="width: 7.2rem; height: 4.8rem;" src="${unit.image}"><br/><i>${unit.address}</i><!--<br/><a target="_blank" href="/unit/${unit.listingId}/${unit.title.replaceAll(" ","-")+urlParams}">View Unit</a>--></div>`,
          },
        }
        
  
        return processor(listing, markerMap, urlParams)
      })
    }
  
    const getAll = async () => {
      const { data } = await api.get('/units/listings/all-active')
  
      state.listings = makeNewListing(data)
      state.initialListings = state.listings
      controller.state.maxListingCount = state.listings.length
      pushState()
    }
  
    const searchByCity = async (city, guests = 1) => {

      let query = 'guests='+guests
      if(city !== null)
      {
        query = 'city=' + city.city + '&country=' + city.country+'&guests='+guests
      }

      const { data } = await api.get('/units/listings/by-filters?'+query)
  
      state.listings = makeNewListing(data)
      state.searchView = true
      pushState()
    }
  
    const searchByFilters = async ({ city = '', country = '', startDate = '', endDate = '', guests = '' }) => {
      let cityRequest = "city="+city
      let countryRequest = "&country="+country+"&"
      if(!city || !country)
      {
        cityRequest = ''
        countryRequest = ''
      }

      const { data } = await api.get(
        `/units/listings/by-filters?${cityRequest}${countryRequest}startDate=${startDate}&endDate=${endDate}&guests=${guests}`
      )
  
      state.listings = makeNewListing(data)
      state.searchView = true
      pushState()
    }

    const clearSearchView = () => {
      state.searchView = false
      state.listings = state.initialListings
      state.searchView = false
      state.startDate = null
      state.endDate = null
      state.numberOfDaysSelected = null
      pushState()
    }
  
    const loadCities = async () => {
      const { data } = await api.get('/units/listings/cities')
  
      state.cities = data
      pushState()
    }
  
    return { state, searchByCity, searchByFilters, loadCities, getAll, controller, clearSearchView }
  }
  