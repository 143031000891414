import React, { useState } from 'react'
import styled from 'styled-components'

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 120px; */
  /* height: 36px; */
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 3rem;
    border-radius: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.18);
    border-radius: 1.2rem;
    padding: 2rem 1.2rem;
    margin-left: 0rem;
    margin: 0.18rem 0;
    margin-bottom: 1.3rem;
  }
`
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 32px;
  height: 32px; */
  /* background-color: grey; */
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.primary};

  /* border: 1.5px solid lightgrey; */
  box-shadow: 0 1px 3px 0 rgba(32, 33, 36, 0.22);
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f0f0f0;
  }
`

const Text = styled.span`
  font-size: 1.05rem;
  font-weight: bold;
  width: 6.5rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};

  @media screen and (max-width: 768px) {
    font-size: 1.1rem;
    margin: 0 2rem;
  }
`

export const GuestsSelect = ({numberOfGuests = 1, setNumberOfGuests = () => null }) => {

  const handleDecrease = () => {
    if (numberOfGuests > 1) {
      setNumberOfGuests((prevCount) => {
        return prevCount - 1
      })
    }
  }

  const handleIncrease = () => {
    if (numberOfGuests < 5) {
      setNumberOfGuests((prevCount) => {
        return prevCount + 1
      })
    }
  }

  return (
    <Layout>
      <Button onClick={handleDecrease}>
        <Text>-</Text>
      </Button>
      <Text>
        {numberOfGuests} Guest{numberOfGuests !== 1 && 's'}
      </Text>
      <Button onClick={handleIncrease}>
        <Text>+</Text>
      </Button>
    </Layout>
  )
}
