/*class AuthReducer
{
    constructor()
    {
        this.code = "AUTH"
        this.dispatch = () => null
        this.state = {
            username : null,
            account_type: null,
            first_name: null,
            last_name: null,
            email: null,
            is_authenticated: false,
            _id:null,
            loaded: false
        }
    }

    redux(state = this.state, {type})
    {
        if(type == this.code)
        {
            return {...this.state}
        }

        return state
    }

    loadUser({account_type = null, _id = null, username = null, first_name=null, last_name=null, email=null})
    {
        this.state.account_type = account_type
        this.state._id = _id
        this.state.username = username
        this.state.is_authenticated = true
        this.state.loaded = true
        this.state.first_name = first_name;
        this.state.last_name = last_name;
        this.state.email = email;

        this.dispatch({"type":this.code})
    }

    logout()
    {
        this.state.username = null
        this.state.loaded = true
        this.state.account_type = null
        this.state.is_authenticated = false
        this.state._id = null
        this.state.first_name = null;
        this.state.last_name = null;
        this.state.email = null;

        this.dispatch({"type":this.code})
    }
}

const authReducer = new AuthReducer()

export default authReducer*/

const makeSelectedOptionsReducer = () => {
    const code = "SELECTED_OPTIONS"
    let dispatch = () => null
    let state = {
        selectedDateRange: null, // {startDate, endDate}
        guests: 1
    }

    const redux = (newState = state, {type}) => {
        if(type == code)
        {
            return {...state}
        }

        return newState
    }

    const setDispatch = (func) => {
        dispatch = () => func({"type":code})
    }

    const setSearchedDates = (dates) => { // dates = {startDate, endDate}
        state.selectedDateRange = dates
        dispatch()
    }

    const setSearchFilters = ({dates, numberOfGuests}) => { // dates = {startDate, endDate}
        state.selectedDateRange = dates
        state.guests = numberOfGuests
        dispatch()
    }

    const resetSearchFilters = () => {
        state.selectedDateRange = null
        state.guests = 1
        dispatch()
    }

    return {state, redux, setDispatch, setSearchedDates, setSearchFilters, resetSearchFilters}
}

export const selectedOptions = makeSelectedOptionsReducer()

export default selectedOptions