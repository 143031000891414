import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTransition, animated } from 'react-spring'

import image1 from '../../../app/media/img/toronto-dt.jpg'
import image2 from '../../../app/media/img/listing-example-tba2.jpg'
import image3 from '../../../app/media/img/listing-example-tba4.jpg'
import image4 from '../../../app/media/img/listing-example-tba3.jpg'
import { Image, Row, SectionTitle, VerticalText } from '../Style'
import { Col } from '../../../components/layouts/Col'

export const Layout = styled.div``

export const Section = styled(animated.section)`
  width: 100%;
  height: ${({ height }) => (height ? height : '100vh')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 13vh;
  margin-bottom: 36vh;

  @media (max-width: 768px) {
    height: auto;
    margin-bottom: 50vh;
  }
`

export const LargeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 80rem;
  height: 47vh;
  border-radius: 2rem;
  padding: 3rem 5rem;
  background: #fafafa;

  @media (max-width: 768px) {
    width: 100%;
    padding: 3.5rem;
    padding: 1rem;
    padding: 2.5rem;
    line-height: 1.85;

    /* flex-direction: column-reverse; */
  }
`

export const SectionContent = styled.p`
  font-size: 1.7rem;
  color: #666;
  margin: 0 auto;
  margin-bottom: 2rem;
  line-height: 1.6;

  /* padding: 1rem; */

  @media screen {
    font-size: 1.9rem;
  }
`

export const OurApartments = ({ style }) => {
  const [index, setIndex] = useState(0)
  const images = [image1, image2, image3, image4]

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1))
    }, 4000)

    return () => {
      clearInterval(interval)
    }
  }, [images.length])

  const transitions = useTransition(index, {
    key: index,
    from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  })

  return (
    <Layout>
      <Section height='70vh' style={style}>
        <LargeCard>
          <SectionTitle>Our Apartments</SectionTitle>
          <SectionContent>
            Each of our apartments is thoughtfully curated to provide the ultimate comfort and convenience. From stylish
            furniture to high-end amenities, we take pride in creating spaces that feel like home.
          </SectionContent>
          <Row>
            {transitions((style, item) => (
              <>
                <Image style={{ ...style, position: 'absolute' }} src={images[item]} alt={`Apartment ${item + 1}`} />
                {/* <VerticalText>Modern</VerticalText> */}
              </>
            ))}
          </Row>
        </LargeCard>
      </Section>
    </Layout>
  )
}
