import React from 'react'
import styled from 'styled-components'
// import Marquee, { Motion, randomIntFromInterval } from 'react-marquee-slider'
import Marquee from 'react-fast-marquee'
import { Col } from '../../../components/layouts/Col'
import image from '../../../app/media/img/reviews-total.png'

const Section = styled.section`
  position: relative;
  width: 100%;
  height: 85vh;
  background-image: url('toronto.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(-150deg, black, ${({ theme }) => theme.colors.primary});
  background: black;
  opacity: 0.75;
  z-index: 0;
`

const ScrollingContainer = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const ScrollingText = styled(Marquee)`
  width: max-content;
  color: #fff;
  font-size: 24px;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 2.13rem;
  }
`

const Quote = styled.div`
  padding: 5px 10px;
  /* background-color: yellow; */
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 10px;
`

const CallToActionButton = styled.a`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-weight: 900;
  font-size: 1.5rem;
  border: none;
  border-radius: 4px;
  max-width: 35rem;
  margin: 4rem auto;
  z-index: 1;
  opacity: 0.85;
`

export const ReviewsImage = styled.img`
  max-width: 50rem;
  opacity: 0.75;
  border-radius: 2rem;
  margin: auto;
  /* margin-left: auto; */

  @media screen and (max-width: 768px) {
    margin: 0 1rem;
  }
`

export const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  z-index: 2;
  margin: 4rem auto;
  font-size: 3rem;

  @media screen and (max-width: 768px) {
    font-size: 2.3rem;
    margin: 0 auto;
    width: 90%;
    text-align: center;
  }
`

export const Reviews = () => {
  const quotes = [
    'The place my boyfriend and I stayed at was very modern, clean, and situated at a great location.',
    'The neighborhood is perfect, everything is in walking distance and the subway is only 5 minutes away. We stayed for nearly 3 months and would come back anytime.',
    'First class and highly recommended.',
    'The property was as described and exceeded expectations.',
    "The location can't be matched. It's in one of the best spots in Toronto.",
  ]
  return (
    <Section>
      <H2>Client Feedback: A Testament to Excellence</H2>
      <Overlay />
      <Col>
        <ScrollingText loop={0}>
          {quotes.map((q) => (
            <Quote>
              {
                <>
                  <i className='fa-solid fa-quote-left' />
                  &nbsp;
                </>
              }
              {q}
            </Quote>
          ))}
        </ScrollingText>
        <br />

        {/* <ScrollingContainer>
        <ScrollingText velocity={50} direction='left' minScale={1} drag={false}>
          {quotes.map((quote, index) => (
            <Motion
              key={`child-${index}`}
              // initDeg={randomIntFromInterval(0, 180)}
              // direction={Math.random() > 0.5 ? 'clockwise' : 'counterclockwise'}
              // velocity={50}
              // radius={50}
            >
              <Quote>{quote}</Quote>
            </Motion>
          ))}
        </ScrollingText>
      </ScrollingContainer> */}

        <br />
        <ReviewsImage src={image} alt='' />

        <CallToActionButton
          href='https://www.google.com/maps/place/Toronto+Boutique+Apartments/@43.6702777,-79.3941764,17z/data=!4m8!3m7!1s0x882b34aff9aa3faf:0xa78918a4e934f127!8m2!3d43.6702777!4d-79.3916015!9m1!1b1!16s%2Fg%2F1tdmw7l0?entry=ttu'
          target='_blank'>
          View all our reviews on Google
        </CallToActionButton>
      </Col>
    </Section>
  )
}
