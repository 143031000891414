export const paginationLogic = ({pushState, list = [], elementsPerPage = 10}) => {
    const state = {
        page: 0,
        numberOfPages: Math.ceil(list.length/elementsPerPage),
        elementsPerPage,
        pageData: list.slice(0,elementsPerPage)
    }

    const updatePageData = () => {
        state.pageData = list.slice(state.page*elementsPerPage,(state.page+1)*elementsPerPage)
        pushState()
    }
    

    const previousPage = () => {
        if(state.page > 0)
        {
            state.page--
            updatePageData()
        }
    }

    const nextPage = () => {
        if(list.length % elementsPerPage === 0)
        {
            if(state.page+1 < Math.floor(list.length / elementsPerPage))
            {
                state.page++
                updatePageData()
            }
        }
        else if(state.page < Math.floor(list.length/elementsPerPage))
        {
            state.page++
            updatePageData()
        }
    }

    const setPage = (index) => {
        let page = index
        if(page < 0)
        {
            page = 0;
        }
        else if(page >= Math.floor(list.length/elementsPerPage))
        {
            page = Math.floor(list.length/elementsPerPage)
        }

        state.page = page
        updatePageData()
    }

    return {state, previousPage, nextPage, setPage}
}