import {useRef} from 'react'
import { Map } from '../../../features/Map/searchResult'

export const MapView = ({markers}) => {

    const currentlyOpenedPin = useRef(null)

    const onMarkerClick = (marker) => {
        if (currentlyOpenedPin.current) {
          currentlyOpenedPin.current.marker.controller.closePopup()
        }
    
        currentlyOpenedPin.current = { marker }
        marker.controller.openClickPopup()
    }

    return (
        <Map
            markers={markers}
            height={'calc(100vh - 24rem)'}
            onMarkerClick={onMarkerClick}
        />
    )
}