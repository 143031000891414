import styled from 'styled-components'
import { H1 as H1r } from '../../../components/elements/H1'
import Button from '../../../components/elements/Button'

export const Layout = styled.div`
  position: relative;
  margin-top: 6rem;
`

export const TextDescription = styled.span`
  font-size: 1.23rem;
  color: grey;
  margin-bottom: 2.5rem;
`

export const H1 = styled(H1r)`
  font-size: 2.6rem;
  color: black;
`

export const MobileButton = styled(Button)`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    /* width: 100%; */
    top: 10vh;

    position: fixed;
    left: 1rem;
    right: 1rem;
    height: 3rem;
    text-transform: uppercase;
    font-size: 1rem;
    z-index: 10;
  }
`
