import { Col } from '../../../../components/layouts/Col'
import { GridRow } from '../../../../components/layouts/GridRow'
import { Row } from '../../../About/Style'
import { Card, H3, H4 } from '../../Style'
import { Card2, StyledTextCarousel } from './Style'

export const DescriptionGallery = ({ descriptions }) => {
  let tabs = []

  if(descriptions.summary)
  {
    tabs.push({
      name: 'summary',
      text: descriptions.summary.split('\n').map((text, index) => <p key={'block_' + index}>{text}</p>),
    })
  }
  if (descriptions.space) {
    tabs.push({
      name: 'the space',
      text: descriptions.space.split('\n').map((text, index) => <p key={'block_' + index}>{text}</p>),
    })
  }
  if (descriptions.neighborhood) {
    tabs.push({
      name: 'the neighborhood',
      text: descriptions.neighborhood.split('\n').map((text, index) => <p key={'block_' + index}>{text}</p>),
    })
  }
  if (descriptions.transit) {
    tabs.push({
      name: 'transit',
      text: descriptions.transit.split('\n').map((text, index) => <p key={'block_' + index}>{text}</p>),
    })
  }

  return (
    <Card style={{ marginBottom: '2rem', width: '100%' }}>
      <StyledTextCarousel>
        {tabs.map((tab, index) => (
          <input
            key={'radio_' + tab.name}
            hidden
            type='radio'
            name='carousel-control'
            id={'button_' + index}
            defaultChecked={index === 0}
          />
        ))}
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}
          id='navigation'>
          <H3>About</H3>
          <br />
          <br />
          {/* &nbsp;<H4 style={{ margin: '0rem 1rem' }}>•</H4> */}
          <GridRow col='2' gridGap='1rem'>
            {tabs.map((tab, index) => (
              <label key={'label_' + tab.name} for={'button_' + index} className={'label_' + index}>
                <Card2>{tab.name}</Card2>
              </label>
            ))}
          </GridRow>
        </div>
        <div id='carousel'>
          <div class='p-wrapper'>
            {tabs.map((tab, index) => (
              <section key={'panel_' + index} class={'panel_' + index}>
                {tab.text}
              </section>
            ))}
          </div>
        </div>
      </StyledTextCarousel>
    </Card>
  )
}
