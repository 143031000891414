import { useInView } from "react-intersection-observer"
import { useSpring } from 'react-spring'
import {cloneElement} from 'react'

export const TextSpring = ({triggerOnce = true, threshold = 0.7, children}) => {
    const [ref, inView] = useInView({
        triggerOnce,
        threshold,
    })

    const textSectionSpring = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(30px)',
        config: { tension: 200, friction: 20 },
    })

    return (
        <>{cloneElement(children, { ref, style: textSectionSpring})}</>
    )
}