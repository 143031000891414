import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

let footerHeight = 3.5

export const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${footerHeight}rem;
  padding: 0 5rem;
  /* background: #72a9b0; */
  background: white;
  box-shadow: rgba(50, 50, 93, 0.05) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.25) 0px -2px 4px 0px inset;
  z-index: 1;
  font-size: 1.3rem;

  @media screen and (max-width: 768px) {
    padding: 0 1rem;
    font-size: 1.1rem;
  }
`

export const NavLinks = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  list-style: none;
  margin-left: 2rem;
  font-size: 1.6rem;
  color: #333;
  font-weight: bold;

  path,
  span {
    fill: #053b47;
    color: #053b47;
  }

  &:hover {
    color: #666;
  }

  @media screen and (max-width: 768px) {
    display: block;
    margin-left: 0px;
  }
`
