import {useState, useEffect} from 'react'

export const useValue = (val) => {


    let subscribers = new Set()

    let currentValue = val

    const get = () => currentValue

    const set = (newVal) => {
        currentValue = newVal
        subscribers.forEach(setState => setState(currentValue))
    }

    const onChange = (func) => {
        subscribers.add(func)
        return () => subscribers.delete(func)
    }

    const Value = ({mapObject = null, mapArray = null, render = null, conditionalRender=null, children}) => {

        const [state, setState] = useState(get())

        useEffect(() => {
            subscribers.add(setState)
            return () => subscribers.delete(setState)
        }, [])

        if(render)
        {
            return render(state)
        }

        if(conditionalRender)
        {
            if(state)
            {
                return <>{conditionalRender(state)}</>
            }
            else
            {
                return <></>
            }
        }

        if(mapArray)
        {
            return state.map((value, index) => mapArray(value,index))
        }

        if(mapObject)
        {
            return Object.keys(state).map((key, index) => mapObject(key,state[key],index))
        }

        if(children)
        {
            return <>{children}</>
        }

        return (
            <>{state}</>
        )
    }

    return {Value, get, set, onChange}
}