import {PaymentElement, AddressElement, useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import api from "../../../utils/api";
import { unitLogic } from "../../../app/providers/unit/logic";

const logic = unitLogic({api, pushState: () => null})


export const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements();

    const addressElementOptions = {
        mode: 'billing',
        autocomplete: {
            mode: "google_maps_api",
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        },
        fields: {
            phone: 'always',
        },
        validation: {
            phone: {
                required: 'always',
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const submittedElements = await elements.submit();
        const {error: submitError} = submittedElements
        if (submitError) {
            return;
        }

        const addressElement = elements.getElement(AddressElement);
        const {value: addressValue} = await addressElement.getValue();
    
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                address: addressValue.address,
                email: "snevarko@hotmail.com",
                name: addressValue.name,
                phone: addressValue.phone
            }
        })

        await logic.bookUnit({paymentMethod})

    };

    const onRefundClick = async () => {
        await logic.refundDeposit()
    }



    return (
        <>
        <form onSubmit={handleSubmit}>
            <AddressElement options={addressElementOptions}/>
            <CardElement options={{hidePostalCode: true}} />
            <button style={{padding:"1rem",background:"green"}}>Submit</button>
        </form>
        <div><button onClick={onRefundClick}>Refund</button></div>
        </>
    )
}