import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate, NavLink } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import logoLong from '../../media/img/logoLong.png'
import { LogoWithText } from '../../../components/elements/Logo/LogoWithText'
import logoMark from '../../media/img/logoMark.png'

const HeaderStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.3rem 2rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  @media (max-width: 768px) {
    padding: 0.5rem 1.5rem;
    height: 7rem;
  }
`

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40rem; /* Adjust as needed */

  background-color: #fff;
  z-index: 999;
  overflow: hidden;
  transform: translateX(${({ open }) => (open ? '0' : '100%')});
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    transform: translateX(${({ open }) => (open ? '0' : '100%')});
  }
`

const Logo = styled.img`
  height: 4.5rem;
  margin-right: 1rem;
  cursor: pointer;
`

const LogoShort = styled.img`
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
  width: 6.5rem;
  cursor: pointer;
`

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 0px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1000;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  i {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 2rem;
    /* color: lightgrey; */
  }

  @media screen and (max-width: 768px) {
    i {
      font-size: 2.5rem;
    }
  }
`

const MenuContent = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const NavLinkItem = styled(animated(NavLink))`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.primary};
  animation: ${fadeIn} 0.5s ease-in-out;
  font-size: 2.15rem;
  font-weight: 500;

  &.active {
    font-weight: bold;
  }
`

const Hamburger = styled.div`
  display: block;
  cursor: pointer;
`

const HamburgerBar = styled.div`
  width: 3rem;
  height: 0.16rem;
  margin-right: 0.4rem;

  background-color: ${({ theme }) => theme.colors.primary};

  &:first-of-type {
    margin-bottom: 1rem;
  }
  &:nth-of-type(2) {
    margin-top: 1rem;
  }
`

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated)

  const handleToggle = () => {
    if (isOpen) {
      document.body.classList.remove('lock-scroll')
      setIsOpen(false)
    } else {
      document.body.classList.add('lock-scroll')
      setIsOpen(true)
    }
  }

  const menuItems = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/about' },
    { label: 'Contact', path: '/contact' },
    { label: 'Terms of service', path: '/terms-of-service' },
    { label: 'Privacy & cookie', path: '/privacy-policy' },
  ]

  return (
    <HeaderStyle>
      {/* <Logo src={logoLong} alt='Logo' onClick={() => navigate('/')} /> */}
      <LogoWithText onClick={() => setIsOpen(false)} />
      <Hamburger open={isOpen} onClick={handleToggle}>
        <HamburgerBar />
        <HamburgerBar />
      </Hamburger>

      <Container open={isOpen}>
        <CloseButton>
          <i className='fa-solid fa-circle-x' onClick={handleToggle}></i>
        </CloseButton>
        <MenuContent>
          {isOpen &&
            menuItems.map((item, index) => (
              <NavLinkItem key={index} exact to={item.path} activeClassName='active' onClick={handleToggle}>
                {item.label}
              </NavLinkItem>
            ))}
        </MenuContent>
        <LogoShort src={logoMark} />
      </Container>
    </HeaderStyle>
  )
}
