import { createRoot } from 'react-dom/client'
import App from './app'
import { BrowserRouter as Router } from 'react-router-dom'
import store from './app/context/store'
import { Provider } from 'react-redux'
import ReactGA from "react-ga4"
import { ThemeProvider } from 'styled-components'
import { themeSettings } from './app/theme/theme-settings'
import { analytics } from './utils/analytics/GTM'

const container = document.getElementById('root')
const root = createRoot(container)

//ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={themeSettings}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
)
