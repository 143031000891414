import styled from 'styled-components'
// import { Card } from '../../../components/layouts/Card'
import {
  Box,
  Card,
  Img,
  Price,
  ReviewStars,
  ShortDescription,
  TextEmphasis,
  Title,
  TotalPriceLabel,
  ViewUnitLink,
} from './Style'
import { Row } from '../../../components/layouts/Row'
import { Icon } from '../../../components/elements/Icon'
import { deskIcon, gymIcon, parkingIcon, poolIcon } from '../../../app/media/icons'
import { GridRow } from '../../../components/layouts/GridRow'

export const ListingPopup = ({ data, onMouseEnter = () => null, navigate = () => null, urlParams='' }) => {
  let { marker, unit } = data
  let { address, image, lat, lng, listingId, title, summary, bathrooms, bedrooms, price, parking, gym, pool, desk } =
    unit

  const onListingClick = () => {
    //navigate('/listing-page/' + listingId)
  }

  return (
    <Card onClick={onListingClick} onMouseEnter={onMouseEnter}>
      <Row margin='0px'>
        <Img src={image} />
        <div
          style={{
            padding: '0 1rem',
            maxWidth: '30rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}>
          <Row margin='0rem 0' justify='' style={{ display: 'block' }}>
            <Title>{title}</Title>
          </Row>
          {/* <ShortDescription>{summary}</ShortDescription> */}
          <Row>
            <Box>
              <TextEmphasis>{bedrooms}</TextEmphasis> bedroom{bedrooms !== 1 && 's'}
            </Box>
            <Box>
              <TextEmphasis>{bathrooms}</TextEmphasis> bathroom{bathrooms !== 1 && 's'}
            </Box>
            {parking && (
              <Box>
                <Icon size='2.1rem'>{parkingIcon}</Icon> parking
              </Box>
            )}
            {gym && (
              <Box>
                <Icon size='2.1rem'>{gymIcon}</Icon> gym
                {/* RIGHT ICON --> <i class="fa-duotone fa-dumbbell"></i> */}
              </Box>
            )}
            {pool && (
              <Box>
                <Icon size='2.1rem'>{poolIcon}</Icon> pool
              </Box>
            )}
            {desk && (
              <Box>
                <Icon size='2.1rem'>{deskIcon}</Icon> desk
              </Box>
            )}
          </Row>
          <Row align='center' margin='0'>
            <Price>From ${price.startingFrom}/night</Price>
            {/*<TotalPriceLabel>$1,945 total</TotalPriceLabel>*/}
            <ViewUnitLink target='_blank' href={'/listing/' + listingId + '/' + title.replaceAll(" ","-")+urlParams}>
              View unit
            </ViewUnitLink>
          </Row>
        </div>
      </Row>
    </Card>
  )
}
