import styled, { css } from 'styled-components'
import { Form as FormRef } from '../../base/gui/controls/Form/index.js'

export const Form = styled(FormRef)`
  & > div {
    width: 100%;
  }

  .callbackError {
    background: red;
    color: white;
    padding: 4px;
    border-radius: 7px;
  }
`

export const inputStyles = css`
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
`

export const TextField = styled.div`
  ${inputStyles}
  display:flex;
  flex-direction: column;
  margin: 1rem;
  padding: 0.4rem;
  border-radius: 0.4rem;

  label {
    font-size: 1.2rem;
    color: grey;
  }

  input, textarea {
    border: none;
    background: ${({ theme }) => theme.colors.primary}10;
    padding: 0.4rem;
    border-radius: 0.4rem;
    margin-top: 0.3rem;
    width: 100%;

    :focus {
      outline: none;
    }
  }
`

export const CheckboxField = styled.div`
  ${inputStyles}
  a {
    color: blue;
  }

  a:hover {
    text-decoration: underline;
  }
`

export const StyledErrorBanner = styled.p`
  background-color: ${({ theme }) => theme.colors.error};
  color: white;
  border-radius: 7px;
  font-weight: 600;
  padding: 8px;
`

export const LabelStyle = styled.div`
  color: rgb(48, 49, 61);
  font-size: 14.88px;
  font-weight: 400;
  margin-bottom: 4px;
`
