import styled, { css } from 'styled-components/macro'
import { TextEmphasis as TextEmphasisRef } from '../../../components/elements/TextEmphasis'
import Button from '../../../components/elements/Button'
import SelectRef from 'react-select'

import { GridRow } from '../../../components/layouts/GridRow'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 4rem 3.5rem;
  border-radius: 1rem;
  width: 35%;
  min-width: 50rem;
  background: white;

  @media screen and (max-width: 768px) {
    display: flex;
    width: 100%;
    min-width: 0rem;
    padding: 2rem 2rem;
  }
`

export const Text = styled.span`
  font-size: 2rem;
  font-weight: 500;
`
export const TextL = styled.span`
  font-size: 2.7rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`

export const Button2 = styled(Button)`
  background: white;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export const H2 = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`

export const TextEmphasis = styled(TextEmphasisRef)`
  font-size: 2.5rem;
  font-weight: 600;
`

export const Label = styled.label`
  font-size: 1rem;
  color: grey;
`

export const Date = styled.span`
  font-weight: 500;
  margin-bottom: 0.1rem;

  ${(props) =>
    !props.isFocused
      ? ''
      : `
        margin-bottom: 0rem;
        border-bottom: solid 0.1rem ${props.theme.colors.primary};
  `}
`

const Banner = styled.div`
  height: 9rem;
  background-color: red;
  position: fixed;
  width: 100%;
  top: 7.1rem;
  left: 0px;
  z-index: 5;
`

export const AnimatedCard = styled(Card)`
  transition: height 0.3s ease;
  /*position: fixed;*/
  position: absolute;
  width: 100%;
  justify-self: center;
  max-width: 650px;
  height: fit-content;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  z-index: 2;
  min-height: 35vh;

  ${({ view }) =>
    view === 'book-now' &&
    css`
      /*position: absolute;*/
    `}

  @media screen and (max-width: 768px) {
    position: inherit;
  }
`

export const Accordion = styled.div`
  .hidden-checkbox {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  /* Accordion styles */
  .tab {
    width: 100%;
    /*color: ${(props) => props.theme.colors.primary};*/
    overflow: hidden;
    border-radius: 1rem;
  }
  .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    font-weight: bold;
    cursor: pointer;
    color: ${(props) => props.theme.colors.primary};
    /* Icon */
  }
  .tab-label:hover {
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.secondary};
  }
  .tab-label::after {
    content: '❯';
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
  }
  .tab-content {
    max-height: 0;
    padding: 0 1em;
    background: white;
    transition: all 0.35s;
  }
  .tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: ${(props) => props.theme.colors.secondary};
    cursor: pointer;
  }
  .tab-close:hover {
    background: ${(props) => props.theme.colors.secondary};
  }

  .hidden-checkbox:checked + .tab-label {
    color: ${(props) => props.theme.colors.primary};
    background: ${(props) => props.theme.colors.secondary};
  }
  .hidden-checkbox:checked + .tab-label::after {
    transform: rotate(90deg);
  }
  .hidden-checkbox:checked ~ .tab-content {
    max-height: 100vh;
    padding: 1em;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const PriceBreakdown = styled.div`
  .dateString {
    color: ${(props) => props.theme.colors.tertiary};
    font-size: 1.2rem;
  }

  .phaseTotal {
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.8rem;
  }

  .phasesContainer {
    margin-bottom: 1rem;
  }

  .phase {
    text-align: right;
  }

  .phase-name {
    color: ${(props) => props.theme.colors.primary2};
  }

  .phase-amount {
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    display: inline-block;
    width: 10rem;
    text-align: right;
  }

  .separator {
    border: none;
    height: 1px;
    background: #f0f0f0;
  }
`

export const OverallPrice = styled.div`
  margin-bottom: 1rem;

  .price-title {
    font-weight: 600;
    font-size: 1.6rem;
    color: ${(props) => props.theme.colors.tertiary};
    margin-bottom: 0.5rem;
  }

  .price-item {
    text-align: right;
  }

  .item-name {
    color: ${(props) => props.theme.colors.tertiary};
    font-size: 1.2rem;
  }

  .item-amount {
    display: inline-block;
    width: 8rem;
    color: ${(props) => props.theme.colors.primary};
    text-align: right;
    font-size: 1.4rem;
  }

  .price-total {
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
    font-size: 1.8rem;
  }
`

export const CancellationPolicy = styled.div`
  li {
    margin: 24px 0px;
    color: #888;
  }

  strong {
    color: ${(props) => props.theme.colors.primary};
  }
`

export const Select = styled(SelectRef)`
  border: none;
  width: fit-content;
  min-width: 20rem;

  @media screen and (max-width: 768px) {
    min-width: 15rem;
  }

  input  {
    border: none;
  }

  div {
    border: none;
  }

  .css-1u9des2-indicatorSeparator {
    display: none;
  }

  .css-t3ipsp-control {
    box-shadow: 0 0 0 0px ${({ theme }) => theme.colors.primary};
    border: none;
    min-height: 19px;
    justify-content: flex-start;
  }

  .css-t3ipsp-control:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  /*.css-tj5bde-Svg {
    width: 19px;
    height: 19px;
  }*/

  /*.css-1xc3v61-indicatorContainer {
    padding: 0px;
    display: none;
  }

  .css-15lsz6c-indicatorContainer,
  .css-tj5bde-Svg {
    display: none;
  }*/

  .css-qbdosj-Input {
    padding: 0px;
    margin: 0px;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0px;
    width: auto;
    flex-basis: auto;
    flex-grow: 0;
  }

  .css-13cymwt-control {
    min-height: 19px;
    justify-content: flex-start;
  }

  .css-166bipr-Input {
    padding: 0px;
    margin: 0px;
  }

  .css-d7l1ni-option {
    background-color: ${({ theme }) => theme.colors.primary}44;
  }

  .css-d7l1ni-option:active {
    background-color: ${({ theme }) => theme.colors.primary}44;
  }

  .css-tr4s17-option {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .css-tr4s17-option:active {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .css-1jqq78o-placeholder {
    color: inherit;
  }

  .css-1nmdiq5-menu {
    width: auto;
  }
`

export const Span = styled.span`
  font-size: 1.5rem;
  a {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`

export const CustomGridRow = styled(GridRow)`
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`
