import styled from 'styled-components'
import {StyledInputWrapper} from './style'
import { FormLabel } from './style'
import { FieldWrapper } from '../../components/base/gui/controls/Form'
import Joi from 'joi'

export const StyledFormTextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  /*margin-bottom: 16px;*/
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  &:focus {
    border-color: #4caf50;
    outline: none;
  }
`

export const FormTextArea = ({ label = "Text", name="text"}) => {
    let idx = 0
    let labelHtml = label.split('').map((letter) => <span style={{"transitionDelay":`${idx++ * 50}ms`}}>{letter}</span>)
  
    return (
      <FormLabel>
        {labelHtml}
          <FieldWrapper name={name} validation={Joi.string().required().messages({ 'string.empty': 'this field is required', 'string.base': 'this field is required' })} StyleWrapper={StyledInputWrapper}>
            <StyledFormTextArea />
          </FieldWrapper>
      </FormLabel>
    )
}