//import Analytics from 'analytics'
//import googleTagManager from '@analytics/google-tag-manager'

/*export const analytics = Analytics({
  plugins: [
    googleTagManager({
      containerId: process.env.REACT_APP_GTAG_ID
    })
  ]
})*/

export const sendPageViewGTM = () => {
    //console.log('sendPageViewGTM')
    /* Track a page view */
    //analytics.page({url: window.location.pathname})

    window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': window.location.pathname
    });
}

export const trackCustomGTM = (event, obj) => {
    /* Track a custom event */
    //analytics.track(event, obj)

    window.dataLayer.push({
        event,
        ...obj
    });
}

export const sendCustomPageViewGTM = (path) => {
  /* Track a page view */
  //analytics.page({url: window.location.protocol + "//" + window.location.host+path})

    window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': window.location.protocol + "//" + window.location.host+path
    });
}