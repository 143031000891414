export const NumberTestLogic = ({pushState}) => {

    const state = {
        currentNumber: 0
    }

    const increment = () => {
        state.currentNumber++
        pushState()
    }

    return {state, increment}
}