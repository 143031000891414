import { AddressElement, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { ErrorBanner, Form, SubmitButton } from '../../../components/inputs/Form'
import { useParams } from 'react-router-dom'
import { useValue } from '../../../components/hooks/useValue'
import Joi from 'joi'
import { CheckboxField } from '../../../components/inputs/Form/CheckboxField'
import { Col } from '../../../components/layouts/Col'
import { H2, Span } from './Style'
import { Card } from '../../../components/layouts/Card'
//import { sendCustomPageView } from '../../../utils/analytics'
import { sendCustomPageViewGTM } from '../../../utils/analytics/GTM'

export const StripeForm = ({ logic }) => {
  const stripe = useStripe()
  const elements = useElements()
  const params = useParams()
  const paymentResponse = useValue(null)

  const addressElementOptions = {
    mode: 'billing',
    autocomplete: {
      mode: 'google_maps_api',
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    },
    fields: {
      phone: 'always',
    },
    validation: {
      phone: {
        required: 'always',
      },
    },
  }

  const onSubmit = async ({ values, callback }) => {
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }

      const submittedElements = await elements.submit()
      const { error: submitError } = submittedElements
      if (submitError) {
        return
      }

      const addressElement = elements.getElement(AddressElement)
      const { value: addressValue } = await addressElement.getValue()

      let cardElement = elements.getElement(CardElement)
      console.log('cardElement', cardElement)

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: addressValue.address,
          email: logic.state.emailSent.get(),
          name: addressValue.name,
          phone: addressValue.phone,
        },
      })

      if (error) {
        callback(error.message)
        return
      }

      let response = await logic.bookUnit({ paymentMethod, listingId: params.listingId })
      paymentResponse.set(response)
      if(response)
      {
        try
        {
          sendCustomPageViewGTM('/thank-you')
        }catch(e){/*do nothing*/}
      }
    } catch (err) {
      alert(err.message)
      console.log(err)
      callback(err.message)
    }
  }

  return (
    <paymentResponse.Value
      render={(paymentResponse) => (
        <>
          {!paymentResponse && (
            <Col>
              <Form onSubmit={onSubmit}>
                <ErrorBanner />
                <AddressElement options={addressElementOptions} />
                <Col margin='3rem 0'>
                  <CardElement options={{ hidePostalCode: true }} />
                </Col>
                <CheckboxField
                  name='tos'
                  validation={Joi.boolean()
                    .invalid(false)
                    .required()
                    .messages({ 'any.invalid': 'You must accept these terms to continue.' })}
                  label={
                    <Span>
                      I have read and accept the{' '}
                      <a target='_blank' href='/privacy-policy'>
                        Privacy Policy
                      </a>{' '}
                      |{' '}
                      <a target='_blank' href='/terms'>
                        Toronto Boutique Apartments.com Terms and Conditions
                      </a>
                    </Span>
                  }
                />
                <br />
                <SubmitButton>Pay</SubmitButton>
              </Form>
            </Col>
          )}
          {paymentResponse && (
            <Card style={{width: "100%"}}>
              <H2>Payment successfully processed.</H2>
            </Card>
          )}
        </>
      )}
    />
  )
}
