import React, { useState } from 'react'
import styled from 'styled-components'
import { useUnitDetails } from '../../../app/providers/unit'
import { H3, H4 } from '../Style'
import { Row } from '../../../components/layouts/Row'
import { GridRow, Text } from './Style'

import { DescriptionGallery } from './DescriptionGallery'

// import icon from '../../../app/media/icons/TV.svg'

export const Layout = styled.div`
  position: relative;
`

export const Block = styled.div`
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  padding: 4rem 0;
`

export const Amenity = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  font-size: 1.4rem;
  /*height: 6rem;*/

  i {
    margin-bottom: 1.5rem;
    color: grey;
    transition: 200ms all ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      transition: 200ms all ease-in-out;
    }
  }
`

export const AmenityIcon = styled.img`
  width: 3rem;
  height: 3rem;
`

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  cursor: pointer;
`

const MAX_DESCRIPTION_SENTENCES = 8

export const UnitMainInfo = () => {
  const { unitData } = useUnitDetails('unitData')

  const {
    title,
    descriptions,
    address,
    amenities,
    guests,
    checkInTime,
    checkOutTime,
    terms: { minNights, maxNights },
  } = unitData

  const [showFullDescription, setShowFullDescription] = useState(false)

  const toggleDescription = () => {
    //setShowFullDescription(!showFullDescription)
  }

  const renderAmenities = () => {

    /*
    [
      'TV',
      'Cable TV',
      'Wireless Internet',
      'Air conditioning',
      'Outdoor pool',
      'Kitchen',
      'Free parking on premises',
      'Gym',
      'Elevator',
      'Heating',
      'Washer',
      'Dryer',
      'Essentials',
      'Shampoo',
      'Hangers',
      'Hair dryer',
      'Iron',
      'Self check-in',
      'Smartlock',
      'Hot water',
      'Bed linens',
      'Microwave',
      'Coffee maker',
      'Refrigerator',
      'Dishwasher',
      'Dishes and silverware',
      'Cookware',
      'Oven',
      'Stove',
      'Patio or balcony',
      'Smoke detector',
      'Suitable for children (2-12 years)',
      'Internet',
      'Private entrance',
      'Carbon monoxide detector',
      'Suitable for infants (under 2 years)',
      'Doorman',
      'First aid kit',
      '24-hour check-in',
      'Paid parking off premises',
      'Hot tub',
      'Extra pillows and blankets',
      'Single level home',
      'Luggage dropoff allowed',
      'Long term stays allowed',
      'Waterfront',
      'Lockbox',
      'Toaster',
      'Towels provided',
      'Buzzer/wireless intercom',
      'Fire extinguisher',
      'Private living room',
      'Bathtub',
      'Indoor fireplace',
      'Laptop friendly workspace',
      'High touch surfaces disinfected',
      'Kettle',
      'Free parking on street',
      'Private pool',
      'Garden or backyard',
      'Downtown',
      'Cleaning Disinfection',
      'Enhanced cleaning practices',
      'Indoor pool',
      'Beach',
      'Stereo',
      'Room-darkening shades',
      'Water View',
      'Host greets you',
      'Beach essentials',
      'Beach Front',
      'Beach View',
      'Children’s dinnerware',
      'High chair',
      'Pack ’n Play/travel crib',
      'Lake',
      'Lake access',
      'Lake Front',
      'Desk',
      'Swimming pool',
    ]
    */

    return amenities.map((a) => {
      const shortName = a.replace('/',' ').replace('’',"'").replaceAll(' ','-').toLowerCase()
      console.log(shortName)
      const iconPath = `/icons/${shortName}.svg`
      return (
        <Amenity key={a}>
          <AmenityIcon src={iconPath} alt={a} />
          {a}
        </Amenity>
      )
    })
  }

  const descriptionSentences = descriptions.space ? descriptions.space.split('. ') : ['']
  let visibleDescription = showFullDescription
    ? descriptionSentences.join('. ')
    : descriptionSentences.slice(0, MAX_DESCRIPTION_SENTENCES).join('. ')
  const isReadMoreVisible = descriptionSentences.length > MAX_DESCRIPTION_SENTENCES

  return (
    <Layout>
      <DescriptionGallery descriptions={descriptions} />

      {/*<Block>
        <Row align='center' margin='0' marginBottom='2rem' height='auto'>
          <H3>About</H3>&nbsp; <H4>• the space</H4>
        </Row>
        <Text>{visibleDescription.split("\n").map(p => <p>{p}</p>)}</Text>
        {isReadMoreVisible && (
          <Button onClick={toggleDescription}>{showFullDescription ? 'Read less' : 'Read more'}</Button>
        )}
        </Block>*/}

      <Block style={{ marginTop: '10vh' }}>
        <Row align='center' margin='0' marginBottom='2rem' height='auto'>
          <H3>Amenities</H3>&nbsp; <H4>• included with the space</H4>
        </Row>
        <GridRow col='5'>{renderAmenities()}</GridRow>
      </Block>

      {descriptions.houseRules && (
        <Block>
          <Row align='center' margin='0' marginBottom='2rem' height='auto'>
            <H3>House rules</H3>&nbsp; <H4>• and more</H4>
          </Row>
          <Text>
            {descriptions.houseRules.split("\n").map((text, index) => (
              <p key={'block_' + index}>{text}</p>
            ))}
          </Text>
          {/*JSON.stringify(descriptions)*/}
        </Block>
      )}
    </Layout>
  )
}
