import { Loader } from "@googlemaps/js-api-loader"
import { useEffect, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    version: "weekly"
});

var MapLibary = null;

const MapTest = () => {

    const [map, setMap] = useState(null)

    const loadGoogleAPi = async () => {
        await loader.load()

        if(!MapLibary)
        {
            const { Map } = await window.google.maps.importLibrary("maps");
            MapLibary = Map
        }
        
        const newMap = new MapLibary(document.getElementById("map"), {
            center: { lat: -25.344, lng: 131.031 },
            zoom: 5,
        });

        let newMarker1 = new window.google.maps.Marker({
            position: { lat: -25.344, lng: 131.031 }
        })
        //newMarker.setOptions({map: newMap,position: { lat: -25.344, lng: 131.031 }});

        let newMarker2 = new window.google.maps.Marker({
            position: { lat: -25.444, lng: 131.131 }
        })
        //newMarker.setOptions({map: newMap,position: { lat: -25.244, lng: 131.131 }});

        new MarkerClusterer({ map: newMap, markers: [newMarker1, newMarker2] });

        setMap(newMap)
    }

    useEffect(() => {
        loadGoogleAPi()
    },[])

    return (
        <>
        {!map && <h3>Loading...</h3>}
        <div id="map" style={{display:"inline-block",width:"500px", height:"500px", background:"red"}}></div>
        </>
    )
}

export default MapTest