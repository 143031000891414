import React, { useEffect } from 'react'
import mapboxgl from 'mapbox-gl'
import { Style } from './Style'
import { useUnitDetails } from '../../../app/providers/unit'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY

export const MapBox = () => {
  const {
    store: { unitData },
  } = useUnitDetails()

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/light-v11',
      center: [unitData.address.lng, unitData.address.lat],
      zoom: 16.5,
      pitch: 45,
    })

    function rotateCamera(timestamp) {
      map.rotateTo((timestamp / 100) % 360, { duration: 0 })
      requestAnimationFrame(rotateCamera)
    }

    map.on('load', () => {
      rotateCamera(0)

      const layers = map.getStyle().layers
      for (const layer of layers) {
        if (layer.type === 'symbol' && layer.layout['text-field']) {
          map.removeLayer(layer.id)
        }
      }

      map.addLayer({
        id: '3d-buildings',
        source: 'composite',
        'source-layer': 'building',
        filter: ['==', 'extrude', 'true'],
        type: 'fill-extrusion',
        minzoom: 15,
        paint: {
          'fill-extrusion-color': [
            'case',
            ['==', ['get', 'id'], 'highlightedBuildingId'],
            '#00ff00', // Green color for the highlighted building
            '#aaaaaa', // Grey color for other buildings
          ],
          'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
          'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
          'fill-extrusion-opacity': 0.6,
        },
      })

      const queryBuildingFeature = () => {
        const features = map.queryRenderedFeatures({ layers: ['3d-buildings'] })

        if (features.length > 0) {
          // Find the building feature matching the specified latitude and longitude
          const buildingFeature = features.find((feature) => {
            const polygon = feature.geometry.coordinates
            const point = [unitData.address.lng, unitData.address.lat]
            return mapboxgl.PointInPolygon(point, polygon)
          })

          if (buildingFeature) {
            const featureId = buildingFeature.properties.id
            map.setPaintProperty('3d-buildings', 'highlightedBuildingId', featureId)
          }
        }
      }

      // Call the queryBuildingFeature function after the map has loaded
      queryBuildingFeature()
    })

    return () => {
      map.remove()
    }
  }, [])

  return (
    <Style style={{ position: 'relative', height: '100vh' }}>
      <div id='map' style={{ position: 'absolute', top: 0, bottom: 0, width: '100%' }} />
    </Style>
  )
}
