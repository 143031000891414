import { cloneElement, useContext, useEffect, useState } from "react"
import { DateRangeContext } from "./index.js"

export const StartDate = ({children}) => {

    const context = useContext(DateRangeContext)
    const [startDateProps, setStartDateProps] = useState({isFocused: context.isStartDateFocused, date: context.startDate})

    useEffect(() => {
        context.setStartDateProps = setStartDateProps
    }, [])

    return (
        <>{cloneElement(children, {applyFocus: () => context.onStartDateFocus(), ...startDateProps})}</>
    )
}