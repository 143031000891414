import styled from 'styled-components'
import { Page } from '../../Home/Style'
import { ImageSlider } from '../../../components/base/gui/controls/ImageSlider'

const StyledSliderDemo = styled.div`
    width: 100%;
    height: calc(100vh - 7.3rem);
    display: flex;
    justify-content: center;
    align-items: center;

    .sliderContainer {
        display: inline-block;
        width: 250px;
        height: 200px;
        background: gray;
    }
`
const allImages = [
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/b16fac3e-041f-455d-aa46-4e9dd2bd8887aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/97aaafb3-94d1-456b-8df9-216559ca10f5aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/47305426-ea50-4929-9b13-5048dd1cc40faki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/59bbf1a2-8970-4a44-b531-291c5e432af4aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/a1eeba02-959c-4fb6-8245-fa3b063bdcabaki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/c300da69-5059-4ae1-b830-2bce507c8c33aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/c4f44fcb-0604-4c8d-b886-7e4a4a4fb349aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/6dd63670-6a32-498a-8e96-66b43951e3a4aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/bf9df13c-2bf8-447a-ba00-051c678a5bdbaki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/8f15237b-b828-4b83-82e5-a3b2fd8718c2aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/3ae6e6a1-28d6-4018-aee3-27505c7c5285aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/2d3c1b22-97cb-44fb-ad7e-193b9d05eaccaki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/607d9305-693f-4980-9c48-0aa5a7f47e7aaki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/cc70aa9b-21e4-4704-ab23-67885eef1f87aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/1e768968-3b19-4229-9bdd-efbe92e03622aki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/380a43e2-1553-4de9-bac7-e8aec316c36caki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/a6187300-4a06-4581-8e5c-b3456acf619faki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/a95a4c22-728c-482b-8d8a-332dc2722a9daki-policyxx-large.jpg",
    "//image.myvr.com/e0445af696c0570c/f40b20a5e1fa8e56/fd349582-8ca2-45c1-91ef-96df9c746ecfaki-policyxx-large.jpg"
]

export const SliderDemo = () => {
    return (
        <Page>
            <StyledSliderDemo>
                <div className="sliderContainer">
                    <ImageSlider imageUrlList={allImages}/>
                </div>
            </StyledSliderDemo>
        </Page>
    )
}