import styled from 'styled-components'

import { TextEmphasis } from '../../../components/elements/TextEmphasis'
import { conciergeIcon, parkingIcon, noIcon, gymIcon, poolIcon, deskIcon } from '../../../app/media/icons'
import { Icon, NewIcon } from '../../../components/elements/Icon'
import { Box, Container, Label } from './Style'
import { useUnitDetails } from '../../../app/providers/unit'
import { compileAmenities } from './compileAmenities'

const MyNewIcon = styled(NewIcon)`
  cursor: default;
`

export const Amenities = () => {
  const { unitData } = useUnitDetails('unitData')

  const { parkingText, hasGym, poolText, hasDesk } = compileAmenities(unitData)
  const { bedrooms, bathrooms } = unitData

  return (
    <Container justify='space-evenly' height='25vh'>
      <Box>
        <TextEmphasis>{bedrooms}</TextEmphasis>
        <Label>Bedroom{bedrooms !== 1 && 's'}</Label>
      </Box>
      <Box>
        <TextEmphasis>{bathrooms}</TextEmphasis>
        <Label>Bathroom{bathrooms !== 1 && 's'}</Label>
      </Box>
      <Box>
        {parkingText && (
          <>
            <MyNewIcon>{parkingIcon}</MyNewIcon>
            {parkingText}
          </>
        )}

        {!parkingText && (
          <>
            <MyNewIcon>{noIcon}</MyNewIcon>
            <Label>No Parking</Label>
          </>
        )}
      </Box>
      <Box>
        {hasGym && (
          <>
            <MyNewIcon>{gymIcon}</MyNewIcon>
            <Label>Gym</Label>
          </>
        )}
        {!hasGym && (
          <>
            <MyNewIcon>{noIcon}</MyNewIcon>
            <Label>No Gym</Label>
          </>
        )}
      </Box>
      <Box>
        {poolText && (
          <>
            <MyNewIcon>{poolIcon}</MyNewIcon>
            {poolText}
          </>
        )}

        {!poolText && (
          <>
            <MyNewIcon>{noIcon}</MyNewIcon>
            <Label>No Pool</Label>
          </>
        )}
      </Box>
      <Box>
        {hasDesk && (
          <>
            <MyNewIcon>{deskIcon}</MyNewIcon>
            <Label>Desk</Label>
          </>
        )}
        {!hasDesk && (
          <>
            <MyNewIcon>{noIcon}</MyNewIcon>
            <Label>No Desk</Label>
          </>
        )}
      </Box>
    </Container>
  )
}
