import { useState, useContext, useEffect } from 'react'
import styled from 'styled-components/macro'
import { PhoneStyle } from './PhoneStyle'
import { FormLabel, StyledInputWrapper } from '../../../../../features/ContactForm/style'
//import { FieldWrapper, ValidationContext } from '../../../components/base/gui/controls/Form'
import { FieldWrapper, ValidationContext } from '../../../../../components/base/gui/controls/Form'
import Joi from 'joi'
import PhoneInputControl from 'react-phone-input-2'
import { useValue } from '../../../../../components/hooks/useValue'
import { LabelStyle } from '../../../../../components/inputs/Form/Style.js'
//import 'react-phone-number-input/style.css'
//import PhoneInputControl from 'react-phone-number-input'

const StyledFormInput = styled.div`

  width: calc(100% - 24px) !important;
  padding: 12px;
  border-radius: 5px;
  border: solid 1px rgb(230, 230, 230);
  margin-bottom: 16px;
  background: rgb(255, 255, 255);
  transition: all 0.3s ease-in-out;
`

export const StyledField = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  .error {
    color: red;
    position: absolute;
    width: 100%;
    top: calc(100% - 1.6rem);
    margin: 0;
    font-size: 12px;
  }
`

export const PhoneInput = ({ label = 'Text', name = 'text', onChange = null }) => {
  let idx = 0
  //let labelHtml = label.split('').map((letter) => <span style={{ transitionDelay: `${idx++ * 50}ms` }}>{letter}</span>)

  let currentPhone = ''
  const myFormik = useContext(ValidationContext)
  const fieldError = useValue('')

  const onPhoneChange = (newVal) => {
    currentPhone = newVal
    myFormik.onValueChanged('phone', newVal)
  }

  const onBlur = () => {
    myFormik.onBlur('phone', currentPhone)
  }

  useEffect(() => {
    let joiValidation = Joi.string().required().messages({ 'string.empty': 'this field is required' })

    let setErrorFunction = (val) => fieldError.set(val)

    myFormik.addValue('phone', joiValidation, '', setErrorFunction)

    return () => myFormik.removeValue('phone')
  }, [])

  return (
    <FormLabel
      fontSize='1.2rem'
      margin='1.5rem'
      color='grey'
      myStyle="stripe"
      // initial={{ opacity: 0, y: 50 }}
      // whileInView={{
      //   opacity: 1,
      //   y: 0,
      // }}
      // transition={{
      //   duration: 0.7,
      //   delay: 0.6,
      // }}
    >
      <LabelStyle>{label}</LabelStyle>
      <PhoneStyle myStyle="stripe">
        <StyledField>
          {/*<FieldWrapper name={name} onChange={(newVal) => phone.set(newVal)} validation={Joi.string().required().messages({ 'string.empty': 'this field is required' })} StyleWrapper={StyledInputWrapper}>*/}
          <PhoneInputControl
            placeholder='Enter phone number'
            value={currentPhone}
            onChange={onPhoneChange}
            onBlur={onBlur}
          />

          <fieldError.Value
            render={(fieldError) => <>{fieldError !== '' && <p className='error'>{fieldError}</p>}</>}
          />

          {/*</FieldWrapper>*/}
        </StyledField>
      </PhoneStyle>
    </FormLabel>
  )
}
