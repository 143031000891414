import { ListingSearchProvider as Provider } from '../../app/providers/unitSearch'
import { Page } from './Style'
import { DisplayListings } from '../../features/DisplayListings/index-new.js'
import { SearchBarBooking } from '../../features/SearchBarBooking/index-new.js'

import { useState } from 'react'
import styled from 'styled-components'
import { Map } from '../../features/Map/searchResult'
import { useSearchResults } from '../../components/hooks/useSearchResults'
import { useValue } from '../../components/hooks/useValue'
import { MapView } from './MapView'

export const Home = () => {

  return (
    <Provider>
      <Page>
        <SearchBarBooking />
        <DisplayListings />
      </Page>
    </Provider>
  )
}
