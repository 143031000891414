import styled from 'styled-components'

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: ${(props) => props.align};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin || '2rem auto'};
  padding: ${(props) => props.padding};
  margin-left: ${(props) => props.marginLeft};
  background: ${(props) => props.bg};
`
