import styled from 'styled-components'
import { Box as BoxRef } from '../../../components/layouts/Box'
import { Row } from '../../../components/layouts/Row'

export const Box = styled(BoxRef)`
  margin: 0 2rem;

  /* @media screen and (max-width: 768px) {
    font-size: 1rem;
  } */
`

export const Label = styled.label`
  font-size: 1.5rem;
  color: grey;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem;

    ${Box} &:nth-child(3) {
      font-size: 0.8rem !important;
    }
  }
`

export const Container = styled(Row)`
  padding-top: 10vh;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem 0rem;
    height: auto;
    padding-bottom: 10vh;
  }
`

export const SmallText = styled.span`
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`
