import styled from 'styled-components'

export const Wrapper = styled.div`
  color: ${({ theme, color }) => color || '#63a1a0'};

  > svg {
    width: ${({ size }) => size || '2rem'};
    height: ${({ size }) => size || '2rem'};
  }
  cursor: ${(props) => props.cursor};
`

export const Icon = ({ children, size, cursor = 'pointer' }) => {
  return (
    <Wrapper size={size} cursor={cursor}>
      {children}
    </Wrapper>
  )
}

export const NewIcon = styled.div`
  color: ${({ theme, color }) => color || '#63a1a0'};

  > svg {
    width: ${({ size }) => size || '2rem'};
    height: ${({ size }) => size || '2rem'};
  }
  cursor: 'pointer';
`
