import styled from 'styled-components'

export const StyledTextCarousel = styled.div`
  #carousel {
    overflow: hidden;
    width: 100%;
    height: 350px;
    max-height: 50vh;
    margin-top: 2rem;
  }

  .p-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease-in-out;
  }

  #button_0:checked ~ #carousel .p-wrapper {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  #button_1:checked ~ #carousel .p-wrapper {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  #button_2:checked ~ #carousel .p-wrapper {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  #button_3:checked ~ #carousel .p-wrapper {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  [class^='panel_'] {
    overflow-y: scroll;
    position: relative;
    width: inherit;
    height: inherit;
    color: grey;
    font-size: 1.4rem;
    line-height: 2.3rem;
    padding-right: 8px;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 4px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #ccc;
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }

  [class^='label_'] {
    display: inline-block;
    /* margin: auto 1rem; */
    font-weight: 300;
    color: ${(props) => props.theme.colors.primary2};
    color: lightgrey;
    font-size: 1.6rem;
    cursor: pointer;
    padding-bottom: 6px;
    transition: all 0.3s ease;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 50px 100px -20px, rgba(0, 0, 0, 0.1) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.05) 0px -2px 4px 0px;
    padding: 0.6rem;
    border-radius: 1rem;
    transition: all 0.3s ease;
  }

  [class^='label_']:hover {
    transform: translateY(-6px);
    color: ${(props) => props.theme.colors.primary};
  }

  #button_0:checked ~ #navigation .label_0,
  #button_1:checked ~ #navigation .label_1,
  #button_2:checked ~ #navigation .label_2,
  #button_3:checked ~ #navigation .label_3 {
    cursor: default;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 700;
    box-shadow: rgba(50, 50, 93, 0.05) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.25) 0px -2px 4px 0px inset;
    transform: translateY(-0.5rem);
    transition: all 0.3s ease;
  }
`

export const Card2 = styled.div`
  padding: 1rem;
  border-radius: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`
