import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import { Row as RowRef } from '../../components/layouts/Row'
import { Col as ColRef } from '../../components/layouts/Col'

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  /* padding: 2rem; */
  /* padding-top: 10rem; */

  @media (max-width: 768px) {
    padding: 1rem 0;
    /* padding-top: 10rem; */
  }
`

export const Section = styled(animated.section)`
  width: 100%;
  /* max-width: 1200px; */
  /* margin-bottom: 3rem; */
  height: ${({ height }) => (height ? height : '100vh')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: auto;
    /* max-height: 90vh; */
    /* padding-top: 10rem; */
  }
`

export const SectionBg = styled(animated.section)`
  width: 100%;
  background: url('../public/condo-living-room.jpg');
  background-size: cover;
  /* max-width: 1200px; */
  /* margin-bottom: 3rem; */
  height: ${({ height }) => (height ? height : '100vh')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: auto;
    /* max-height: 90vh; */
    /* padding-top: 10rem; */
  }
`

export const Section2 = styled(Section)`
  width: 100%;

  margin-bottom: 3rem;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    height: 100%;
    margin: 2rem 0;
  }
`

export const TextSection = styled(animated.div)`
  width: 100%;
  /* margin-bottom: 3rem; */
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.8rem;
  letter-spacing: -0.65px;

  @media (max-width: 768px) {
    width: 85%;
    text-align: center;
    font-size: 2.6rem;
  }
`

export const SectionTitle = styled.h2`
  font-size: 3rem;
  font-weight: 700;

  margin-bottom: 2rem;
  color: #333;
  padding: 1rem 0;
`

export const SectionContent = styled.p`
  font-size: 1.9rem;

  color: #666;
  /* color: #fafafa; */
  /* width: 60rem; */
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 1rem 0;
  padding-right: 2rem;
  line-height: 1.6;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Image = styled(animated.img)`
  width: ${({ width }) => (width ? width : '115%')};
  border-radius: 2rem;
  transition: transform 0.14s ease-in-out;
  z-index: 0;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 100%;
    /* max-width: 55rem; */
    position: absolute;
    right: 0.1rem;
    top: 3rem;
    transform: scale(1.1) !important;
  }
`

export const BackgroundImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 90%; */
  width: 100%;
  height: 75%;
  background: url('../../../public/condo-living-room.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 3rem;
`

export const LargeCard = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => width || '70%'};
  /* height: 47vh; */
  border-radius: 1.8rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(50, 50, 93, 0.05) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.25) 0px -2px 4px 0px inset;

  @media (max-width: 768px) {
    width: 96%;
    /* padding: 2rem 0; */
    padding: 1.2rem 0;
  }
`

export const TextContainer = styled.div`
  width: 50%;
  max-width: 50rem;
  margin: auto 2rem;
  margin-left: 6rem;
  text-align: left;

  @media (max-width: 768px) {
    /* width: 90%; */
    margin-left: 3rem;
    /* max-width: 25rem; */
    z-index: 2;
    width: 100%;
  }
`

export const TextContainer2 = styled(TextContainer)`
  width: 100%;
  max-width: 100%;
  /* padding: 3rem 1.5rem; */
`

export const VerticalText = styled.span`
  display: inline-block;
  position: absolute;
  /* top: 10rem; */
  transform: rotate(-90deg) translateY(-50%);
  top: 0;
  top: -50%;
  left: -20%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: -0.5px;
  height: fit-content;
  margin: auto 0;
  color: ${({ theme }) => theme.colors.primary};
  color: #ccc;
  transition: transform 0.3s ease-in-out;
`

export const Img = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`

export const Img2 = styled.img`
  width: 100%;
  max-width: 40rem;
  height: 100%;
  object-fit: cover;
  transform: scale(1.04) translateY(-0.3rem);

  @media (max-width: 1150px) {
    display: none;
    width: 100%;
    max-width: 25rem;
    position: absolute;
    right: 0.1rem;
    z-index: 0;
  }
`

export const Row = styled(RowRef)`
  margin: 2rem 0;
  width: 100%;
  max-width: 60rem;
  transform: translateX(5rem);
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    transform: translateX(0rem);
    align-items: center;
    /* margin-top: 2rem; */
    padding-top: 5rem;
  }
`

export const Col = styled(ColRef)`
  width: 70%;
  height: 70vh;

  @media (max-width: 768px) {
    height: 100%;
    width: 80%;
  }
`
