import { Page } from "../../components/layouts"
import { StyledPrivacyPolicy } from "./Style"

export const PrivacyPolicy = () => {
    return (
        <Page>
            <StyledPrivacyPolicy>
            <div class="root-0-2-277 privacyWrapper-0-2-278 privacyWrapper-d0-0-2-279">
                <div class="TextField-h1-0-2-245 text-field-wrapper"><h1><span>Privacy &amp; Cookie use policy</span></h1></div><div class="text-field-wrapper">
                <p><span>Welcome to our booking platform (the “Platform” or “We”). The
                    Platform allows guests or tenants to perform online booking or reservations of properties and rooms (the
                    "Service").
                </span></p>
                <p>
                    <span>This privacy policy (the “Policy”) outlines our privacy practices with
                        respect to the Service, including the ways your personal information and data are collected, stored, used
                        and shared. Providing us with your personal information is a choice you make. We appreciate that and
                        thank you for making this choice. You are not legally obligated to provide us with this information,
                        but We do need it to allow you to use the Service.
                    </span>
                </p>
                <p>
                    <span>We act as data controllers and are responsible for the collection and use of any data
                        that you submit or provide through the Service.
                    </span>
                </p>
                <p>
                    <span>In addition to this privacy statement, we may have additional policies or codes of
                        conduct which will apply in relation to your use of the Service. If you have any questions about your use
                        of the Service, please contact us.
                    </span>
                </p>

                <h2><span>What kind of personal information is collected?</span></h2>

                <p>
                    <span>When you submit an inquiry, make a customer service request or register an account, you
                        provide contact information such as your name, address, mobile phone number and email address.
                    </span>
                </p>
                <p>
                    <span>We may collect your credit card or debit card information when you pay for the Service.
                        This information is collected on our behalf by a payment processor. We may use cookies and similar
                        tracking technologies to collect information on our website, and via email. Additionally, We may collect
                        your IP address from your internet service provider and may collect your bank details upon property
                        owners' need.
                    </span>
                </p>

                <h2><span>Use &amp; Disclosure of collected information</span></h2>

                <p>
                    <span>We may share the information you shared with us with third party service providers in
                        order to provide and support the Service:
                    </span>
                </p>
                <ul>
                    <li>
                        <span>communicating with you regarding the use of the Service and respond to your requests;</span>
                    </li>
                    <li>
                        <span>enhancing the security and safety of the Service, such as by
                        investigating suspicious activity or violations of applicable terms or policies;
                        </span>
                    </li>
                    <li>
                        <span>developing new tools, products or services within the Service;</span>
                    </li>
                    <li>
                        <span>associating activity on the Service across different devices operated
                        by the same individual to improve the overall operation of the Service;
                        </span>
                    </li>
                    <li><span>to identify and fix bugs that may be present;</span></li>
                    <li>
                        <span>conducting data and system analytics, including research to improve the
                        Service.</span>
                    </li>
                    <li>
                        <span>comply with any applicable law and assisting law enforcement agencies
                        and competent authorities, if We believe it is necessary or justified.</span>
                    </li>
                </ul>

                <h2><span>Accessing, modifying, exporting or deleting your information</span></h2>

                <p><span>You have the following rights with respect to your personal data:</span></p>
                <ul>
                    <li><span>The right to request a copy of your personal data.</span></li>
                    <li>
                        <span>The right to request to correct your personal data if inaccurate or out
                        of date.</span>
                    </li>
                    <li>
                        <span>The right to request that your personal data be deleted when it is no
                        longer necessary to retain such data.</span>
                    </li>
                    <li>
                        <span>The right to withdraw any consent to personal data processing at any
                        time. For example, your consent to receive e-marketing communications.</span>
                    </li>
                    <li>
                        <span>If you want to withdraw your consent to e-marketing, please make use of
                        the link to manage your subscriptions included in our communication. Please note that you may still
                        receive system messages and administrative communications, such as order confirmation, system messages
                        and notifications about your account activities.</span>
                    </li>
                    <li>
                        <span>The right to request to provide you with your personal data and, if
                        possible, to pass on this information directly (in a portable format) to another data controller when
                        the processing is based on consent or contract.</span>
                    </li>
                    <li>
                        <span>The right to request a restriction on further data processing, in case
                        there is a dispute in relation to the accuracy or processing of your personal data.</span>
                    </li>
                    <li>
                        <span>The right to object to the processing of personal data, in case data
                        processing has been based on legitimate interest and/or direct marketing.</span>
                    </li>
                </ul>
                <p>
                    <span>If you are not able to do so using the tools provided in the Service, you should
                    contact us directly for access, modification, export or deletion of your personal information.</span>
                </p>

                <h2><span>GDPR Notice</span></h2>

                <p>
                    <span>The following disclosure is made pursuant to the European General Data Protection
                    Regulation (GDPR):</span>
                </p>
                <p>
                    <span>As a data controller of our clients’ personal data, we are committed to
                    protecting and respecting your privacy in compliance with EU- General Data Protection Regulation
                    (GDPR) 2016/679, dated April 27th 2016.</span>
                </p>

                <h2><span>Our legal basis for collecting personal data</span></h2>

                <p><span>Collecting personal data based on consents:</span></p>
                <p>
                    <span>The collection of personal data based on consent from the data subject will be done by
                    using consent forms that will store documentation related to the consent given by the individual.
                    </span>
                </p>
                <p>
                    <span>We use personal information for fulfilling our obligations related to contracts and
                    agreements with customers, partners and suppliers.</span>
                </p>
                <p>
                    <span>Collecting personal data based on legitimate interest:</span>
                </p>
                <p>
                    <span>We may use personal data if it is considered to be of legitimate interest, and if the
                    privacy interests of the data subject do not override this interest. Normally, to establish the legal
                    basis for data collection, an assessment has been made during which a mutual interest has been identified.
                    </span>
                </p>

                <h2><span>Data Retention</span></h2>

                <p>
                    <span>We retain personally identifiable information for as long as we deem necessary for the
                    purposes set forth above. We may delete information from our systems, without notice to you,
                    once we deem it is no longer necessary for the purposes set forth above.
                    </span>
                </p>

                <h2><span>CCPA Notice</span></h2>

                <p>
                    <span>You may exercise certain consumer rights under the California
                    Consumer Privacy Act (CCPA). Your CCPA rights are described below.</span>
                </p>

                <h2><span>Right to Know</span></h2>

                <p>
                    <span>You have the right to request information regarding the
                        categories and specific pieces of personal information we have collected about you, as well as the sources
                        of that information, the business purpose for collecting it, and what types of third parties we share or
                        sell it with.
                    </span>
                </p>

                <h2><span>Right to Deletion</span></h2>

                <p>
                    <span>You have the right to request that we delete any of your
                        personal information. We will delete any personal information that is not critical to the normal business
                        operation from our records and direct all of our service providers to do the same.
                    </span>
                </p>
                <p>
                    <span>We consider data to be critical to our business operation if
                    they are used to:</span>
                </p>
                <ul>
                    <li>
                        <span>Provide goods or services to you</span>
                    </li>
                    <li>
                        <span>Detect and resolve issues related to security or functionality</span>
                    </li>
                    <li>
                        <span>Comply with legal obligations</span>
                    </li>
                </ul>

                <h2><span>Right to Non-Discrimination</span></h2>

                <p><span>If you exercise your consumer rights:</span></p>
                <ul>
                    <li>
                        <span>We will not deny goods or services to you</span>
                    </li>
                    <li>
                        <span>We will not charge different prices or rates for goods or services, including through the use of
                        discounts or other benefits or imposing penalties
                        </span>
                    </li>
                    <li>
                        <span>We will not provide a different level or quality of goods or services to you</span>
                    </li>
                </ul>
                <h2><span>Do Not Sell My Personal Information</span></h2>
                <p>
                    <span>We do not sell any information that identifies you, such as your name or contact
                        information. However, we do allow Ad Networks such as Facebook and Google to collect your
                        electronic activity while on our website
                    </span>
                </p>
                <h2><span>Children's privacy</span></h2>
                <p>
                    <span>This service is for people 16 years of age or older. We do not knowingly or
                    intentionally collect information about children who are under 16 years of age.</span>
                </p>
                <h2><span>Cookies</span></h2>
                <p>
                    <span>We may use cookies on the Service. Cookies are packets of information sent by our
                    servers to your web browser and then sent back by the browser each time it accesses our servers.
                    The cookies may contain a variety of information, such as the web pages you have accessed, session
                    durations and IP addresses. Cookies are used for various purposes, such as to collect statistical
                    information about your use of the Service and save you the need to re-login with your username and
                    password when you use the Service.
                    </span>
                </p>
                <p>
                    <span>This information is used to make websites work more efficiently, as well as to provide
                    business and marketing information, and to gather such personal data as browser type and operating system,
                    referring page, path through site, domain of ISP, etc. for the purposes of understanding how visitors use
                    a website. Cookies and similar technologies help us tailor our website to your personal needs, as well as
                    to detect and prevent security threats and abuse.
                    </span>
                </p>
                <p>
                    <span>If you wish to block cookies, you may do so through your browser’s settings or
                        using the option in the service website for blocking marketing cookies. You can delete cookies that are
                        already on your computer and you can set your browser to prevent them from being placed going forward.
                        Please refer to the browser's help menu for further information. However, please bear in mind that
                        disabling cookies may adversely affect your user experience on the Service. You can delete advertising
                        cookies in your browser by using this form:
                    </span>
                    <span>
                        <a href="https://www.google.com/url?q=https://optout.aboutads.info/&amp;sa=D&amp;source=editors&amp;
                        ust=1678363299737561&amp;usg=AOvVaw1fkZYdUiRtrIeBkZS-nd9_">https://optout.aboutads.info/</a></span>
                        <span>.</span>
                </p>
                <h2><span>Changes to the privacy policy</span></h2>
                <p>
                    <span>We may change this Policy from time to time. We will provide you notice of such changes
                    through the Services' interface. Your continued use of the Service constitutes your consent to the
                    amended Policy. If you do not consent to the amended Policy, We may terminate the Terms and your account
                    on the Service and block your access to, and use of, the Service, upon the elapse of 30 days after you
                    decline to accept the revised Policy.
                    </span>
                </p>
                <p>
                    <span>In the case of legal requirement or necessity, we may also introduce immediate changes
                    to this Policy. The latest version of the Terms and its effective date will always be accessible on the
                    Service.</span>
                </p>
                <h2><span>Contact Us</span></h2>
                <p>
                    <span>
                        If you have any questions about this Privacy Policy, please contact us - <a target="_blank" href="mailto:info@torontoboutiqueapartments.com">info@torontoboutiqueapartments.com</a>
                    </span>
                </p>
                </div>
            </div>
            </StyledPrivacyPolicy>
        </Page>
    )
}