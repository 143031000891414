import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

/* primary font */

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');



*{
  box-sizing:border-box;
  -webkit-box-sizing: border-box;
}

  html,
  body{
    scroll-behaviour: smooth;
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    /* line-height: 1.5; */
    margin: 0;
    padding: 0;
    font-family: inherit;

    /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  }

/* 
  h1,h2,h3,h4,h5,h6{
    font-family: 'Playfair Display', Helvetica, Arial, sans-serif;
  }
   */

  body {
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6em;
  }

  body.lock-scroll {
    @media screen and (max-width: 768px) {
      overflow-y: hidden;
    }
  }


/* // style resets */

  input{
    font-family:inherit;
  }

  button{
    border:0;
    background:transparent;
  }

  a{
    text-decoration:none;
    color: unset;
    margin:0;
  }


 
`

export default GlobalStyle
