import { ErrorWrapper } from '../../../../components/base/gui/controls/Form'
import { EmailField, Form, SubmitButton, TextAreaField, TextField } from '../../../../components/inputs/Form'
import { CheckboxField } from '../../../../components/inputs/Form/CheckboxField'
import { Col } from '../../../../components/layouts/Col'
import { GridRow } from '../../../../components/layouts/GridRow'
//import { PhoneInput } from '../../../../features/ContactForm/PhoneInput'
import Joi from 'joi'
import { Span } from '../Style'
import { PhoneInput } from './PhoneInput'

export const InquiryForm = ({ quoteId, logic, listingId }) => {
  const onSubmit = async ({ values, callback }) => {
    try {
      await logic.inquireUnit({ formValues: values, listingId})
    } catch (ex) {
      console.log(ex)
      callback(ex.message)
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Col>
        <ErrorWrapper Element={({ error }) => <p className='callbackError'>{error}</p>} />
        <GridRow col={2}>
          <TextField label='First Name' placeholder='First Name' name='firstName' />
          <TextField label='Last Name' placeholder='Last Name' name='lastName' />
        </GridRow>
        <EmailField />

        <PhoneInput name='phone' label='Phone Number' />
        <TextAreaField
          label='Inquiry'
          placeholder='Your Inquiry'
          name='inquiry'
          style={{ height: '10vh', marginBottom: '25vh' }}
        />
        <Col padding='0 1rem 1rem 1rem' margin='0'>
          <CheckboxField
            name='tos'
            validation={Joi.boolean()
              .invalid(false)
              .required()
              .messages({ 'any.invalid': 'You must accept these terms to continue.' })}
            label={
              <Span>
                I have read and accept the{' '}
                <a target='_blank' href='/privacy-policy'>
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a target='_blank' href='/terms'>
                  Toronto Boutique Apartments.com Terms and Conditions
                </a>
              </Span>
            }
          />
        </Col>
        <br />
        <SubmitButton>Send Inquiry</SubmitButton>
      </Col>
    </Form>
  )
}
