import { H3 as H3r } from '../../components/elements/H3'
import { TextEmphasis as TextEmphasisRef } from '../../components/elements/TextEmphasis'
import styled from 'styled-components/macro'
import { Map as MapRef } from '../Map/searchResult'

export const Map = styled(MapRef)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const H3 = styled(H3r)`
  font-family: 'Montserrat';
  color: unset;
  letter-spacing: -0.25px;
  font-weight: bold;
  font-size: 1.7rem;
`

export const Container = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 32rem);

  @media screen and (max-width: 768px) {
    height: calc(100vh - ${props => props.searchBarContracted ? 21.5 : 32}rem);
    padding-bottom: 5rem;
  }

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: 1.4rem;
    border-radius: 0.7rem;
    background-clip: padding-box;
    border: 0.5rem solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 0.5rem;
    color: rgba(0, 0, 0, 0.2);
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      color: rgba(0, 0, 0, 0.4);
    }
  }
`

export const TextEmphasis = styled(TextEmphasisRef)`
  font-size: unset;
`

export const Wrapper = styled.div`
  margin-left: 4rem;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin: 0 2rem;
  }
`

export const Layout = styled.div`
  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
`

export const MapToggleButton = styled.button`
  border-radius: 2.5rem 2.5rem;
  position: fixed;
  bottom: 5rem;
  left: 50%;
  background: ${({ theme }) => theme.colors.body};
  color: white;
  width: 100%;
  max-width: max-content;
  padding: 1.5rem 2.5rem;
  // height: 4rem;
  z-index: 2;
  transform: translateX(-50%);
  font-weight: 600;
  cursor: pointer;
  
  ${props => props.searchView ? 'display: none;' : ''}

  @media screen and (max-width: 768px) {
    bottom: 4rem;
    ${props => props.searchView ? 'display: inline-block;' : ''}
  }
`

export const BottomMargin = styled.div`

  margin-bottom: 6rem;

  @media screen and (max-width: 768px) {
    margin-bottom: 5rem;
  }
` 
