import { ErrorWrapper } from "../../../components/base/gui/controls/Form"
import { H3 } from "../../../components/elements/H3"
import { useValue } from "../../../components/hooks/useValue"
import { EmailField, Form, SubmitButton, TextField } from "../../../components/inputs/Form"
import { CheckboxField } from "../../../components/inputs/Form/CheckboxField"
//import { sendCustomPageView } from "../../../utils/analytics"
import { sendCustomPageViewGTM } from "../../../utils/analytics/GTM"
import api from "../../../utils/api"
import { InquiryForm } from "./InquiryForm"
import Joi from "joi"

const MakeLogic = ({quoteId}) => {
    let state = {
        emailSent: useValue(null), //will be the email address
        verifiedCode: useValue(null), //will be the code when it was validated
        inquiryId: useValue(null) //will be _id  of inquiry
    }

    const submitEmail = async (email) => {
        await api.post('/user/email-verification/request', {email})
        state.emailSent.set(email)
    }

    const submitValidationCode = async (code) => {
        const {data} = await api.post('/user/email-verification/validate', {email: state.emailSent.get(), code})
        let {valid} = data
        if(valid)
        {
            state.verifiedCode.set(code)
            return true
        }
        else
        {
            return false
        }
    }

    const inquireUnit = async ({formValues, listingId}) => {
        let {data} = await api.post('/units/inquire/'+quoteId,{
            email: formValues.email,
            //validationCode: state.verifiedCode.get(),
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            phone: formValues.phone,
            listingId,
            inquiry: formValues.inquiry
        })

        if(data.inquiryId)
        {
            state.inquiryId.set(data.inquiryId)
            if(data.inquiryId)
            {
                try
                {
                    sendCustomPageViewGTM('/thank-you')
                }
                catch(e){/*do nothing*/}
            }
        }
    }

    return {...state, submitEmail, submitValidationCode, inquireUnit}
}

export const InquireSteps = ({selectedDates, quoteId, listingId}) => {

    const {emailSent,verifiedCode,inquiryId, ...logic} = MakeLogic({selectedDates, quoteId, listingId})

    const onEmailSubmit = async ({values,callback}) => {
        try
        {
            await logic.submitEmail(values.email)
        }
        catch(e)
        {
            console.log(e)
            callback(e.message)
        }
    }

    const onCodeValidationSubmit = async ({values,callback}) => {
        try
        {
            let valid = await logic.submitValidationCode(values.code)
            if(!valid)
            {
                callback("Validation code is invalid.")
            }
        }
        catch(e)
        {
            console.log(e)
            callback(e.message)
        }
    }

    return (
        <div>
            <inquiryId.Value render={inquiryId => <>
                {inquiryId === null &&
                    <InquiryForm quoteId={quoteId} logic={logic} listingId={listingId}/>
                }
                {inquiryId !== null &&
                    <H3>Your inquiry was sent. We will respond to you as soon as we can.</H3>
                }
            </>} />
        </div>
    )
}