/*const initialState = {
  _id: null,
  accountType: null,
  email: null,
  exp: null, //jwt "exp" parameter which is expiration time in unicode,
  loaded: false,
  firstName: null,
  lastName: null,
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'USER_LOADED': //{token, accountType}
    case 'LOGIN_SUCCESS':
    case 'REGISTER_SUCCESS':
      let newState = {
        ...state,
        accountType: payload.accountType || null,
        email: payload.email || null,
        isAuthenticated: true,
        firstName: payload.firstName,
        lastName: payload.lastName,
        loaded: true,
        _id: payload._id || null,
        // username: payload.username || null,
      }

      return newState

    case 'LOGOUT':
      let newLogoutState = { ...state, accountType: null, isAuthenticated: false, loaded: true, _id: null }

      return newLogoutState

    case 'CHANGE_NAME':
      return {
        ...state,
        firstName: payload.firstName || null,
        lastName: payload.lastName || null,
      }
    default:
      return state
  }
}

export default authReducer
*/

const makeAuthReducer = () => {
  const code = "AUTH"
  let dispatch = () => null
  let state = {
    _id: null,
    accountType: null,
    email: null,
    exp: null, //jwt "exp" parameter which is expiration time in unicode,
    loaded: false,
    firstName: null,
    lastName: null
  }

  const redux = (newState = state, {type}) => {
      if(type == code)
      {
          return {...state}
      }

      return newState
  }

  const setDispatch = (func) => {
      dispatch = () => func({"type":code})
  }

  const loadUser = (userData) => {
    state = {
      ...state,
      accountType: userData.accountType || null,
      email: userData.email || null,
      isAuthenticated: true,
      firstName: userData.firstName,
      lastName: userData.lastName,
      loaded: true,
      _id: userData._id || null,
    }
    dispatch()
  }


  const logout = () => {
    state.accountType = null
    state.isAuthenticated = false
    state.loaded = true
    state._id = null
    dispatch()
  }

  return {state, redux, setDispatch, loadUser, logout}
}

export const authReducer = makeAuthReducer()

export default authReducer

