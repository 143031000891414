import styled from 'styled-components/macro'
import { CSSTransition } from 'react-transition-group'
import { Form } from '../../components/inputs/Form'
import { StyledField } from '../../components/base/gui/controls/Form'
import { motion } from 'framer-motion'

export const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  width: 100%;

  @media screen and (max-width: 768px) {
    min-height: unset;
  }
`

export const FormContainer = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  /* background: #f9f9f9; */
  padding: 4rem;
  /* height: 0rem; */
  width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  /* transform: perspective(1000px) rotateX(2deg) rotateY(2deg); */

  input:focus + label span,
  input:valid + label span {
    color: lightblue;
    transform: translateY(-30px);
  }

  @media (min-width: 600px) {
    max-width: 500px;
    margin: auto;
  }

  @media screen and (max-width: 768px) {
    margin: auto;
    padding: 2rem;
    width: 90%;
  }

  &:hover {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.14);
    /* transform: perspective(1000px) rotateX(0deg) rotateY(0deg); */
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*justify-content: center;*/
  width: 100%;
  height: 100%;
  @media (min-width: 600px) {
    /* width: 50%; */
  }
`

// export const FormLabel = styled.label`
//   font-size: 16px;
//   font-weight: 500;
//   margin-bottom: 8px;
//   width: 100%;
//   span {
//     display: inline-block;
//     font-size: 18px;
//     min-width: 5px;
//     transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   }
// `

export const FormLabel = styled(motion.label)`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 8px;
  width: 100%;
  margin: ${({ margin }) => margin || ''};

  span {
    display: inline-block;
    font-size: ${({ fontSize }) => fontSize || '1.5rem'};
    min-width: 5px;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: ${({ color }) => (color ? color : '')};
  }

  ${props => {
    if(props.myStyle === "stripe")
    {
      return `
        .form-control {
          background: white !important;
          box-shadow: unset;
        }
      `
    }
    else
    {
      return `
      .flag-dropdown {
        border: none !important;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1) !important;
      }

      .form-control {
        background: rgba(99, 161, 160, 0.063) !important;
        box-shadow: unset;
      }
      `
    }
  }}

  * :focus {
    outline: none;
  }
`

export const FormSelect = styled.select`
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  margin-bottom: 16px;
  background: #f3f3f3;
  /* box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(50, 50, 93, 0.05) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.25) 0px -2px 4px 0px inset;

  transition: all 0.3s ease-in-out;

  &:focus {
    background: #fff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);

    outline: none;
  }
`

export const FormCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const FormButton = styled(motion.button)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 24px;
  width: 100%;
  box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.2);
  transition: all 200ms ease-in-out;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 15px 25px -5px rgba(0, 0, 0, 0.3);
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  }
`

export const FormGroupTransition = styled(CSSTransition)`
  width: 100%;

  div {
    width: 100%;
  }

  & {
    width: 100%;
  }

  &.form-group-enter {
    opacity: 0;
    transform: translateX(50%);
  }
  &.form-group-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 300ms ease-in-out;
  }
  &.form-group-exit {
    opacity: 1;
  }
  &.form-group-exit-active {
    opacity: 0;
    transform: translateX(-50%);
    transition: all 300ms ease-in-out;
  }
`

export const StyledSelectWrapper = styled(StyledField)`
  width: 100%;

  .error {
    top: calc(100% + 1.6rem);
  }
`

export const StyledInputWrapper = styled(StyledField)`
  width: 100%;
  margin-bottom: 16px;

  .error {
    top: 100%;
  }
`

export const PhoneNumbers = styled.div`
  text-align: center;
  width: 80%;
  margin: auto;

  p {
    line-height: 2;
  }

  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
  }
`
