export const unitLogic = ({pushState, api}) => {

    const state = {
        unitData: {
            title: "",
            descriptions: {}, //{space, neighborhood, houseRules, transit, summary}
            mainPicture: {}, //{large, caption, regular, thumbnail}
            pictures: [], //[{_id, sort, original, large, caption, regular, thumbnail, id}]
            address: {}, //{full, street, city, country, lat, lng, zipcode, state, neighborhood, unit}
            terms: {}, //{minNights, maxNights},
            prices: {}, //{cleaningFee, currency, basePrice, securityDepositFee}
            type: "",
            propertyType: "",
            owners: [], //array of owner IDs
            amenities: [], //array of strings
            amenitiesNotIncluded: [], //array of strings
            roomType: "",
            bathrooms: 0,
            checkInTime: "0:00", //24h clock
            checkOutTime: "0:00", //24h clock
            areaSquareFeet: 0,
            bedrooms: 0,
            guests: 0,
            contactPhone: "",
            bookedDates: []
        },
        listingId: null
    }

    const load = async ({params}) => {
        let startDate = new Date();
        const {data} = await api.get('/units/'+params.listingId+`?startDate=${startDate.getFullYear()}-${(startDate.getMonth()+1).toString().padStart(2,'0')}-${startDate.getDate().toString().padStart(2,'0')}`)
        state.listingId = params.listingId
        state.unitData = data

        let d = new Date()
        let newBookedDates = []
        for(let i=0; i<data.bookedDates.length; i++)
        {
            if(data.bookedDates[i])
            {
                newBookedDates.push(new Date(d))
            }
            d.setDate(d.getDate()+1)
        }

        state.unitData.bookedDates = newBookedDates

        pushState()
    }

    const getQuote = async ({guestsCount = null, checkInDate = null, checkOutDate = null, listingId = null}) => {
        let {data} = await api.post('/units/quote',{guestsCount, checkInDate, checkOutDate, listingId})
        return data
    }

    const getPaymentIntentSecret = async () => {
        let {data} = await api.get('/units/payment-intent/123')
        return data.clientSecret
    }

    const bookUnit = async ({paymentMethod}) => {
        let {data} = await api.post('/units/book/123',paymentMethod)
        return data
    }

    const refundDeposit = async() => {
        let {data} = await api.post('/units/refund-deposit/123')
        return data
    }

    return {state, load, getQuote, getPaymentIntentSecret, bookUnit, refundDeposit}
}