import { useContext, useState, useEffect, cloneElement } from "react"
import styled from "styled-components"
import { ValidationContext } from "."
import Joi from "joi"

export const StyledField = styled.div`
    position: relative;
    display: inline-block;
    width: 100%;

    .input {
        background-color: rgb(255, 255, 255);
        border: solid 1px rgb(230, 230, 230);
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.4px;
        padding: 12px;
    }

    .error {
        color: red;
        position: absolute;
        width: 100%;
        top: 100%;
        margin: 0;
        font-size: 12px;
    }
`

export const FieldWrapper = ({defaultValue = null, validation = null, name=null, onChange=null, setError=null, children, StyleWrapper=StyledField}) => {
    const myFormik = useContext(ValidationContext)
    const [value, setValue] = useState(typeof defaultValue === "undefined" ?  '' : defaultValue);
    const [fieldError, setFieldError] = useState('');

    const inputName = name || 'field'

    const onChildValueChange = (e) => {
        setValue(e.target.value);
        myFormik.onValueChanged(inputName, e.target.value);
        if(onChange)
        {
            onChange(e.target.value)
        }
    };

    const onBlur = () => {
        myFormik.onBlur(inputName, value);
    };

    useEffect(() => {
        let joiValidation = validation || Joi.string().required().messages({ 'string.empty': 'field is required' });

        let setErrorFunction = setError ? setError : setFieldError

        let defaultVal = defaultValue == null ? '' : defaultValue
    
        myFormik.addValue(inputName, joiValidation, defaultVal, setErrorFunction);

        return () => myFormik.removeValue(inputName)
    }, []);

    useEffect(() => {
        if(validation)
        {
          myFormik.changeValidation(inputName, validation)
        }
    },[validation])

    return (
        <StyleWrapper>
            {cloneElement(children, { onChange: onChildValueChange, value, onBlur})}
            {fieldError !== '' && <p className="error">{fieldError}</p>}
        </StyleWrapper>
    )
}