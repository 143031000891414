import styled from 'styled-components'

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  color: grey;
  letter-spacing: 0.05rem;

  &:first-of-type {
    margin-right: 1.5rem;
  }
`
