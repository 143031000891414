import styled from 'styled-components'
import { DateRangePicker } from '../../../modules/react-date-range'

export const StyledDateRangePicker = styled(DateRangePicker)`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* transform: translateY(-2rem); */

  .rdrStartEdge {
    color: #63a1a0 !important;
  }
  .rdrEndEdge {
    color: #63a1a0 !important;
  }
  .rdrDayHovered {
    color: #63a1a0 !important;
  }
  .rdrInRange {
    color: #63a1a090 !important;
  }
  /* 
  .rdrDay .rdrDayNumber {
    color: orange !important;
  } */

  .rdrCalendarWrapper {
    border-radius: 2rem;
  }

  .rdrDefinedRangesWrapper {
    display: none;
  }

  ${(props) => (props.additionalStyle ? props.additionalStyle : '')}
`
