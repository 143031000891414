
import { useSearchResults } from '../../../app/providers/unitSearchProvider'
import { GoogleMaps } from '../../../utils/services/GoogleMaps'
import { MapHolder } from '../Style'


export const Map = ({markers, ...props}) => {

  return (
    <MapHolder {...props}>
      <GoogleMaps markers={markers} {...props} />
    </MapHolder>
  )
}
