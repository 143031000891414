import { UnitProvider } from '../../app/providers/unit'
import { Page } from '../../components/layouts'
import { Amenities } from './Amenities'
import { CustomScroller } from './CustomScroller'
import { Hero } from './Hero'
import { Container } from './Style'
import { UnitAboutSection } from './UnitAboutSection'
import { UnitMainInfo } from './UnitMainInfo'


export const ListingPage = () => {

  return (
    <Page>

      <UnitProvider>
        <Hero />

        <Container>
          <Amenities />
          <UnitMainInfo />
          <UnitAboutSection />
        </Container>
      </UnitProvider>
    </Page>
  )
}

// roomType ("entire home/apt")
// space
// floor (étage)
// areaSquareFeet
//
// bathrooms, bedrooms
// guests

// amenities
// amenities not included ()

//
// houseRules
// terms (minNights, maxNights)
// checkInTime, checkOutTime
// propertyType
//
