import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: end;
  justify-content: start;
  height: 100%;
`

export const CardWrap = styled.ul`
  position: relative;
  width: 62.5%;
  aspect-ratio: 3/2;
`

export const Card = styled(motion.li)`
  position: absolute;
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: 8px;
  transform-origin: top center;
  list-style: none;
  cursor: pointer;
  background-color: gray;
  background-image: url('${(props) => props.image}');
  background-position: center;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  transition: transform 0.2s;
  border-radius: 2% / 3%;

  &:hover {
    ${(props) => {
      if (props.index === 0) {
        return 'transform: scale(1.05) translate(0%, -2%);'
      } else {
        return `transform: translate(0%, -5%);`
      }
    }}
  }
`

export const CardOverlay = styled.div`
  width: 100%;
  height: 100%;
  ${(props) => {
    if (props.index === 0) {
      return ''
    } else if (props.index === 1) {
      return `background-color: ${props.theme.colors.primary}dd;`
    } else {
      return `background-color: ${props.theme.colors.primary2}ee;`
    }
  }}
  border-radius: 2% / 3%;
`

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000ee;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GalleryModal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0;
  background-color: #fefefe;
  z-index: 6;
  display: flex;
  justify-content: center;
  animation: slide-up 1s ease;
  overflow-y: scroll;

  @keyframes slide-up {
    0% {
      top: 100%;
    }
    100% {
      top: 0px;
    }
  }
`

export const CloseButton = styled.div`
  font-size: 2rem;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  margin: 2rem;
  cursor: pointer;

  &:hover {
    font-size: 2.5rem;
    margin: 1.8rem;
  }
`

export const CloseGalleryButton = styled.div`
  font-size: 3rem;
  color: ${(props) => props.theme.colors.primary};
  position: fixed;
  top: 15px;
  left: 5px;
  cursor: pointer;
  z-index: 8;
  width: 5rem;
  height: 5rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.5rem;

  &:hover {
    font-size: 3.5rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
    width: 4rem;
    height: 4rem;
  }
`

export const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  ${(props) => (props.left ? 'left: 10px;' : '')}
  ${(props) => (props.right ? 'right: 10px;' : '')}
  transform: translateY(-50%);
  width: 40px;
  height: 100px;
  border-radius: 7px;
  background: ${(props) => props.theme.colors.primary};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  opacity: 30%;
  cursor: pointer;

  &:hover {
    opacity: 100%;
  }
`

export const FullImage = styled.img`
  max-width: calc(100vw - 6rem);
  max-height: calc(100vh - 13.2rem);
`

export const ViewAllImages = styled.span`
  position: absolute;
  right: 0px;
  bottom: -4rem;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  }
`
