import { Page } from "../../Home/Style"
import styled from 'styled-components'
import {useRef} from 'react'
import { Form } from "../../../components/base/gui/controls/Form"

const MyComponent = ({ className }) => (
    <div className={className}>
      <h1>Hello world3!</h1>
    </div>
  );
  
  const StyledMyComponent = styled(MyComponent)`
      background: blue;
  `;

export const StyleFormTest = () => {
    return (
        <Page>
            <StyledMyComponent />
        </Page>
    )
}