import React from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'
import img1 from '../../../app/media/img/condo-living-room.jpg'
import img2 from '../../../app/media/img/listing-example-tba.jpg'

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  /* height: 100vh; */
`

const TextContainer = styled.div`
  width: 50%;
  padding: 2rem;
`

const TextContent = styled.div`
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`

const ImageContainer = styled.div`
  width: 100%;
  /* height: 400px; */
  overflow: hidden;
  position: relative;
`

const CarouselWrapper = styled(animated.div)`
  display: flex;
  flex-direction: row;
  position: absolute;
`

const CarouselImage = styled(animated.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
`

const images = [img1, img2, img1, img2]

export const HighTechCarousel = () => {
  const [index, setIndex] = React.useState(0)
  const { x } = useSpring({
    x: -index * 100, // Slide the carousel horizontally based on the index
    config: { tension: 300, friction: 20 },
  })

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)
  }

  return (
    <SectionContainer>
      <TextContainer>
        <TextContent>
          {/* Add your text content here */}
          <h2>Title</h2>
          <p>Description</p>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <CarouselWrapper style={{ transform: x.interpolate((val) => `translateX(${val}%)`) }}>
          {images.map((image, i) => (
            <CarouselImage key={i} src={image} alt={`Image ${i + 1}`} />
          ))}
        </CarouselWrapper>
      </ImageContainer>
      <button onClick={handlePrev}>Previous</button>
      <button onClick={handleNext}>Next</button>
    </SectionContainer>
  )
}
