import styled from 'styled-components'

export const StyledPrivacyPolicy = styled.div`
  color: #444;
  padding-top: 10vh;
  width: 65%;
  margin: 0 auto;
  line-height: 2.5;

  h1 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 3.4rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    padding-top: 4vh;

    h1 {
      line-height: 1.13;
      font-size: 2.8rem;
    }
  }
`
