import styled from 'styled-components'

export const PaginationWrapper = styled.div`
  /*display: flex;
  justify-content: right;*/
  margin: auto 0;
  padding-right: 3rem;

  span {
    padding: 0px 7px;
    letter-spacing: 2px;
  }

  @media screen and (max-width: 768px) {
    transform: scale(0.8);
    padding-right: 0rem;
    display: flex;
  }
`

export const PageButton = styled.button`
  background-color: ${({ active, theme }) => (active ? theme.colors.primary : '#ffffff')};
  color: ${({ active, theme }) => (active ? '#ffffff' : theme.colors.primary)};
  /* border: ${({ theme }) => `1px solid ${theme.colors.primary2}`}; */
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  margin: 0 0.25rem;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: ${({ active, theme }) => (active ? theme.colors.primary2 : '#ebebeb')};
    color: ${({ active, theme }) => (active ? '#ffffff' : theme.colors.primary2)};
  }
`
