import styled from 'styled-components'

export const Card = styled.div`
  width: 100%;
`
export const Img = styled.img`
  width: 15rem;
  height: 15rem;
  border-radius: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  object-fit: cover;

  @media only screen and (max-width: 768px) {
    height: 10rem; /* reduce height on smaller screens */
    width: 10rem;
  }
`
export const Title = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0;
  font-family: 'Inter';
  letter-spacing: -0.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
`

export const ReviewStars = ({ stars }) => {
  return (
    <ReviewStarsStyle>
      <i className='fa-solid fa-star'></i> {stars} (26)
    </ReviewStarsStyle>
  )
}

export const ReviewStarsStyle = styled.div`
  font-weight: medium;
  font-size: 1.2rem;
  margin: auto 0;
`
export const ShortDescription = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  letter-spacing: -0.5px;
  color: grey;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const Price = styled.span`
  font-weight: bold;
  margin-right: 4rem;
`
export const TotalPriceLabel = styled.span`
  background: #63a1a0;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0rem 0.8rem;
  border-radius: 0.4rem;
  margin-left: auto;
`

export const ViewUnitLink = styled.a`
  color: #63a1a0;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 600;
  /* padding: 0rem 0.8rem; */
  margin-left: auto;
  border: none;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  color: grey;
  letter-spacing: 0.05rem;
  margin-right: 1.5rem;

  /*&:first-of-type {
    margin-right: 1.5rem;
  }*/
`

export const TextEmphasis = styled.span`
  font-size: ${({ fontSize }) => fontSize || '2rem'};
  color: #63a1a0;
  font-weight: bold;
`
