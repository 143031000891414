import { LabelStyle, TextField as Style } from "./Style"
import { FieldWrapper } from "../../base/gui/controls/Form"
import Joi from "joi"


export const EmailField = ({label="Email", placeholder="Enter email", name="email"}) => {

    return (
        <Style>
            <label>
                <LabelStyle>{label}</LabelStyle>
                <FieldWrapper name={name} validation={Joi.string().email({ tlds: { allow: false } }).required().messages({ 'string.empty': 'Email is required' })}><input className="input" placeholder={placeholder}/></FieldWrapper>
            </label>
        </Style>
    )
}