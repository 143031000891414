// https://color-hex.org/color/08a2e5

export const themeSettings = () => ({
  colors: {
    primary: '#63a1a0', // Breaker Bay (green-teal)
    secondary: '#f2f2f4', // Athens Gray (light grey)
    tertiary: '#27292b', // Graphite Black
    quaternary: '#FFFFFF', // White
    error: "#C42021", //fire engine red

    primary2: '#4c7e7d', // Breaker Bay shade (green-teal)
    primary3: '#3d6564', // Breaker Bay (green-teal)

    body: '#27292b',

    fadedText: '#67666a',
    highlight: '#FFE07D',
    bright: '#F8C7CC',
    underline: '#247BA0',

    danger: '#DA2C38',
    warning: 'yellow',
    success: '#6cc070',
  },

  transitionSpeed: '400ms',
})
