import { useValue } from '../../../../hooks/useValue'
import {DebugDiv, IconContainer, ImageSliderContainer, Item, StyledImageSlider} from './Style'
import {useRef, useEffect} from 'react'

export const ImageSlider2 = ({imageUrlList, ...props}) => {

    const containerRef = useRef()
    const preventClick = useRef(true)
    const leftArrowAvailable = useValue(false)
    const rightArrowAvailable = useValue(imageUrlList.length > 1 ? true : false)

    let dragging = false
    let prevPageX = 0;
    let initialPageY = 0;
    let prevScrollLeft = 0 ;
    let currentImgIndex = useRef(0);

    let secondImageLoaded = false
    let maxIndexBrowsed = 0;
    let imagesLoaded = new Array(imageUrlList.length)


    const loadImages = () => {
        if(currentImgIndex.current > maxIndexBrowsed)
        {
            maxIndexBrowsed = currentImgIndex.current
            let children = containerRef.current.children

            for(let i=currentImgIndex.current; i <= currentImgIndex.current+6; i++)
            {
                if(!imagesLoaded[i])
                {
                    imagesLoaded[i] = true
                    if(children[i])
                    {
                        children[i].style.backgroundImage = `url(${imageUrlList[i]})`
                    }
                }
            }
        }
    }

    const onMouseDown = (e) => {
        dragging = true;

        if(e.touches)
        {
            if(!secondImageLoaded)
            {
                secondImageLoaded = true
                let children = containerRef.current.children
                if(children[1])
                {
                    children[1].style.backgroundImage = `url(${imageUrlList[1]})`
                }
            }

            prevPageX = e.touches[0].clientX;
            initialPageY = e.touches[0].clientY;
        }
        else
        {
            prevPageX = e.pageX;
            initialPageY = e.pageY;
        }
        prevScrollLeft = containerRef.current.scrollLeft
    }

    const onMouseDrag = (e) => {
        if(!dragging) return;

        if(e.touches)
        {
            containerRef.current.scrollLeft = prevScrollLeft - e.touches[0].clientX + prevPageX;
            let distance = Math.abs(e.touches[0].clientY - initialPageY)
        }
        else
        {
            containerRef.current.scrollLeft = prevScrollLeft - e.pageX + prevPageX;
            let distance = Math.abs(e.pageY - initialPageY)
        }
    }

    const onMouseUp = (e) => {
        let currentScroll = containerRef.current.scrollLeft;

        if(dragging)
        {
            if(Math.abs(currentScroll - prevScrollLeft) < 5)
            {
                preventClick.current = false
            }
        }
        else
        {
            return
        }

        dragging = false
        

        let width = containerRef.current.getBoundingClientRect().width;
        let prevIndex = Math.floor(currentScroll/width)
        let nextIndex = Math.ceil(currentScroll/width)

        if(currentImgIndex.current == prevIndex)
        {
            if(currentScroll - prevIndex*width > width*0.15)
            {
                currentImgIndex.current++
            }
        }
        else
        {
            if(nextIndex*width - currentScroll > width*0.15)
            {
                currentImgIndex.current--
            }
        }

        if(currentImgIndex.current < 0)
        {
            currentImgIndex.current = 0
        }
        else if(currentImgIndex.current >= imageUrlList.length)
        {
            currentImgIndex.current = imageUrlList.length - 1
        }

        loadImages()

        containerRef.current.scrollTo({
            left: width*currentImgIndex.current,
            behavior: "smooth",
        });

        setTimeout(() => {
            containerRef.current.scrollTo({
                left: width*currentImgIndex.current,
                behavior: "smooth",
            });
        }, 50)

        if(currentImgIndex.current === 0 && leftArrowAvailable.get())
        {
            leftArrowAvailable.set(false)
        }
        else if(currentImgIndex.current === imageUrlList.length - 1 && rightArrowAvailable.get())
        {
            rightArrowAvailable.set(false)
        }

        if(!leftArrowAvailable.get() && currentImgIndex.current > 0)
        {
            leftArrowAvailable.set(true)
        }
        if(!rightArrowAvailable.get() && currentImgIndex.current+1 < imageUrlList.length)
        {
            rightArrowAvailable.set(true)
        }

    }

    const onNextClick = (e) => {
        
        e.stopPropagation()

        if(currentImgIndex.current + 1 < imageUrlList.length)
        {
            currentImgIndex.current++;

            loadImages()

            containerRef.current.scrollTo({
                left: containerRef.current.getBoundingClientRect().width*currentImgIndex.current,
                behavior: "smooth",
            });

            setTimeout(() => {
                containerRef.current.scrollTo({
                    left: containerRef.current.getBoundingClientRect().width*currentImgIndex.current,
                    behavior: "smooth",
                });
            }, 50)

            if(!leftArrowAvailable.get())
            {
                leftArrowAvailable.set(true)
            }

            if(currentImgIndex.current + 1 >= imageUrlList.length)
            {
                rightArrowAvailable.set(false)
            }
        }
    }

    const onPreviousClick = (e) => {
        e.stopPropagation()

        if(currentImgIndex.current > 0)
        {
            currentImgIndex.current--;
            containerRef.current.scrollTo({
                left: containerRef.current.getBoundingClientRect().width*currentImgIndex.current,
                behavior: "smooth",
            });

            setTimeout(() => {
                containerRef.current.scrollTo({
                    left: containerRef.current.getBoundingClientRect().width*currentImgIndex.current,
                    behavior: "smooth",
                });
            },50)

            if(!rightArrowAvailable.get())
            {
                rightArrowAvailable.set(true)
            }

            if(currentImgIndex.current <= 0)
            {
                leftArrowAvailable.set(false)
            }
        }
    }

    const onMouseEnter = () => {
        if(!secondImageLoaded)
        {
            secondImageLoaded = true
            let children = containerRef.current.children
            if(children[1])
            {
                children[1].style.backgroundImage = `url(${imageUrlList[1]})`
            }
        }
    }

    const onContainerClick = (e) => {
        if(preventClick.current)
        {
            e.stopPropagation()
        }
        else
        {
            preventClick.current = true   
        }
    }

    useEffect(() => {
        let firstImageDiv = containerRef.current.children[0]
        if(firstImageDiv)
        {
            firstImageDiv.style.backgroundImage = `url(${imageUrlList[0]})`
        }

        containerRef.current.addEventListener("mousemove", onMouseDrag)
        containerRef.current.addEventListener("touchmove", onMouseDrag)

        containerRef.current.addEventListener("mousedown", onMouseDown)
        containerRef.current.addEventListener("touchstart", onMouseDown)

        containerRef.current.addEventListener("mouseup", onMouseUp)
        containerRef.current.addEventListener("touchend", onMouseUp)

        containerRef.current.addEventListener("mouseleave", onMouseUp)
        containerRef.current.addEventListener("mouseenter", onMouseEnter)
    }, [])

    return (
        <StyledImageSlider onClick={onContainerClick} {...props}>
            <leftArrowAvailable.Value render={leftArrowAvailable => <>
                {leftArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-left left toggle-visibility" onClick={onPreviousClick}/>
                }
                {!leftArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-left left toggle-visibility half-opacity" onClick={e => e.stopPropagation()}/>
                }
            </>}/>
            <rightArrowAvailable.Value render={rightArrowAvailable => <>
                {rightArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-right right toggle-visibility" onClick={onNextClick}/>
                }
                {!rightArrowAvailable && 
                    <IconContainer className="fa-solid fa-chevron-right right toggle-visibility half-opacity" onClick={e => e.stopPropagation()}/>
                }
            </>}/>
            
            <ImageSliderContainer ref={containerRef}> 
                {imageUrlList.map((_,index) => <Item key={"img_"+index}/>)}
            </ImageSliderContainer>
        </StyledImageSlider>
    )
}