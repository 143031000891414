import styled from 'styled-components'

const dateFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }

const SimpleTextHolder = styled.div`
  display: inline-block;
  padding: 0.2rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin: auto;

  ${(props) =>
    !props.isFocused
      ? ''
      : `
        padding-bottom: 0.1rem;
        border-bottom: solid 0.1rem ${props.theme.colors.primary};
    `}
`

export const DateDisplay = ({ applyFocus, isFocused, date, defaultText = 'Date' }) => {
  return (
    <SimpleTextHolder onClick={applyFocus} isFocused={isFocused}>
      {date ? date.toLocaleDateString('en-US', dateFormatOptions) : defaultText}
    </SimpleTextHolder>
  )
}
