import {Form as FormRef} from './Style'
//import { Form as FormRef } from '../../base/gui/controls/Form'

export * from './NameField.js'
export * from './EmailField.js'
export * from './TextField.js'
export * from './TextAreaField.js'
export * from './SubmitButton.js'
export * from './ErrorBanner.js'

export const Form = ({children, onSubmit, className=''}) => {

    return (
        <FormRef className={className} onSubmit={onSubmit}>
            {children}
        </FormRef>
    )
    
}