import { useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { SideBarNav } from '../SideBarNav'

let drawerWidth = 170

const Layout = styled.div`
  display: flex;
`

const Main = styled.main`
  flex-grow: 1;
  transition: ${(props) => props.theme.transitionSpeed} ease-in-out;
  min-height: 100vh;
  margin-left: 6.5rem;
`

export const AppMainLayout = ({ children, menuUi, setMenuUi, menuTabs, isLoggedIn }) => {
  const auth = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const accountType = useSelector((state) => state.auth.accountType)
  const [open, setOpen] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Layout>
      {/* <SideBarNav setOpen={setOpen} open={false} /> */}
      <Main open={open}>{children}</Main>
    </Layout>
  )
}
