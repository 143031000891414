import styled, { css } from 'styled-components'

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.col}, minmax(0, 1fr));
  ${(props) => props.colTemplate && `grid-template-columns: ${props.colTemplate};`};
  grid-gap: ${({ gridGap }) => gridGap || '2rem'};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  margin-bottom: ${(props) => props.marginBottom};
  width: 100%;
  ${(props) => {
    if(props.mobile)
    {
      return css`
        @media screen and (max-width: ${props => props.mediumScreenWidth ? props.mediumScreenWidth : '1268px'}) {
          grid-template-columns: ${props => props.mediumScreenTemplate ? props.mediumScreenTemplate : '1fr 1fr'};
        }

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      `
    }
    else{return ''}
  }}

  ${(props) =>
    props.mobile &&
    props.reversed &&
    css`
      @media screen and (max-width: 1268px) {
        > * {
          order: 1; /* Reset the order of each grid item */
        }

        > *:nth-child(1) {
          order: 2; /* Move the first item to the end */
        }
      }
    `}
`
