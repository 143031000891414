import { useState } from 'react'
import styled, { keyframes } from 'styled-components'

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 22rem;
`

const TextPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 24rem;
  transition: opacity 0.3s ease-in-out;
  line-height: 3.5rem;
  opacity: ${(props) => (props.currentPage === props.pageNum ? 1 : 0)};
  overflow: hidden;

  @media (max-width: 768px) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6; /* Limit the text to 6 lines */
    line-clamp: 6;
  }
`

const PageDots = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const Dot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? 'black' : 'gray')};
  margin: 0.4rem;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.active ? 'scale(1.2)' : 'none')};
`

const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const PageContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  animation: ${fadeAnimation} 0.3s ease-in-out;
`

export const TextScroller = ({ content }) => {
  const words = content.split(' ')

  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum)
  }

  const totalPages = Math.ceil(words.length / (window.innerWidth >= 769 ? 60 : 40))
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1)

  const getPageContent = (pageNum) => {
    const startIndex = (pageNum - 1) * (window.innerWidth >= 769 ? 60 : 40)
    const endIndex = Math.min(startIndex + (window.innerWidth >= 769 ? 60 : 40), words.length)

    let pageContent = words.slice(startIndex, endIndex).join(' ')

    return pageContent
  }

  const handleSwipe = (event) => {
    const { deltaX } = event.changedTouches[0]
    if (deltaX > 0 && currentPage > 1) {
      handlePageChange(currentPage - 1)
    } else if (deltaX < 0 && currentPage < totalPages) {
      handlePageChange(currentPage + 1)
    }
  }

  return (
    <TextWrapper onTouchStart={handleSwipe} onTouchEnd={handleSwipe}>
      <PageContent>
        <TextPage currentPage={currentPage} pageNum={currentPage}>
          {getPageContent(currentPage)}
        </TextPage>
        {totalPages > 1 && (
          <PageDots>
            {pages.map((pageNum) => (
              <Dot key={pageNum} active={pageNum === currentPage} onClick={() => handlePageChange(pageNum)} />
            ))}
          </PageDots>
        )}
      </PageContent>
    </TextWrapper>
  )
}
