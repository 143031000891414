import styled from 'styled-components'

export const StyledImageSlider = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  ${(props) => {
    let arrowPosition = props.arrowPosition || 'border'
    if (arrowPosition === 'inside') {
      return `
                .left {
                    left: 5px;
                    transform: translate(0%, -50%);
                }

                .right {
                    right: 5px;
                    transform: translate(0%, -50%);
                }
            `
    } else if (arrowPosition === 'border') {
      return `
                .left {
                    left: 0%;
                    transform: translate(-50%, -50%);
                }

                .right {
                    right: 0%;
                    transform: translate(50%, -50%);
                }
            `
    }
  }}

  & > .toggle-visibility {
    visibility: hidden;

    @media screen and (max-width: 768px) {
      visibility: visible;
    }
  }

  &:hover > .toggle-visibility {
    visibility: visible;
  }

  .half-opacity {
    opacity: 50%;
    cursor: default;
  }
`

export const ImageSliderContainer = styled.div`
  background: red;
  height: 100%;
  white-space: nowrap;
  border-radius: 1.25rem;
  width: 100%;
  overflow: hidden;
`

export const Item = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const IconContainer = styled.i`
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  position: absolute;
  top: 50%;
  background-color: #fafafa;
  border-radius: 50%;
  font-size: 2rem;
  cursor: pointer;
`

export const DebugDiv = styled.div`
  background-color: #ffffffdd;
  color: black;
  position: absolute;
  top: 0px;
  left: 0px;
`
