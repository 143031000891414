import { DateRange as DateRangeBase, StartDate, EndDate } from '../../base/gui/controls/DateRange'
import { DateDisplay } from './DateDisplay'
import { Icon } from './Style'

import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: space-between;
`

export const DateRange = ({ onChange = null, contextRef = null }) => {
  return (
    <DateRangeBase onChange={onChange} contextRef={contextRef}>
      {/* <h3>Select Date</h3> */}
      <Row>
        <div style={{ float: 'left', marginLeft: '2rem', marginRight: '2rem' }}>
          <Icon />
        </div>
        <StartDate>
          <DateDisplay defaultText='Check in' />
        </StartDate>
        <span style={{ margin: '0 2rem' }}>/</span>
        <EndDate>
          <DateDisplay defaultText='Check out' />
        </EndDate>
      </Row>
    </DateRangeBase>
  )
}
