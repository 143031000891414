import { Page } from '../Home/Style'
import { StyledTermsAndCondition } from './Style'

export const TermsAndConditions = () => {
  return (
    <Page>
      <StyledTermsAndCondition>
        <div className='root-0-2-277'>
          <h1 className='TextField-h1-0-2-245 text-field-wrapper'>Terms &amp; Conditions</h1>
          <div className='text-field-wrapper'>
            Between Client and/or Guest (hereinafter referred to as “Client”), and Toronto Boutique Apartments (Boutique
            Asset Group Ltd) (hereinafter referred to as TBA) <br />
            <br />- The parties intend and agree that the Residential Tenancies Act of 2020 (herein referred to as the
            “Residential Tenancies Act”) not apply with respect to the relation between the parties because of the
            exemption set out in section 5(a) of the Residential Tenancies Act.
            <br />- TBA hereby grants a License to the Client/Guest to occupy said premises, noted on page 1 of this
            agreement.
            <br />- Both Parties agree that the said premises are a Vacation Home, to be used only for temporary
            residential dwelling &amp; occupied on full time basis by the Client(s), &amp; not used for business use.
            Except for casual guests no other persons shall occupy the premises without written consent.
            <br />- TBA will provide Client with 1 vacation home key, 1 building fob and 1 parking clicker if
            applicable. Clients previously paid $500 Reservation Deposit automatically becomes their Damage Deposit once
            they have moved in.
            <br />- Client has the right, if in good standing, to renew/extend the lease, and agrees to provide 15 days’
            notice of his/her wish to renew/extend lease. Length of extension will be agreed upon at that time, License
            Fee will be pro-rated and subject to increase.
            <br />- Any extension of the departure date is subject to availability and approval by TBA. Any UNAUTHORIZED
            occupancy beyond dates approved by TBA will be liable for a charge oftwice the daily rates then in effect
            for such holdover period.
            <br />- By default, this means that the property is being actively marketed for post you check out. If you
            have plans to extend, please do reach out soon as you can to place a deposit to hold the unit, otherwise tba
            may be unable to accommodate potential extensions.
            <br />- License Fee is Payable in advance 1 week prior to the month anniversary of lease start day of each
            month during the said term.
            <br />- For lease terms longer than 2 months, a fixed late payment fee of $100 (incidental fee) will be
            applied per day the licensing fee is late via the credit card on file, capped at 1 week, any delays past 1
            week will incur forfeit of vacation rental and any licensing fees paid at the beginning of the stay (such as
            last month’s rent and deposit).
            <br />- $50 fee for each NSF cheque. The Client agrees to pay TBA all fees and costs, including reasonable
            fees by attorneys or collection agencies, incurred in TBA’s efforts to collect all amounts due.
            <br />- This offer to Lease is conditional upon the Client, upon acceptance of this Agreement, to provide
            the agreed-upon payment(s).
            <br />- At the start of the lease term Client agrees to inform TBA of any damaged/missing items in the
            furnished vacation home within the first 24 hours of his/her stay, after which time it is assumed that all
            items in the vacation home are in good, working condition. Upon end of lease term, TBA will inspect the
            vacation home and refund the Deposit within 48 hours if there are no damages or missing items. TBA will
            charge for any damages to the furniture or inside elements of the vacation home (beyond normal wear &amp;
            tear) &amp;/or missing items, &amp; including elements in the vacation home building, against the Deposit. A
            quote will first be sourced for the Client in this case, and the overall process may take longer 48 hours.
            <br />- Client agrees to maintain the unit and all furnishings and appliances provided in the unit, in clean
            &amp; orderly manner, failure to do so may result in a cleaning fee of $100. Client will not remove any of
            the items of furniture and/or appliances provided in the unit, or make any holes in the walls, or install
            anything related to plumbing (ie extra bathroom hose) or for any other purpose inside the said property or
            in the balcony, during or at the end of his/her lease. Client agrees if they do make such changes without
            consent, they will cover the cost of repair/removal even if/when above their deposit amount. Client agrees
            to return all provided keys/fobs/clickers back to TBA at end of lease - if any keys/fobs/clickers are
            missing, Client agrees to reimburse the cost from their Deposit. Client agrees not to change locks on the
            leased Vacation Home under any conditions.
            <br />- Client agrees not to place additional holes in walls, move furniture, or add additional furniture
            the vacation home without TBA consent. Client will remove any additional furniture brought into the suite at
            the end of term - failure to do so results in a $500 automatic removal fee.
            <br />- Internet Service is unlimited usage. * TBA takes no responsibility for the content viewed online and
            for any legal costs.
            <br />- Client agrees &amp; acknowledges that TBA is not responsible for safe keeping of client’s personal
            items in vacation home or common areas of the Property. TBA is not responsible for personal items left
            behind in unit, after end of the lease term, and cannot guarantee return of items.
            <br />- TBA, and its staff, have the right, at reasonable times (9am-8pm) and with confirmed 24- Hour notice
            to the Client, to conduct a viewing/ walk-through of the vacation home with prospective Clients, or schedule
            emergency repairs, such as leaks, electric issues etc.
            <br />- Client agrees to provide a copy of his/her provide a government-issued ID prior to move-in and lease
            term start date. Client agrees and gives consent that TBA may run a background &amp; credit check prior to
            move in and agrees that TBA has the right to void any agreement based on the result of the background check.
            <br />- Client acknowledges receipt of his/her signed copy of this accepted Agreement to Lease.
            <br />- Client agrees to comply with all Vacation home regulations and building rules. Building management
            reserves the right to remove any Client due to misconduct or violation of these Terms and Conditions or such
            rules. Client agrees no smoking is allowed on premises, in vacation home/balconies.
            <br />- The Client may not assign, sublease, transfer the Reservation Confirmation, the apartment, or any
            part thereof or permit the use of the apartment by anyone other than the Client and permitted occupants.
            <br />- The Client shall indemnify and hold TBA harmless from and against all claims, expenses, liabilities,
            costs, demands (including those made by the owner of the Property) and damages of any nature whatsoever
            arising from (a) any damages to the apartment and its furnishings or fixtures or the common areas of the
            Property due to negligence, neglect or abuse of Client or any invitee of it or other occupant, (b) the use
            of the apartment or the common areas of the Property by the Client or any invitee of it or other occupant,
            (c) any damages incurred by the Client or any invitee of it or other occupant arising from any negligence in
            connection with the operation, management, maintenance, repair or replacement of the Property where the
            owner of the Property is not TBA or an affiliate of it, or (d) any costs in connection with the Client
            having to relocate from the Property due to any circumstances whatsoever, including any damage or
            destruction of the Property or during any period of demolition, repair, restoration or remedying of any
            condition.
            <br />- The Client is responsible for insuring their belongings for the duration of their stay.
            <br />- The Client authorizes TBA to obtain a credit report from one or more consumer credit reporting
            agencies. TBA may request this report at any time and from time to time during the Client's stay with TBA
            and the reservation may be subject to minimum credit scores. The Client understands that unless minimum
            credit score (600+) is met the reservation may be cancelled.
            <br />- In accordance with the Personal Information Protection and Electronic Documents Act (PIPEDA), the
            Client acknowledges that providing personal information to TBA is considered consent to the collection, use
            and disclosure for the stated purposes, and may be shared with related TBA companies and third-party
            providers (such as background and credit check companies). If the Client wishes to withdraw consent for one
            or more purposes, the Client must notify the local TBA Office in writing.
            <br />- The rights granted to the Client hereunder constitute a License only and will not, under any
            circumstances, constitute a lease or tenancy agreement between the parties. The party’s intent that the
            Tenant Protection Act not apply with respect to the occupancy of the Client in the Furnished Suite as the
            parties agree that section 5(a) of the Residential Tenancies Act provides an exemption to this agreement.
            This agreement may not be assigned, either in whole or in part, by the Client. The invalidity or
            unenforceability, in whole or in part, of any provision of this agreement will not affect the validity or
            enforceability of the remainder thereof.
            <br />- Reservation is not guaranteed until all initial invoices are paid.
          </div>
        </div>
      </StyledTermsAndCondition>
    </Page>
  )
}
