import api from '../../utils/api'
import { FormContainer, FormGroup, FormButton, FormGroupTransition, Layout, PhoneNumbers } from './style'
import { FormSelect } from './FormSelect'
import { useValue } from '../../components/hooks/useValue'
import { SubmitWrapper } from '../../components/base/gui/controls/Form'
import { FormTextArea } from './FormTextArea'
import { FormInput } from './FormInput'
import { FormEmailInput } from './FormEmailInput'
import { Loader } from '../../components/elements/Loader'
import ReactGA from 'react-ga'
import { PhoneInput } from './PhoneInput'
import { Col } from '../../components/layouts/Col'
import { GridRow } from '../../components/layouts/GridRow'
//import { sendCustomPageView } from '../../utils/analytics'
import { sendCustomPageViewGTM } from '../../utils/analytics/GTM'

export const ContactForm = () => {


  const formStep = useValue(1)
  const reachBackBy = useValue(null)
  const view = useValue('form')
  const formValues = {}

  const saveFormValues = (values) => {
    for (let key in values) {
      formValues[key] = values[key]
    }
  }

  const nextFormStep = () => {
    formStep.set(formStep.get() + 1)
  }

  const onSubmit = async ({ values }) => {
    saveFormValues(values)
    if (formStep.get() === 3) {
      view.set('sending')
      try {
        await api.post('/contact-us', formValues)
        ReactGA.event({ category: 'contact-form', action: 'submit-form', label: '' })
        view.set('success')

        try
        {
            sendCustomPageViewGTM('/inquiry/success')
        }
        catch(e){/*do nothing*/}

      } catch (e) {
        view.set('failed')
        console.log(e)
        alert(e.message)
      }
    } else {
      nextFormStep()
    }
  }

  return (
    <Layout>
      <Col>
        <FormContainer onSubmit={onSubmit}>
          <view.Value
            render={(view) => (
              <>
                {view === 'form' && (
                  <>
                    <div>
                      <formStep.Value
                        render={(formStep) => (
                          <>
                            <FormGroupTransition
                              in={formStep === 1}
                              timeout={300}
                              classNames='form-group'
                              unmountOnExit>
                              <FormGroup>
                                <FormSelect name='inquiryType' label='How can we help?*' defaultValue=''>
                                  <option value='' disabled selected>
                                    -- Select --
                                  </option>
                                  <option value='RENTING_UNIT'>It's related to renting a unit</option>
                                  <option value='MY_UNIT'>It's about my unit</option>
                                  <option value='GENERAL_QUESTION'>It's a general question</option>
                                </FormSelect>
                              </FormGroup>
                            </FormGroupTransition>

                            <FormGroupTransition
                              in={formStep === 2}
                              timeout={300}
                              classNames='form-group'
                              unmountOnExit>
                              <>
                                <GridRow col='2'>
                                  <FormGroup
                                    initial={{ opacity: 0, y: 50 }}
                                    whileInView={{
                                      opacity: 1,
                                      y: 0,
                                    }}
                                    transition={{
                                      duration: 0.7,
                                      delay: 0.2,
                                    }}>
                                    <FormInput name='firstName' label='First Name*' />
                                  </FormGroup>

                                  <FormGroup>
                                    <FormInput name='lastName' label='Last Name*' />
                                  </FormGroup>

                                  <FormGroup>
                                    <FormEmailInput name='email' label='Email*' />
                                  </FormGroup>

                                  <FormGroup>
                                    <PhoneInput name='phone' label='Phone Number*' />
                                  </FormGroup>
                                </GridRow>
                                <FormGroup>
                                  <FormSelect
                                    name='contactType'
                                    onChange={(value) => reachBackBy.set(value)}
                                    label="What's the best way to reach back?"
                                    defaultValue=''>
                                    <option value=''>-- Select --</option>
                                    <option value='EMAIL'>Email</option>
                                    <option value='PHONE_CALL'>Phone call</option>
                                  </FormSelect>
                                </FormGroup>
                              </>
                            </FormGroupTransition>

                            <FormGroupTransition
                              in={formStep === 3}
                              timeout={300}
                              classNames='form-group'
                              unmountOnExit>
                              <FormGroup>
                                <FormTextArea name='message' label='Tell us more about your inquiry*' />
                              </FormGroup>
                            </FormGroupTransition>
                          </>
                        )}
                      />
                    </div>

                    <SubmitWrapper>
                      <FormButton
                        type='button'
                        // initial={{ opacity: 0, y: -20 }}
                        // animate={{
                        //   opacity: 1,
                        //   y: 0,
                        // }}
                        // transition={{
                        //   duration: 0.35,
                        //   delay: 1.65,
                        // }}
                        // once
                      >
                        <formStep.Value>{formStep.get() === 3 ? <>Submit</> : <>Next</>}</formStep.Value>
                      </FormButton>
                    </SubmitWrapper>
                  </>
                )}
                {view === 'sending' && <Loader />}
                {view === 'success' && (
                  <>
                    <h3>Your message was sent</h3>
                    <p>We will get back to you as soon as we can.</p>
                  </>
                )}
                {view === 'failed' && (
                  <>
                    <h3>Oh no!</h3>
                    <p>Something went wrong and we could not send your message. We apologize for the inconvenience.</p>
                  </>
                )}
              </>
            )}
          />
        </FormContainer>
        {/* {showConfetti && <Confetti />} */}
        <PhoneNumbers><p>You can also contact us via phone at: <br/> <a href='tel:18002579483'><span>1-800-257-9483</span></a> or <a href='tel:16477152102'><span>1-647-715-2102</span></a></p></PhoneNumbers>
      </Col>
    </Layout>
  )
}
