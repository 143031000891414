import { SubmitWrapper } from "../../base/gui/controls/Form"
import Button from "../../elements/Button"
import {useState} from 'react'
import { Loader } from "../../elements/Loader"

export const SubmitButton = ({children}) => {

    const [updating, setUpdating] = useState(false)

    const onclick = async (fn) => {
        setUpdating(true)
        await fn()
        setUpdating(false)
    }
    
    return (
        <>
            {updating && <Button><Loader size = {"1.6rem"} color="white"/></Button>}
            {!updating && <SubmitWrapper clickEvent={onclick}><Button>{children}</Button></SubmitWrapper>}
        </>
    )
}