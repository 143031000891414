import api from '../utils/api'
import { useContext, useSyncExternalStore, createContext, useRef, useState, useEffect } from 'react'
import { Loader } from '../components/elements/Loader'
import { useParams } from 'react-router-dom'

export const makeProvider = (makeLogic) => {
  const StoreContext = createContext(null)

  const useStoreData = (props) => {
    const subscribers = new Set()

    const lastState = {
      toRenew: true,
      values: {},
    }

    const pushState = () => {
      lastState.toRenew = true
      subscribers.forEach((callback) => callback())
    }

    const logic = makeLogic({ pushState, api, ...props })

    const subscribe = (callback) => {
      subscribers.add(callback)
      return () => subscribers.delete(callback)
    }

    const get = () => {
      if (lastState.toRenew) {
        lastState.toRenew = false
        lastState.values = { ...logic.state }
      }
      return lastState.values
    }

    return { subscribe, get, logic }
  }

  const Provider = ({ children, ...props }) => {
    const store = useRef(useStoreData({ ...props }))
    const [isLoading, setLoading] = useState(typeof store.current.logic.load !== 'undefined')
    const [isError, setError] = useState(null)
    const params = useParams()

    useEffect(() => {
      if (store.current.logic.load) {
        const onLoad = async () => {
          try {
            await store.current.logic.load({ params })
          } catch (ex) {
            setError(ex.message)
          }
          setLoading(false)
        }

        onLoad()
      }
    }, [store])

    if (isLoading) {
      return (
        <div style={{ display: 'flex', margin: 'auto', height: '83vh' }}>
          <Loader />
        </div>
      )
    }

    if (isError) {
      return <h3>{isError}</h3>
    }

    return <StoreContext.Provider value={store.current}>{children}</StoreContext.Provider>
  }

  const useStore = (option = null) => {
    const context = useContext(StoreContext)
    if (!context) {
      throw new Error('useStore must be used within a Provider')
    }

    let subscribeFunction = context.subscribe

    let getFunction = context.get

    if (option === false) {
      subscribeFunction = () => {
        return () => null
      }
    } else if (option == null) {
      //do nothing
    } else if (Array.isArray(option)) {
      let previousValue = {}
      getFunction = () => {
        let storeValues = context.get()
        let values = {}
        for (let i = 0; i < option.length; i++) {
          values[option[i]] = storeValues[option[i]]
        }

        let isNew = false
        for (let key in values) {
          if (values[key] !== previousValue[key]) {
            isNew = true
            break
          }
        }

        if (isNew) {
          previousValue = values
          return values
        } else {
          return previousValue
        }
      }
    } else if (typeof option === 'object') {
      let previousValue = {}
      getFunction = () => {
        let storeValues = context.get()
        let values = {}
        for (let key in option) {
          values[key] = option[key](storeValues)
        }

        let isNew = false
        for (let key in values) {
          if (values[key] !== previousValue[key]) {
            isNew = true
            break
          }
        }

        if (isNew) {
          previousValue = values
          return values
        } else {
          return previousValue
        }
      }
    } else if (typeof option === 'string') {
      getFunction = () => {
        let storeValues = context.get()
        return storeValues[option]
      }
    } else {
      //do nothing
    }

    const store = useSyncExternalStore(subscribeFunction, getFunction)

    if (option === false) {
      return context.logic
    } else if (option == null) {
      return { store, ...context.logic }
    } else if (typeof option === 'object') {
      return { ...store, ...context.logic }
    } else if (typeof option === 'string') {
      return { [option]: store, ...context.logic }
    } else {
      return { store, ...context.logic }
    }
  }

  return { Provider, useStore }
}

export default makeProvider
