import styled from 'styled-components'

export const StyledPageSelector = styled.div`

    margin-top: 1rem;

    .current-page {
        font-weight: 700;
        color: black;
    }

    .select-page {
        font-weight: 300;
        color: ${props => props.theme.colors.primary};
        cursor: pointer;
    }

    .fa-solid {
        color: ${props => props.theme.colors.primary};
        cursor: pointer;
        padding: 4px;
    }

    .page-num {
        padding: 4px;
    }
`