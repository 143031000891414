import { cloneElement, useContext, useEffect, useState } from "react"
import { DateRangeContext } from "./index.js"

export const EndDate = ({children}) => {

    const context = useContext(DateRangeContext)
    const [endDateProps, setEndDateProps] = useState({isFocused: context.isEndDateFocused, date: context.endDate})

    useEffect(() => {
        context.setEndDateProps = setEndDateProps
    }, [])

    return (
        <>{cloneElement(children, {applyFocus: () => context.onEndDateFocus(), ...endDateProps})}</>
    )
}