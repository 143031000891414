import { MapBox } from '../MapBox'
import { GridRow } from '../../../components/layouts/GridRow'
import { useUnitDetails } from '../../../app/providers/unit'
import { Card, H3, H4, Text } from '../Style'
import { Row } from '../../../components/layouts/Row'
import { TextScroller } from './TextScroller'
import { Col } from '../../../components/layouts/Col'
import { Amenities } from '../Amenities'
import { Map } from '../../../features/Map/searchResult'
import { useRef } from 'react'

export const UnitAboutSection = () => {
  const { unitData } = useUnitDetails('unitData')
  const currentlyOpenedPin = useRef(null)
  const {
    title,
    descriptions: { neighborhood, space = '' },
  } = unitData



  const onMarkerClick = (marker) => {
    currentlyOpenedPin.current = { marker }
    marker.controller.openClickPopup()
  }

  return (
    <Col>
      <div style={{marginTop: "10vh"}}>
        <Row align='center' margin='0' marginBottom='4rem' height='auto'>
          {/* <H3>About</H3>&nbsp; <H4>• {title}</H4> */}
          <H3>About</H3>&nbsp; <H4>• the location</H4>
        </Row>
        {/*<TextScroller content={space}></TextScroller>*/}
      </div>
      {/* <MapBox /> */}

<Map
                          markers={[{ location: { lat: unitData.address.lat, lng: unitData.address.lng } }]}

                        height='calc(100vh - 32rem)'
                        onMarkerClick={onMarkerClick}
                      /> 

      
      {/* <pre>{JSON.stringify(unitData, null, 2)}</pre> */}
    </Col>
  )
}
