import { useContext, cloneElement} from 'react';
import { ValidationContext } from './index';

export const SubmitWrapper = ({ children, name, onResult, clickEvent = null}) => {
  const myFormik = useContext(ValidationContext);

  const onClick = (e) => {
    if(clickEvent)
    {
      clickEvent(async () => await myFormik.submit({name: name || "", onResult}))
    }
    else
    {
      myFormik.submit({name: name || "", onResult});
    }
  };

  return (
      <>{cloneElement(children,{"onClick": onClick})}</>
  );
};
