import Button from '../../components/elements/Button'
//import { useSearchResults } from '../../app/providers/unitSearchProvider'
import { useListingSearch } from '../../app/providers/unitSearch'
import { useEffect, useRef, useState } from 'react'
import {
  ButtonIconCircle,
  Card,
  DatesSelect,
  Entry,
  ExpandButton,
  Grid,
  Icon,
  Label,
  LocationSelect,
  Select,
  VerticalBar,
} from './Style'
import { searchIcon } from '../../app/media/icons'
import { DateRange } from '../../components/inputs/DateRange'

import selectedOptions from '../../app/context/reducers/selectedOptions'
import { GuestsSelect } from './GuestsSelect'
import { useHeaderSignal } from '../../app/theme/header/HeaderSignal'

const options = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
]

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const SearchBarBooking = () => {
  const { cities, searchByCity, loadCities, searchByFilters, controller } = useListingSearch('cities')
  const {searchBarContracted} = controller.state

  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null })
  const [numberOfGuests, setNumberOfGuests] = useState(1)
  const [searching, setSearching] = useState(false)
  const receivedHeaderSignal = useHeaderSignal()
  const dateContextRef = useRef(null)

  //const [searchBarContracted, setSearchBarContracted] = useState(false)
  //is now: controller.state.searchBarContracted

  const onClick = async () => {
    /*if (!selectedCity) {
      return
    }*/

    setSearching(true)

    try {
      if (selectedDates.startDate == null && selectedDates.endDate == null) {
        selectedOptions.setSearchFilters({ dates: null, numberOfGuests })

        if (selectedCity === null) {
          await searchByCity(null, numberOfGuests)
        } else {
          await searchByCity(selectedCity.value, numberOfGuests)
        }
      } else if (selectedDates.startDate !== null && selectedDates.endDate !== null) {
        let startDate = formatDate(selectedDates.startDate)
        let endDate = formatDate(selectedDates.endDate)

        selectedOptions.setSearchFilters({ dates: selectedDates, numberOfGuests })

        await searchByFilters({
          city: selectedCity ? selectedCity.value.city : null,
          country: selectedCity ? selectedCity.value.country : null,
          startDate,
          endDate,
          guests: numberOfGuests,
        })
      } else if (selectedDates.startDate == null) {
        alert('Check in date is required')
      } else if (selectedDates.endDate == null) {
        alert('Check out date is required')
      }
    } catch (ex) {
      console.log(ex)
      alert(ex.message)
    }

    setSearching(false)
    searchBarContracted.set(true)
  }

  useEffect(() => {
    if(receivedHeaderSignal)
    {
      setSelectedCity(() => null)
      setSelectedDates(() => ({ startDate: null, endDate: null }))
      if(dateContextRef.current)
      {
        dateContextRef.current.clearDateSelection()
      }
      setNumberOfGuests(() => 1)
    }
  }, [receivedHeaderSignal])

  useEffect(() => {
    loadCities()
  }, [])

  return (
    <>
      <searchBarContracted.Value render={isContracted => <>
        <ExpandButton
          onClick={() => searchBarContracted.set(!isContracted)}
          searchBarContracted={isContracted}
          className='fa-solid fa-caret-down'
        />
      
      
        <Card searchBarContracted={isContracted}>
          <Grid mobile>
            <LocationSelect>
              <Label>Where</Label>
              <Select
                value={selectedCity}
                onChange={setSelectedCity}
                options={cities.map((city) => ({ value: city, label: `${city.city}` }))}
                isLoading={cities.length === 0}
                placeholder='City'
              />
            </LocationSelect>
            <VerticalBar />
            <DatesSelect>
              {/*<Icon />*/}
              {/* <span> Check In</span>
              <span> / </span> <span>Check Out</span> */}
              <DateRange onChange={setSelectedDates} contextRef={dateContextRef}/>
            </DatesSelect>

            <GuestsSelect numberOfGuests={numberOfGuests} setNumberOfGuests={setNumberOfGuests}/>

            <ButtonIconCircle onClick={onClick} searching={searching}>
              {searchIcon}
            </ButtonIconCircle>
          </Grid>
        </Card>
      </>}/>
    </>
  )
}
