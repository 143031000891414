import React, {
  useRef,
  createContext,
  useContext,
  useCallback,
  useSyncExternalStore,
} from 'react'

const createHighVelocityContext = (initialState) => {
  const StoreContext = createContext(null)

  const useStoreData = () => {
    const store = useRef(initialState)
    const subscribers = useRef(new Set())

    const get = useCallback(() => store.current, [])
    const set = useCallback(
      (newState) => {
        store.current = { ...store.current, ...newState }
        subscribers.current.forEach((callback) => callback())
      },
      [store, subscribers]
    )
    const subscribe = useCallback(
      (callback) => {
        subscribers.current.add(callback)
        return () => subscribers.current.delete(callback)
      },
      [subscribers]
    )

    return {
      get,
      set,
      subscribe,
    }
  }

  const Provider = ({ children }) => {
    const store = useStoreData()
    return (
      <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    )
  }

  const useStore = (selector) => {
    const store = useContext(StoreContext)
    if (!store) {
      throw new Error('useStore must be used within a Provider')
    }

    const state = useSyncExternalStore(store.subscribe, () =>
      selector(store.get(), () => initialState)
    )

    const setState = (newState) => {
      store.set(newState)
    }

    return [state, setState]
  }

  return { Provider, useStore }
}

export default createHighVelocityContext
