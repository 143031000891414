import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 15rem;
  overflow-y: scroll;

  @media screen and (max-width: 768px) {
    /* height: 10rem; */
  }
`

export const ListingContainer = ({ children }) => {
  return <Container>{children}</Container>
}
