import styled from 'styled-components'
import ReactDOMServer from 'react-dom/server'
import { ListingPopup } from '../../../features/DisplayListings/ListingPopup'
import { ListingContainer } from '../../../features/DisplayListings/ListingPopup/ListingContainer'

const HR = styled.hr`
  height: 1px;
  background-color: #eee;
  border: none;
`

export const popupProcessor = (listing, markerMap, urlParams = '') => {

  listing.marker.popupContent = ReactDOMServer.renderToStaticMarkup(<ListingPopup data={listing} urlParams={urlParams}/>)

  let positionString = listing.unit.lat.toFixed(5) + ',' + listing.unit.lng.toFixed(5)

  listing.marker.onClickPopupContent = ReactDOMServer.renderToStaticMarkup(
    <ListingContainer>
      {markerMap[positionString].map((unit, index) => (
        <>
          <ListingPopup key={'unit_' + index} data={{ unit }} urlParams={urlParams} />
          <HR />
        </>
      ))}
    </ListingContainer>
  )

  listing.marker.label = markerMap[positionString].length.toString() // > 1 ? markerMap[positionString].length.toString() : null

  return listing
}
