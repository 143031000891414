import styled from 'styled-components'
import {useEffect} from 'react'
//import { sendPageView } from '../../../utils/analytics'
import { sendPageViewGTM } from '../../../utils/analytics/GTM'

const StyledPage = styled.main`
  max-width: ${({ maxWidth }) => maxWidth || '90%'};
  margin: 0 auto;
  padding-top: 7.2rem;
  padding-bottom: 3.5rem;
`

export const Page = ({className, children}) => {

  useEffect(() => {
    sendPageViewGTM()
  }, [])

  return (
    <StyledPage className={className}>
      {children}
    </StyledPage>
  )
} 
