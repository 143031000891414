import { useUnitDetails } from '../../../app/providers/unit'
import styled from 'styled-components'

import { Col } from '../../../components/layouts/Col'
import { GridRow } from '../../../components/layouts/GridRow'
import { ImageCardStack } from '../../../features/ImageCardStack'
import { BookingCard } from '../BookingCard'
import { H1, Layout, MobileButton, TextDescription } from './Style'
import { useState } from 'react'
import { CustomGridRow } from '../BookingCard/Style'

export const Hero = () => {
  const { unitData } = useUnitDetails('unitData')
  const [showBookingCard, setShowBookingCard] = useState(false)

  return (
    <>
      <Layout>
        {/*<MobileButton onClick={() => setShowBookingCard(!showBookingCard)}>Book now</MobileButton>*/}
        <Col margin='0rem'>
          <H1>{unitData.title}</H1>
          <TextDescription>
            <i class='fa-solid fa-location-dot'></i>&nbsp;&nbsp;{unitData.address.full}
          </TextDescription>
        </Col>

        <CustomGridRow colTemplate='3fr 2fr' mobile>
          <div style={{width: "100%", justifySelf: "start", marginBottom: "4rem"}}>
            <ImageCardStack pictures={unitData.pictures} />
          </div>
          <div style={{position: "relative"}}><BookingCard showBookingCard={showBookingCard} /></div>
        </CustomGridRow>
      </Layout>
    </>
  )
}
