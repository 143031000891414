export const usePagination = ({ list = [], elementsPerPage = 10 }) => {

  let state = {
    currentPage: 0,
    numberOfPages: Math.ceil(list.length / elementsPerPage),
    elementsPerPage,
    pageData: list.slice(0, elementsPerPage),
  }

  let updateListComponent = null
  let updatePaginationComponent = null
  let scrollListToTop = () => null

  const subscribeListComponent = (setStateFunction, scrollToTopFunction = () => null) => {
    scrollListToTop = scrollToTopFunction
    updateListComponent = setStateFunction
  }

  const subscribePaginationComponent = (setStateFunction) => {
    updatePaginationComponent = setStateFunction
  }

  const getPageData = () => {
    return state.pageData
  }

  const getCurrentPage = () => {
    return state.currentPage + 1
  }

  const updatePageData = () => {
    state.pageData = list.slice(state.currentPage * elementsPerPage, (state.currentPage + 1) * elementsPerPage)

    if (updateListComponent) {
      scrollListToTop()
      updateListComponent(state.pageData)
    }

    if (updatePaginationComponent) {
      updatePaginationComponent(state.currentPage + 1)
    }
  }

  const previousPage = () => {
    if (state.currentPage > 0) {
      state.currentPage--
      updatePageData()
    }
  }

  const nextPage = () => {
    if (list.length % elementsPerPage === 0) {
      if (state.currentPage + 1 < Math.floor(list.length / elementsPerPage)) {
        state.currentPage++
        updatePageData()
      }
    } else if (state.currentPage < Math.floor(list.length / elementsPerPage)) {
      state.currentPage++
      updatePageData()
    }
  }

  const setPage = (index) => {
    let page = index - 1
    if (page < 0) {
      page = 0
    } else if (page >= Math.floor(list.length / elementsPerPage)) {
      page = Math.floor(list.length / elementsPerPage)
    }

    state.currentPage = page
    updatePageData()
  }

  const reloadList = (newList) => {
    state.currentPage = 0;
    state.numberOfPages = Math.ceil(newList.length / elementsPerPage);
    state.pageData = newList.slice(0, elementsPerPage)
    updatePageData()
  }

  return {
    getPageData,
    getCurrentPage,
    subscribeListComponent,
    subscribePaginationComponent,
    previousPage,
    nextPage,
    setPage,
    numberOfPages: state.numberOfPages,
    reloadList
  }
}
