import React, { useEffect, useState } from 'react'

import image1 from '../../app/media/img/toronto-dt.jpg'
import image2 from '../../app/media/img/toronto-from-above.jpg'
import livingRoom from '../../app/media/img/condo-living-room.jpg'
import listingExample from '../../app/media/img/listing-example-tba.jpg'
import maskToronto from '../../app/media/img/mask-toronto.png'
import { animated, useSpring } from 'react-spring'

import { useInView } from 'react-intersection-observer'

import {
  AboutContainer,
  BackgroundImage,
  Col,
  Image,
  Img,
  Img2,
  LargeCard,
  LargeCard2,
  Row,
  Section,
  Section2,
  SectionBg,
  SectionContent,
  SectionTitle,
  TextContainer,
  TextContainer2,
  TextSection,
  VerticalText,
} from './Style'
import { HighTechCarousel } from './Carousel'
import { YourHome } from './YourHome'
import { OurApartments } from './OurApartments'
import { Reviews } from './Reviews'
import { ContactForm } from '../../features/ContactForm'
import { GridRow } from '../../components/layouts/GridRow'

import styled from 'styled-components'
import { motion, useScroll, useTransform, useViewportScroll } from 'framer-motion'
import { useValue } from '../../components/hooks/useValue'
import { TextSpring } from '../../components/base/gui/animations/TextSpring'
//import { sendPageView } from '../../utils/analytics'
import { sendPageViewGTM } from '../../utils/analytics/GTM'

const images = [livingRoom, listingExample]

const HeadingHero = styled(motion.h1)`
  font-size: 4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) !important;
  /* left: 50%;
  top: 50%; */
  /* transform: translate(-50%, -50%); */
  color: white;
  opacity: 0.9;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    width: 90%;
  }
`

const ArrowContainer = styled(motion.div)`
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const AboutPage = () => {
  let sectionCount = 6
  const { scrollYProgress } = useScroll()
  const scrollProgress = useTransform(scrollYProgress, [0, sectionCount * window.innerHeight], [0, 1])
  //const [currentImage, setCurrentImage] = useState(0)
  const currentImage = useValue(0)

  useEffect(() => {
    const interval = setInterval(() => {
      //setCurrentImage((prevImage) => (prevImage === images.length - 1 ? 0 : prevImage + 1))
      let prevImage = currentImage.get()
      currentImage.set(prevImage === images.length - 1 ? 0 : prevImage + 1)
    }, 4000)

    sendPageViewGTM()

    return () => clearInterval(interval)
  }, [])

  const sectionSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(30px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 200, friction: 20 },
  })

  const imageSpring = useSpring({
    from: { opacity: 0, transform: 'scale(0.9)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 200, friction: 20 },
  })

  const scrollToNextSection = () => {
    // const nextSectionIndex = Math.ceil(scrollProgress.current * sectionCount)
    // const nextSectionOffset = (nextSectionIndex + 1) * window.innerHeight
    window.scrollTo({
      top: window.innerHeight - 65,
      behavior: 'smooth',
    })
  }

  return (
    <AboutContainer>
      <Section style={sectionSpring}>
        <BackgroundImage>
          <HeadingHero
            initial={{ opacity: 0, y: 50 }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 1,
              delay: 1,
            }}
            once>
            Your home away from home
          </HeadingHero>
          {/* <ScrollIndicator sectionCount={6} /> */}
          <ArrowContainer
            onClick={scrollToNextSection}
            initial={{ opacity: 0, y: 50, x: '-50%' }}
            whileInView={{
              opacity: 1,
              y: 0,
              x: '-50%',
            }}
            transition={{
              duration: 0.7,
              delay: 1.8,
            }}
            once>
            <Circle>
              {/* <Arrow style={{ rotate: arrowRotation }} /> */}
              <i
                class='fa-sharp fa-solid fa-arrow-down-long'
                style={{ color: '#63a1a0', textShadow: '0 0 4px rgba(0, 0, 0, 0.3)', opacity: 0.8 }}></i>
            </Circle>
          </ArrowContainer>

          <currentImage.Value
            render={(currentImage) => <Img style={imageSpring} src={images[currentImage]} alt='Background' />}
          />

          {/* <SectionTitle>Welcome to Toronto Boutique Apartments</SectionTitle>
          <SectionContent>
            We offer the finest selection of boutique apartments in Toronto for long-term rentals. Experience modern
            living in the heart of the city with our beautifully designed and fully furnished apartments.
          </SectionContent> */}
        </BackgroundImage>
      </Section>

      <TextSpring>
        <TextSection>We believe our units speak for themselves</TextSection>
      </TextSpring>

      <Section2 style={sectionSpring}>
        <LargeCard>
          <TextContainer>
            <SectionTitle>About us</SectionTitle>

            <SectionContent>
              We offer the finest selection of boutique apartments in Toronto for long-term rentals. Experience modern
              living in the heart of the city with our beautifully designed and fully furnished apartments.
            </SectionContent>
          </TextContainer>
          <Img2 src={maskToronto} />
        </LargeCard>
        {/* <ImageContainer>
          <Image style={imageSpring} src={image1} alt='Apartment 1' />
          <Image style={imageSpring} src={image2} alt='Apartment 2' />
        </ImageContainer> */}
      </Section2>

      <OurApartments style={sectionSpring} />

      <YourHome style={sectionSpring} />

      <Section2 style={sectionSpring}>
        <LargeCard>
          <TextContainer2>
            <SectionTitle>Stunning & modern rentals in Toronto</SectionTitle>

            <SectionContent>
              Our long-term rentals in Toronto are fully furnished experiences, curated with impeccable aesthetics and
              excellent one-on-one customer service. We focus on providing personalized client service to our long-term
              guests to make their furnished stay experience authentic and comfortable. Our rental condos are located in
              Yorkville and King West areas of downtown Toronto, making them ideal for executive corporate housing or
              company relocations. We use professional interior designers to create the best interior layout and
              purchase high-end furniture for each furnished rental in Toronto.
            </SectionContent>
          </TextContainer2>
        </LargeCard>
      </Section2>

      <Reviews />

      <GridRow col='2' mobile style={{ marginBottom: '10rem' }}>
        <Col>
          <h2>Contact us</h2>
          <p style={{ display: 'flex', width: '100%', lineHeight: '1.6' }}>
            Contact us at info@torontoboutiqueapartments.com or 1-800-257-9483 to schedule a viewing appointment of one
            of our furnished rentals Toronto suites.
          </p>
        </Col>
        <ContactForm />
      </GridRow>
    </AboutContainer>
  )
}
