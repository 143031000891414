import { useEffect, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import ContentLoader from 'react-content-loader'
import { GridRow } from '../../components/layouts/GridRow'

import { Listing } from './Listing'
import { BottomMargin, Container, H3, Layout, Map, MapToggleButton, TextEmphasis, Wrapper } from './Style'
// import { Map } from '../Map/searchResult'
import { Pagination } from './Pagination(clean)'
import { useListingSearch } from '../../app/providers/unitSearch'
import { PaginationProvider } from '../../app/providers/pagination'
import { PaginatedListings } from './PaginatedListings'
//import { usePagination } from './Pagination(clean)/usePagination'
import { usePagination } from '../../components/base/controllers/pagination'
import { Row } from '../../components/layouts/Row'
import { useNavigate } from 'react-router-dom'
import { Col } from '../../components/layouts/Col'
import { useValue } from '../../components/hooks/useValue'
import { MapView } from '../../pages/Home/MapView'
import { useHeaderSignal } from '../../app/theme/header/HeaderSignal'

const SkeletonLoader = () => (
  <ContentLoader
    speed={5}
    width={1366}
    height={300}
    viewBox='0 0 1366 300'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'>
    <rect x='20' y='20' rx='10' ry='10' width='400' height='230' />
    {/* <rect x='240' y='20' rx='0' ry='0' width='200' height='20' />
    <rect x='240' y='50' rx='0' ry='0' width='200' height='20' /> */}
    <rect x='20' y='280' rx='10' ry='10' width='200' height='20' />
    <rect x='20' y='300' rx='10' ry='10' width='100' height='20' />
    {/* <rect x='20' y='300' rx='0' ry='0' width='100' height='20' /> */}
  </ContentLoader>
)

export const DisplayListings = () => {
  const { listings, searchView, getAll, controller, clearSearchView } = useListingSearch(['listings', 'searchView'])
  const {searchBarContracted} = controller.state
  const currentlyOpenedPin = useRef(null)
  const paginationController = usePagination({ list: listings })
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const view = useValue('list')
  const receivedHeaderSignal = useHeaderSignal()

  const onMouseEnter = (listing) => {
    if (currentlyOpenedPin.current) {
      currentlyOpenedPin.current.marker.controller.closePopup()
    }

    if (listing.marker.controller) {
      currentlyOpenedPin.current = listing
      listing.marker.controller.openPopup()
    }
  }

  const handleToggle = () => {
    let newValue = view.get() === 'list' ? 'map' : 'list'
    view.set(newValue)
  }

  const onMarkerClick = (marker) => {
    if (currentlyOpenedPin.current) {
      currentlyOpenedPin.current.marker.controller.closePopup()
    }

    currentlyOpenedPin.current = { marker }
    marker.controller.openClickPopup()
  }

  useEffect(() => {
    getAll()

    const onScroll = () => {
      const scrollTarget = document.documentElement.scrollHeight - 1.3*window.innerHeight
      if(window.scrollY >= scrollTarget)
      {
        controller.loadMore()
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    if(listings.length > 0)
    {
      setIsLoading(false)
    }
  }, [listings])

  useEffect(() => {
    if(receivedHeaderSignal)
    {
      clearSearchView()
    }
  }, [receivedHeaderSignal])

  return (
    <>
      <view.Value
        render={(view) => (
          <>
            {view === 'list' && (
              <Layout>
                <Wrapper>
                  <GridRow colTemplate='90rem 1fr' mobile>
                    <Row margin='0' justify='space-between'>
                      {searchView && listings.length > 0 && (
                        <H3>
                          <TextEmphasis>
                            {listings.length} listing{listings.length !== 1 && 's'}
                          </TextEmphasis>{' '}
                          within area
                        </H3>
                      )}
                      {searchView && listings.length > 0 && (
                        <>
                          <Pagination paginationController={paginationController} />
                        </>
                      )}
                    </Row>
                  </GridRow>
                </Wrapper>

                {!searchView && (
                  <BottomMargin>
                    <Col width='95%' margin='0 auto'>
                      <GridRow col='3' gridGap='2.5rem' mobile>
                        {isLoading ? (
                          // Show skeleton loader while loading
                          Array.from({ length: 9 }).map((_, index) => (
                            <div key={index} style={{ marginBottom: '2rem' }}>
                              <SkeletonLoader />
                            </div>
                          ))
                        ) : (
                          // Render listings once loaded
                          <TransitionGroup component={null}>
                            <controller.state.homepageListingCount.Value render={homepageListingCount => <>
                              {listings.slice(0, homepageListingCount).map(result => (
                                <CSSTransition key={result.unit.listingId} classNames='fade' timeout={300}>
                                  <Listing
                                    key={result.unit.listingId}
                                    data={result}
                                    onMouseEnter={() => onMouseEnter(result)}
                                    navigate={navigate}
                                  />
                                </CSSTransition>
                              ))}
                            </>}/>
                          </TransitionGroup>
                        )}
                      </GridRow>
                    </Col>
                  </BottomMargin>
                )}

                {searchView && listings.length == 0 && (
                  <center>
                    There are no units available for the dates requested. Please make sure you're selecting at least 1
                    month stay.
                  </center>
                )}

                {searchView && listings.length > 0 && (
                  <>
                    <GridRow colTemplate='90rem 1fr' mediumScreenTemplate="45rem 1fr" mediumScreenWidth="1400px" mobile>
                      <Col width='95%' margin='0 auto'>
                        <PaginatedListings
                          onMouseEnter={onMouseEnter}
                          paginationController={paginationController}
                          isLoading={isLoading}
                          searchBarContracted={searchBarContracted}
                        />
                      </Col>
                      <Map
                        markers={listings.map((listing) => listing.marker)}
                        height='calc(100vh - 32rem)'
                        onMarkerClick={onMarkerClick}
                      />
                    </GridRow>
                  </>
                )}
              </Layout>
            )}

            {view === 'map' && <MapView markers={listings.map((listing) => listing.marker)} />}

            {/* Map toggle button */}
            {listings.length > 0 && (
              <MapToggleButton onClick={handleToggle} searchView={searchView}>
                {view === 'list' ? (
                  <>
                    <i className="fa-solid fa-map"></i> &nbsp;Click to View Map
                  </>
                ) : (
                  <>
                    <i class='fa-solid fa-apartment'></i> &nbsp;Click to Show Listings
                  </>
                )}
              </MapToggleButton>
            )}
          </>
        )}
      />
    </>
  )
}
