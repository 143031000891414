import styled from 'styled-components'
import { Card as CardRef } from '../../components/layouts/Card'

export const Card = styled(CardRef)`
  padding: 4rem;

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }
`

export const H3 = styled.h3`
  font-size: 1.7rem;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
`

export const H4 = styled.h4`
  font-weight: bold;
  margin: 0;
`

export const Text = styled.span`
  font-size: 1.4rem;
  line-height: 183%;
  /* font-weight: bold; */
`

export const Container = styled.div`
  width: 60%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
