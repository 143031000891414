import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || ''};
  margin: ${(props) => props.margin || '2rem auto'};
  margin-bottom: ${(props) => props.marginBottom};
`
