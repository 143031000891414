import styled from 'styled-components'
import { StyledInputWrapper } from './style'
import { FormLabel } from './style'
import { FieldWrapper } from '../../components/base/gui/controls/Form'
import Joi from 'joi'

const StyledFormInput = styled.input`
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  background: #f3f3f3;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:focus {
    background: #fff;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
    outline: none;
  }
`

export const FormInput = ({ label = 'Text', name = 'text', onChange = null }) => {
  let idx = 0
  let labelHtml = label.split('').map((letter) => <span style={{ transitionDelay: `${idx++ * 50}ms` }}>{letter}</span>)

  return (
    <FormLabel
      initial={{ opacity: 0, y: 50 }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        duration: 0.7,
        delay: 0.2,
      }}>
      {labelHtml}
      <FieldWrapper
        name={name}
        onChange={onChange}
        validation={Joi.string().required().messages({ 'string.empty': 'this field is required', 'string.base': 'this field is required' })}
        StyleWrapper={StyledInputWrapper}>
        <StyledFormInput />
      </FieldWrapper>
    </FormLabel>
  )
}
