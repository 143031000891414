import styled from 'styled-components'
import { GridRow as GridRowRef } from '../../../components/layouts/GridRow'

export const Text = styled.div`
  color: grey;
  font-size: 1.4rem;
  line-height: 2.3rem;
`

export const GridRow = styled(GridRowRef)`
  padding: 2rem 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
