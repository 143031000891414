import styled from 'styled-components/macro'

export const LargeImage = styled.img`
  max-width: min(750px, 100vw);
  max-height: 500px;
  margin-bottom: 10px;

  cursor: pointer;
  border-top: 0px solid white;
  border-left: 0px solid white;
  transition: all 0.6s ease;

  &:hover {
    border-top: 1px solid white;
    border-left: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: translate(-1px, -5px);
  }
`

export const SmallImage = styled.img`
  max-width: 370px;
  max-height: 245px;
  cursor: pointer;
  border-top: 0px solid white;
  border-left: 0px solid white;
  transition: all 0.6s ease;

  &:hover {
    border-top: 1px solid white;
    border-left: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: translate(-1px, -5px);
  }
`

export const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: min(750px, 100vw);
  margin: 10px 0px;
`

export const FullScreenImg = styled.img`
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.7) 0px 10px 30px;
`

export const DoubleImage = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const BgDiv = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000b0;
  z-index: 2;

  animation: fade-in-bg 1s ease;

  @keyframes fade-in-bg {
    0% {
      background-color: #00000000;
    }
    100% {
      background-color: #000000b0;
    }
  }
`
