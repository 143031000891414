import styled from 'styled-components'
export const StyledButtonIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin-left: auto;
  cursor: pointer;

  svg {
    width: 2rem;
  }
`
