import { createContext, useEffect, useRef, useState } from 'react'

//import 'react-date-range/dist/styles.css'; // main style file
//import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangeContext } from './context'
import '../../../modules/react-date-range/dist/styles.css'
import '../../../modules/react-date-range/dist/default.css'
import { StyledDateRangePicker } from './Style'
import styled from 'styled-components'

export * from './StartDate'
export * from './EndDate'
export * from './context'

const Container = styled.div`
  position: relative;
  @media screen and (max-width: 768px) {
    /*position: inherit;*/
  }
`

export const Wrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: 100%;
  ${(props) => (props.showCalendar ? `${props.showCalendar}: 0px;` : '')}
  z-index: 99999999;

  @media screen and (max-width: 768px) {
    left: 50%;
    right: inherit;
    transform: translateX(-50%);
  }
`

class Logic {
  constructor({ onDateChangeHook = null, startDate = null, endDate = null }) {
    this.setSelectionRange = () => {}
    this.setStartDateProps = () => {}
    this.setEndDateProps = () => {}
    this.setShowCalendar = () => {}
    this.handleRangeFocusChange = () => {}

    this.startDate = startDate || null
    this.endDate = endDate || null
    this.isStartDateFocused = false
    this.isEndDateFocused = false
    this.showCalendar = false
    this.calendarOpenedAt = 0
    this.onDateChangeHook = onDateChangeHook
  }

  onStartDateFocus() {
    if (!this.isStartDateFocused) {
      this.isStartDateFocused = true
      this.pushStartDateProps()

      if (this.isEndDateFocused) {
        this.isEndDateFocused = false
        this.pushEndDateProps()
      }
    }

    this.openCalendar('left')
    this.handleRangeFocusChange([0, 0])
  }

  onEndDateFocus() {
    if (!this.isEndDateFocused) {
      this.isEndDateFocused = true
      this.pushEndDateProps()

      if (this.isStartDateFocused) {
        this.isStartDateFocused = false
        this.pushStartDateProps()
      }
    }

    this.openCalendar('right')
    this.handleRangeFocusChange([0, 1])
  }

  pushStartDateProps() {
    this.setStartDateProps({ isFocused: this.isStartDateFocused, date: this.startDate })
    if (this.onDateChangeHook) {
      this.onDateChangeHook({ startDate: this.startDate, endDate: this.endDate })
    }
  }

  pushEndDateProps() {
    this.setEndDateProps({ isFocused: this.isEndDateFocused, date: this.endDate })
    if (this.onDateChangeHook) {
      this.onDateChangeHook({ startDate: this.startDate, endDate: this.endDate })
    }
  }

  openCalendar(location) {
    this.showCalendar = true
    this.calendarOpenedAt = new Date().getTime()
    this.setShowCalendar(location)
  }

  hideCalendar() {
    this.showCalendar = false
    this.setShowCalendar(null)

    if (this.isStartDateFocused) {
      this.isStartDateFocused = false
      this.pushStartDateProps()
    } else if (this.isEndDateFocused) {
      this.isEndDateFocused = false
      this.pushEndDateProps()
    }
  }

  subscribeHandleRangeFocusChange(fn) {
    this.handleRangeFocusChange = fn

    if (this.isStartDateFocused) {
      fn([0, 0])
    } else if (this.isEndDateFocused) {
      fn([0, 1])
    }
  }

  onDateSelect({ selection }) {
    let start = this.startDate || new Date()
    let end = this.endDate || new Date()

    if (this.isStartDateFocused) {
      this.startDate = selection.startDate
      start = selection.startDate

      if (this.endDate && this.startDate > this.endDate) {
        this.endDate = this.startDate
        this.pushEndDateProps()
      }

      if (start > end) {
        end = start
      }

      this.pushStartDateProps()
    } else if (this.isEndDateFocused) {
      this.endDate = selection.endDate
      end = selection.endDate

      if (this.startDate && this.endDate < this.startDate) {
        this.startDate = this.endDate
        this.pushStartDateProps()
      }

      if (end < start) {
        start = end
      }

      this.pushEndDateProps()
    } else {
      this.hideCalendar()
      return
    }

    this.setSelectionRange({
      startDate: start,
      endDate: end,
      key: 'selection',
    })

    this.hideCalendar()
  }

  clearDateSelection = () => {
    this.startDate = null
    this.endDate = null
    this.isStartDateFocused = false
    this.isEndDateFocused = false
    this.hideCalendar()
    this.pushStartDateProps()
    this.pushEndDateProps()
    this.setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    })
  }
}

export const DateRange = ({
  onChange = null,
  children,
  startDate = null,
  endDate = null,
  disabledDates = [],
  contextRef = null,
}) => {
  const componentLogic = useRef(new Logic({ onDateChangeHook: onChange, startDate, endDate }))
  const calendarRef = useRef()

  if (contextRef) {
    contextRef.current = componentLogic.current
  }

  const [selectionRange, setSelectionRange] = useState({
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
    key: 'selection',
  })

  const [showCalendar, setShowCalendar] = useState(null)

  const handleSelect = (ranges) => {
    componentLogic.current.onDateSelect(ranges)
  }

  useEffect(() => {
    if (componentLogic.current) {
      componentLogic.current.setSelectionRange = setSelectionRange
      componentLogic.current.setShowCalendar = setShowCalendar
    }
  }, [componentLogic])

  useEffect(() => {
    const onWindowClick = (e) => {
      if (componentLogic.current.showCalendar && calendarRef.current) {
        let bounds = calendarRef.current.getBoundingClientRect()

        if (
          e.clientX >= bounds.x &&
          e.clientX <= bounds.x + bounds.width &&
          e.clientY >= bounds.y &&
          e.clientY <= bounds.y + bounds.height
        ) {
          //do nothing because we clicked inside
        } else {
          if (new Date().getTime() - componentLogic.current.calendarOpenedAt > 250) {
            componentLogic.current.hideCalendar()
          }
        }
      }
    }

    const onWindowTouch = (e) => {

      if (componentLogic.current.showCalendar && calendarRef.current) {
        let bounds = calendarRef.current.getBoundingClientRect()
        let touch = e.touches[0];
        let x = touch.clientX;
        let y = touch.clientY;

        if (
          x >= bounds.x &&
          x <= bounds.x + bounds.width &&
          y >= bounds.y &&
          y <= bounds.y + bounds.height
        ) {
          //do nothing because we clicked inside
        } else {
          if (new Date().getTime() - componentLogic.current.calendarOpenedAt > 250) {
            componentLogic.current.hideCalendar()
          }
        }
      }
    }

    window.addEventListener('click', onWindowClick)
    window.addEventListener('touchstart', onWindowTouch)
    return () => {window.removeEventListener('click', onWindowClick); window.removeEventListener('touchstart', onWindowTouch)}
  }, [])

  return (
    <DateRangeContext.Provider value={componentLogic.current}>
      <Container>
        {children}
        <Wrapper ref={calendarRef} showCalendar={showCalendar}>
          {showCalendar && (
            <StyledDateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              minDate={new Date()}
              showDateDisplay={false}
              disabledDates={disabledDates}
            />
          )}
        </Wrapper>
      </Container>
    </DateRangeContext.Provider>
  )
}
