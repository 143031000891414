import { createContext, useRef, useEffect } from "react";

export * from './FieldWrapper';
export * from './SubmitWrapper';
export * from './ErrorWrapper';

class ReactFormValidation {
    constructor( onSubmit = async () => { return })
    {
      this.values = {};
      this.validations = {};
      this.onSubmit = onSubmit;
      this.errorCallbackFunctions = []
    }
  
    addValue(key, joiValidation, defaultValue = null, errorFunction = null) {
      this.values[key] = defaultValue;
      this.validations[key] = { validation: joiValidation, touched: false, errorFunction };
    }

    removeValue(key) {
      delete this.values[key]
      delete this.validations[key]
    }
  
    onValueChanged(key, value) {
      let validated = this.validations[key].validation.validate(value);
      this.validations[key].touched = this.validations[key].touched || !('error' in validated);
      this.values[key] = value;
  
      if (this.validations[key].errorFunction) {
        this.validations[key].errorFunction(validated.error?.message || '');
      }
    }
  
    onBlur(key, value) {
      let validated = this.validations[key].validation.validate(value);
      this.validations[key].touched = true;
      this.values[key] = value;
  
      if (this.validations[key].errorFunction) {
        this.validations[key].errorFunction(validated.error?.message || '');
      }
    }
  
    changeValidation(name, validation) {
      this.validations[name].validation = validation
    }

    removeValidation(name) {
      delete this.validations[name]
    }
  
    getValue(key) {
      return this.values[key] || null
    }
  
    removeErrorCallback(f)
    {
      for(let i=0; i<this.errorCallbackFunctions.length; i++)
      {
        if(this.errorCallbackFunctions[i] === f)
        {
          this.errorCallbackFunctions.splice(i,1)
          break
        }
      }
    }

    addErrorCallback(f)
    {
      console.log("adding error callback")
      this.errorCallbackFunctions.push(f)
    }
  
    errorCallback(val)
    {
      this.errorCallbackFunctions.forEach(f => f(val))
    }
  
    async submit({name, onResult}) {
  
      let keys = Object.keys(this.values);
      let key = '';
      let hasError = false;
  
      this.errorCallback(null)
  
      for (let i = 0; i < keys.length; i++) {
        key = keys[i];
        let validated = this.validations[key].validation.validate(this.values[key]);
        this.validations[key].touched = true;
  
        if (this.validations[key].errorFunction) {
          if (validated.error) {
            hasError = true;
          }
          this.validations[key].errorFunction(validated.error?.message || '');
        }
      }
  
  
      if(onResult)
      {
        onResult({hasError})
      }
  
      if (!hasError) {
        await this.onSubmit({values:this.values, name, callback: (val) => this.errorCallback(val)});
      }
    }
  }
  
  export const ValidationContext = createContext();
  
  export const Form = ({ onSubmit, children, autoScrollIntoView=false, className='' }) => {
    const reactFormValidation = useRef(new ReactFormValidation(onSubmit));
    const formComponent = useRef(null)
  
    useEffect(() => {
        if(formComponent.current && autoScrollIntoView)
        {
            formComponent.current.scrollIntoView({ behavior: 'smooth' });
        }
    },[formComponent])
  
    return (
      <div className={className}>
        <ValidationContext.Provider value={reactFormValidation.current}>
          <div ref={formComponent}>{children}</div>
        </ValidationContext.Provider>
      </div>
    );
  };